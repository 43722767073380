<div class="account-wrapper">
  <div class="account-page">
    <div *ngIf="showPasswordForm" class="account-center">

      <div class="login-logo"></div>
      <div class="account-box b-shadow">

        <div class="account-title">{{accountTitle}} </div>
        <hr />

        <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()" class="form-signin validate-form">
          <div *ngIf="!isForgotPassword" class="wrapper-input validate-input m-t-5 m-b-10">
            <label for="email">Email ID</label>
            <input formControlName="emailId" class="input" type="email" id="email" name="email" required readonly>
          </div>

          <!-- <div *ngIf="!isForgotPassword" class="wrapper-input validate-input m-t-5 m-b-10">
            <label for="username">Username / Email</label>
            <input formControlName="userName" class="input" (keydown)="prevent($event)" type="text" id="username"
              name="username" autocomplete="email" autofocus required (blur)="onBlurUsername($event)" readonly>
            <div class="invalid-feedback d-block" *ngIf="isDuplicateUname">
              Username Already Exist!
            </div>
          </div> -->

          <div class="wrapper-input  m-t-5 validate-input">
            <label for="password">Enter Password</label>
            <input formControlName="password" class="input" type="password" id="password" autocomplete="new-password"
              name="password" required minlength="8" autofocus #pass>
            <span class="password-eye" (click)="showPassword(pass,pass.type)">
              <span *ngIf="pass.type == 'password'">
                <i class="fas fa-eye field-icon "></i>
              </span>
              <span *ngIf="pass.type == 'text'">
                <i class="fas fa-eye-slash field-icon"></i>
              </span>
            </span>
            <div style="margin-top: -20px;" *ngIf="formControls.password?.errors">
              <div class="invalid-feedback d-block"
                *ngIf="formControls.password?.touched && formControls.password?.errors?.required">
                Please Enter Password
              </div>
              <div class="invalid-feedback d-block"
                *ngIf="formControls.password?.touched  && (formControls.password?.errors?.pattern || formControls.password?.errors?.minlength)">
                {{patternErr}}
              </div>
            </div>
          </div>

          <div class="wrapper-input  m-t-15 validate-input m-b-10">
            <label for="c-password">Confirm Password</label>
            <input formControlName="rePassword" class="input" type="password" id="c-password" autocomplete="off"
              name="c_password" required #repass>
            <span class="password-eye" (click)="showPassword(repass,repass.type)">
              <span *ngIf="repass.type == 'password'">
                <i class="fas fa-eye field-icon "></i>
              </span>
              <span *ngIf="repass.type == 'text'">
                <i class="fas fa-eye-slash field-icon"></i>
              </span>
            </span>
            <div style="margin-top: -20px;" class="invalid-feedback d-block"
              *ngIf="formControls.rePassword?.touched && (verificationForm.value.password !== verificationForm.value.rePassword)">
              The specified passwords do not match.
            </div>
          </div>

          <div class="login-form-btn">
            <button class="login-btn m-l-auto" [disabled]="verificationForm.invalid">
              Submit
            </button>
          </div>

        </form>

      </div>
    </div>

  </div>
</div>