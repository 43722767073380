import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CommonService } from 'src/app/core/api-services/common.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from 'src/app/core/api-services/appointment.service';


@Component({
  selector: 'app-email-sent-popup',
  templateUrl: './email-sent-popup.component.html',
  styleUrls: ['./email-sent-popup.component.scss']
})
export class EmailSentPopupComponent implements OnInit {
  
  @ViewChild('openPatientConfirmation') openPatientConfirmation ;
  appointmentId :any;
  showPopup = true;
  constructor(private commonService:CommonService, 
    private loader:LoaderService, 
    private alertservice:AlertService, 
    private router:Router,
    private route:ActivatedRoute,
    private appointmentService:AppointmentService,
    private alert:AlertService) { }

  ngOnInit() {
    this.appointmentId = this.route.snapshot.paramMap.get('appointmentId');
      this.sendAppointmentEmail();
  }

  openModal(){
    this.openPatientConfirmation.nativeElement.click();
  }

  sendAppointmentEmail(){
    this.appointmentService.sendConfirmationEmail(this.appointmentId).subscribe((data:any) => {
        this.alert.successAlert("Email sent successfully");
    },(error:any)=>{
      this.alert.errorAlert(error);
    });
  }
}
