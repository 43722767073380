import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from 'src/app/core/api-services/patient.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
import { ValidationHelperService } from 'src/app/core/services/validation-helper.service';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.css']
})
export class SupportModalComponent implements OnInit {

  @ViewChild('closeModal') private closeModal: ElementRef;

  supportForm: FormGroup;
  submitted: boolean = false;
  showSpinner: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private _patient: PatientService,
    private _alert: AlertService,
    private validationHelperService: ValidationHelperService
  ) { }

  ngOnInit() {
    this.validateForm();
  }

  /**
* @returns Forms Controls
*/
  get formControls() { return this.supportForm.controls; }

  validateForm() {
    this.supportForm = this.formBuilder.group({
      name: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      mobilePhone: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern(/^[2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4}$/)]],
      message: ['', Validators.required],
      dashboardName: "Specialist"
    })
  }

  resetForm() {
    this.supportForm.reset();
    this.validateForm();
    this.submitted = false;
    // this.closeModal.nativeElement.click();
  }

  onSubmit() {

    this.submitted = true;

    if (this.supportForm.valid) {
      this.showSpinner = true;
      this._patient.supportMail(this.supportForm.value).subscribe(
        (res: any) => {

          this.showSpinner = false;
          this.resetForm();

          this.closeModal.nativeElement.click();
          this._alert.supportEmailAlert(this.translate.instant('support.thanku'))
        },
        (error: any) => {

          this.showSpinner = false;
        }
      )
    } else {
      const keys = this.validationHelperService.getInvalidKeys(this.supportForm, {
        name: 'Name',
        emailId: 'Email',
        mobilePhone: 'Phone Number',
        message: 'Message'
      });
      this.validationHelperService.openErrDialog(keys);
    }
  }
}
