<!-- <div mat-dialog-content>
  {{message}}
</div>
<div mat-dialog-actions>
      <div class="m-l-auto">
          <button class="btn modal-btn" (click)="onNoClick()">No</button>
          <button class="btn modal-btn" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
      </div>
  
 
</div> -->
<!-- <div mat-dialog-header>
  <div class="coming-soon">{{message}}</div>
</div> -->
<ng-container *ngIf="data">
  <div mat-dialog-content>
    <div class="message-soon break-w"> {{data.message}}</div>
  </div>
  <div mat-dialog-actions>
    <div class="col-md-12 text-right m-t-15 d-flex justify-content-end">
      <button class="btn cancel-modal-btn text-right m-r-50" (click)="onNoClick()">No</button>
      <button class="btn delete-modal-btn text-right" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </div>
  </div>
</ng-container>