import { findTimeZone, getUTCOffset } from 'timezone-support';
import { PipeTransform, Pipe } from '@angular/core';
import { AppointmentService } from '../api-services/appointment.service';

@Pipe({
  name: 'timezoneConvert',
})
export class TimezoneConvertPipe implements PipeTransform {
  constructor(private appointmentService: AppointmentService) {
  }

  public transform(d: Date|string|number, timezoneStr?: string, initialTimezoneStr = Intl.DateTimeFormat().resolvedOptions().timeZone, use = false): Date {
    const date = new Date(d);
    if (!use) {
      timezoneStr = this.appointmentService.timezoneChangeSubscription.getValue() || timezoneStr;
    } else {
      timezoneStr = timezoneStr || this.appointmentService.timezoneChangeSubscription.getValue();
    }
    const timezone = findTimeZone(timezoneStr || 'UTC');
    let offset = getUTCOffset(date, timezone).offset;
    if (initialTimezoneStr) {
      const initialTimezone = findTimeZone(initialTimezoneStr || 'UTC');
      const initialOffset = getUTCOffset(date, initialTimezone);
      offset -= initialOffset.offset;
    }
    return new Date(+date - offset * 60 * 1000);
  }

  public transformBack(d: Date|string|number): Date {
    return this.transform(d, Intl.DateTimeFormat().resolvedOptions().timeZone, this.appointmentService.timezoneChangeSubscription.getValue(), true);
  }
}
