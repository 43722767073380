import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "../Authentication/auth.service";
import { Subject, BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PatientService {
  private dataStringSource = new Subject<string>();
  dataString$ = this.dataStringSource.asObservable();
  fileName = new BehaviorSubject('');
  currentFileName$ = this.fileName.asObservable();
  isPatientProfile = new BehaviorSubject(false);
  isPatientProfile$ = this.isPatientProfile.asObservable();
  groupId: any;

  insertData(data: string) {
    this.dataStringSource.next(data);
  }
  baseUrl: string = environment.baseUrl;

  updateFlagIsDependentDetailsPulled = new BehaviorSubject(null);
  updateFlagIsDependentDetailsPulled$ = this.updateFlagIsDependentDetailsPulled.asObservable();

  checkEmailVerification = new BehaviorSubject(false);
  $checkEmailVerification = this.checkEmailVerification.asObservable();

  reInitEmailVerification = new BehaviorSubject(false);
  $reInitEmailVerification = this.reInitEmailVerification.asObservable();

  constructor(private _http: HttpClient, private _auth: AuthService) {
    this.groupId = this._auth.getGroupId();
  }

  setFileName(data) {
    this.fileName.next(data);
  }

  /*********************************************************************************************************
   *          GET APIs
   * ******************************************************************************************************/
  getAppointmentModalDetail(date) {
    return this._http.get<any[]>(
      `${this.baseUrl}provider-service/api/v1/provider-slots/date?q=${date}`
    );
  }

  getAllPatient(data) {
    if (data.facilityId == 0) {
      data.facilityId = "";
    }
    return this._http.get(
      `${this.baseUrl}patient-service/api/patients/appointment-create?name=${data.name}&facilityId=${data.facilityId}&providerGroupId=${data.groupId}&providerId=${data.providerId}&size=${data.size}&dob=${data.dob}`
    );
  }

  getPatientById(id: any) {
    return this._http.get(`${this.baseUrl}patient-service/api/patient/${id}`);
  }

  getPatientByIdAndDashboard(id) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/patient/${id}?dashboard=SPECIALIST`
    );
  }

  /*****************************************************************************************************************
   *    SEARCH APIs
   ******************************************************************************************************************/
  searchPatient(model: any) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/patients/provider-group/${this.groupId}?size=${model.size}&dob=${model.dob}&name=${model.name}`
    );
  }
  searchPatientInSpecialistGroup(model: any) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/patients/provider-group?providerGroupId=${this.groupId}&size=${model.size}&name=${model.name}`
    );
  }
  /**********************************************************************************************************
   *      POST APIs
   **********************************************************************************************************/
  SavePatient(data: any) {
    return this._http.post(`${this.baseUrl}patient-service/api/patients`, data);
  }
  // rounding api
  addPatient(data: any) {
    return this._http.post(`${this.baseUrl}provider-rounding`, data);
  }

  uploadFiles(data): Observable<HttpEvent<any>> {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/patient/upload-file`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  /************************************************************************************************************
   *      PUT APIs
   * **********************************************************************************************************/

  removePatient(data: any) {
    return this._http.put(`${this.baseUrl}provider-rounding/remove`, data);
  }

  updatePatient(data: any) {
    return this._http.put(`${this.baseUrl}patient-service/api/patients`, data);
  }

  supportMail(data) {
    return this._http.post(
      `${this.baseUrl}common-service/api/v1/auth/support-email`,
      data
    );
  }

  getEHRStatus(groupId): any {
    return this._http.get(
      `${this.baseUrl}ehr-integration-service/api/v1/ehr-setting/provider-group/${groupId}/status`
    );
  }

  getPatientAthenaId(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/provider-group-ehr-patient?patientId=${data.patientId}&providerGroupId=${data.groupId}`
    );
  }

  getEncounterAthenaId(id) {
    return this._http.get(
      `${this.baseUrl}appointment-service/api/v1/encounter/${id}`
    );
  }

  getRelationShipList(): Observable<any> {
    return this._http.get(
      `${this.baseUrl}common-service/api/v1/insurance/patients-relationships`
    );
  }

  syncAthenaInsurance(patientId: number, providerGroupId: number) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/patient/${patientId}/provider-group/${providerGroupId}/fetch/insurances/athena`
    );
  }
  getPatientAthenaInsuranceId(patientId) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/patient/${patientId}/ehr-insurance-id/search`
    );
  }

  createSelfInsurance(patientId, groupId) {
    return this._http.post(
      `${this.baseUrl}patient-service/api/patient/${patientId}/provider-group/${groupId}/self-insurance/athena`,
      null
    );
  }
  searchMedications(query: string) {
    return this._http.get(
      `${this.baseUrl}common-service/api/v1/medications?name=${query}`
    );
  }

  getOutPatientList(data) {
    // if (data.facilityId == 0) {
    //   data.facilityId = '';
    // }
    // return this._http.get(`${this.baseUrl}patient-service/api/patients/provider?name=${data.name}&facilityId=${data.facilityId}&providerGroupId=${data.groupId}&providerId=${data.providerId}&size=${data.size}&dob=${data.dob}`)
    return this._http.get(
      `${this.baseUrl}patient-service/api/provider-group/patients?providerGroupId=${data.groupId}&page=${data.size}&name=${data.name}&dob=${data.dob}`
    );
  }

  getInPatientList(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patients?providerId=${data.providerId}&facilityId=${data.inPatientFacilityId}&name=${data.name}&status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&page=${data.size}&pageSize=20`
    );
  }

  getPatientPccDetails(id) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patient/${id}`
    );
  }

  getAuthorList(id) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patient/${id}/progress-notes/author`
    );
  }
  getListOfProgressNotes(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patient/${data.id}/progress-notes?orderByDesc=${data.orderByDesc}&page=${data.page}&pageSize=${data.pageSize}&authorName=${data.authorName}&providerId=${data.providerId}`
    );
  }

  getCsvFile(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patients/download_excel?providerId=${data.providerId}&facilityId=${data.inPatientFacilityId}&name=${data.name}&status=${data.status}&startDate=${data.startDate}=&endDate=${data.endDate}`,
      { responseType: "blob" }
    );
  }
  getInPatientListPdf(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/in-patients/download_pdf?providerId=${data.providerId}&facilityId=${data.inPatientFacilityId}&name=${data.name}&status=${data.status}&startDate=${data.startDate}=&endDate=${data.endDate}`,
      { responseType: "arraybuffer" }
    );
  }

  pullDependantData(data) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/in-patient/${data.inPatientId}/provider/${data.providerId}/facility/${data.facilityId}/pull-dependent-entities?entityType=${data.entityType}`);
  }

  addInPatientToRoundingList(data) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/provider-rounding`, data);
  }

  removeInPatientFromRoundingList(data) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-rounding/remove`, data);
  }

  getInPatientRoundingListByProviderAndFacility(data) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-rounding?providerId=${data.providerId}&facilityId=${data.facilityId}`)
  }

  printRoundingListByCartId(id) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/in-patients/rounding-cart/download_pdf?roundingCartId=${id}`, { responseType: "arraybuffer" });
  }

  printFromAppointmentList(id) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/in-patients/rounding-appointment/download_pdf?appointmentId=${id}`, { responseType: "arraybuffer" });
  }

  syncPccProfileDetail(data) {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/in-patient/details/re-sync/pcc`, data);
  }
  syncProgressNotes(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/in-patient/re-sync/notes/pcc`, data);
  }

  getFacilityTimezone(id) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/pcc/facility/${id}/timezone`);
  }

  getVerificationLinkAndStatus(userId) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/check/email/verification?userId=${userId}`)
  }

  sendMailVerification(userId, email) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/auth/re-send/email/verification/link?userId=${userId}&email=${email}`, null);
  }

  getFiles(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/patient/files?patientId=${data.id}&fileName=${data.name}&fileContents=false`
    );
  }

  getFileById(data) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/patient/files/${data.id}`
    );
  }

  getFileById1(id) {
    return this._http.get(
      `${this.baseUrl}patient-service/api/v1/patient/files/${id}`
    );
  }

  deleteUploadedFileById(id) {
    return this._http.delete(`${this.baseUrl}patient-service/api/v1/patient/files/${id}`)
  }
  downloadFile(id) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/patient/download-file/${id}`, { responseType: "blob" }
    );
  }

  getPatientsFromAthena(data) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${data.groupId}/patients?firstname=${data.firstName}&lastname=${data.lastName}`);
  }

  createAthenaPatient(providerGroupId, data) {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/provider-group/${providerGroupId}/patient/nod`, data);
  }

  getUserIdBypatientId(id: any) {
    return this._http.get(`${this.baseUrl}patient-service/api/patient/userId/${id}`);
  }

  updatePatientInsurance(data: any) {
    return this._http.post(`${this.baseUrl}patient-service/api/patient/referral/insurance`, data);
  }


  deleteFileById(id) {
    return this._http.delete(`${this.baseUrl}patient-service/api/v1/patient/files/${id}`);
  }

  getPatientIdentificationFiles(patientId) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/patient/${patientId}/identification`);
  }

  uploadPatientIdentificationFiles(patientId, fileName1, fileName2, data) {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/patient/upload/identification?patientId=${patientId}&fileName1=${fileName1}&fileName2=${fileName2}`, data);
  }

  uploadPatientIdentificationFilesForUsers(patientId, fileName1, fileName2, data) {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/patient/upload/identification/patient-id?patientId=${patientId}&fileName1=${fileName1}&fileName2=${fileName2}`, data);
  }

  deleteUploadedFileByPatientId(id) {
    return this._http.delete(`${this.baseUrl}patient-service/api/v1/patient/files/patient/${id}`)
  }
}
