<div class="modal fade" data-backdrop="static" id="resetPassword" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="validate-form">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'form.resetPassword.title' | translate }}</h5>
        </div>
        <div class="modal-body">

          <div class="form-group">
            <label for="password">{{ 'form.resetPassword.password' | translate }}</label>
            <input formControlName="password" #pass class="form-control emailInpunt" type="password" id="password"
              placeholder="Enter New Password" autocomplete="new-password" name="password" required minlength="8">
            <div *ngIf="formControls.password?.errors">
              <div class="invalid-feedback d-block" *ngIf="isSubmit && formControls.password?.errors?.required">
                {{ 'tigerConnect.validation.password.required' | translate}}
              </div>
              <div class="invalid-feedback d-block"
                *ngIf="formControls.password?.touched  && (formControls.password?.errors?.pattern || formControls.password?.errors?.minlength)">
                <!-- {{ 'tigerConnect.validation.password.valid' | translate}} -->
                {{patternErr | translate}}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="rePassword">{{ 'form.resetPassword.cPassword' | translate }}</label>
            <input formControlName="rePassword" class="form-control emailInpunt" #rePassword type="password"
              placeholder="Confirm Password" id="rePassword" autocomplete="new-password" name="rePassword" required
              minlength="8">

            <div *ngIf="formControls.rePassword?.errors">
              <div class="invalid-feedback d-block" *ngIf="isSubmit && formControls.rePassword?.errors?.notEquivalent">
                {{ 'form.resetPassword.validation.match' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" (click)="onSubmit()" class="modal-btn btn-primary-one">
            <div *ngIf="showSpinner" class="spinner-border text-muted"></div>
            {{'button.save' | translate}}
          </button>
        </div>

      </form>
      <button hidden type="button" data-dismiss="modal" #closeModal></button>

    </div>
  </div>
</div>