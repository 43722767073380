
import { PipeTransform, Pipe } from '@angular/core';
import { Injectable } from '@angular/core';


@Pipe({
    name: 'underscore',
})
@Injectable({
    providedIn: 'root'
})
export class UnderScorePipe implements PipeTransform {
    transform(items: any): any {
        if (!Array.isArray(items)) {
            if (items) {
                let str: string = '';
                items.split('_').forEach(
                    (e: any) => {
                        str = str + ' ' + e;
                    });
                return str.replace(/\w\S*/g, (txt => txt[0] + txt.substr(1)));
            }
        }

        if (Array.isArray(items)) {
            items.filter(e => {
                let str: string = '';
                let name: string = e.name;
                name.split('_').forEach(
                    s => {
                        str = str + ' ' + s;
                    }
                );
                e.name = str.replace(/\w\S*/g, (txt => txt[0] + txt.substr(1)));;
            })
            return items;
        }
    }
}