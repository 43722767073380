<div class="notification-container">
  <button (click)="onNoClick()" class="close ng-star-inserted" type="button"><span class="close">×</span></button>
  <div class="container">
    <form [formGroup]="patientNotificationsForm">
     <table class="table">
      <thead>
        <th>Patient Notifications</th>
        <th>Text</th>
        <th>Email</th>
      </thead>

      <tbody>

        <!-- <tr formGroupName="APPOINTMENT_SCHEDULED_REMINDER"> -->
          <!-- <td><button class="btn btn-primary btn-radius" (click)="sendNotification('CONFIRMATION_REMINDER', patientNotificationsForm.get('APPOINTMENT_CONFIRMATION_REMINDER'))">Send patient appointment confirmation</button></td> -->
          <!-- <td>Send patient appointment confirmation</td>
          <td> <mat-checkbox formControlName="text"></mat-checkbox></td>
          <td><mat-checkbox formControlName="email"></mat-checkbox></td>
        </tr> -->


        <tr formGroupName="FOLLOW_UP_APPOINTMENT_REMINDER">
          <!-- <td><button class="btn btn-primary btn-radius" (click)="sendNotification('FOLLOWUP_REMINDER', patientNotificationsForm.get('FOLLOWUP_REMINDER'))">Send F/U appointment reminder</button></td> -->
          <td>Send F/U appointment reminder

            <span class="info-icon">
              <span class="tool-wrapper">
                  <i class="fal fa-info-circle" aria-hidden="true"></i>
                  <div class="tooltip tooltip-right ">
                  <div>Invite a patient to schedule a follow-up appointment online or to call the practice to schedule a follow-up appointment.</div>
                  </div>
              </span>
          </span>
          </td>
          <td> <mat-checkbox formControlName="text"></mat-checkbox></td>
          <td><mat-checkbox  formControlName="email"></mat-checkbox></td>
        </tr>


        <tr formGroupName="COMPLETE_ORDER_REQUEST">
          <!-- <td><button class="btn btn-primary btn-radius" (click)="sendNotification('COMPLETE_ORDER_REQUEST', patientNotificationsForm.get('COMPLETE_ORDER_REQUEST'))">Send "complete order" request</button></td> -->
          <td>Send "complete order" request</td>
          <td> <mat-checkbox formControlName="text"></mat-checkbox></td>
          <td><mat-checkbox formControlName="email"></mat-checkbox></td>
        </tr>

        <!-- <tr formGroupName="RATE_APPOINTMENT_SURVEY"> -->
          <!-- <td><button class="btn btn-primary btn-radius" (click)="sendNotification('RATE_APPOINTMENT_REQUEST', patientNotificationsForm.get('RATE_APPOINTMENT_REQUEST'))">Send "Rate appointment" request</button></td> -->
          <!-- <td>Send "Rate appointment" request</td>
          <td> <mat-checkbox formControlName="text"></mat-checkbox></td>
          <td><mat-checkbox formControlName="email"></mat-checkbox></td>
        </tr> -->

        <tr formGroupName="CALL_ASAP_REQUEST">
          <!-- <td><button class="btn btn-primary btn-radius" (click)="sendNotification('CALL_ASAP_REQUEST', patientNotificationsForm.get('CALL_ASAP_REQUEST'))">Send "Call Us ASAP" request</button></td> -->
          <td>Send "Call Us ASAP" request</td>
          <td> <mat-checkbox formControlName="text"></mat-checkbox></td>
          <td><mat-checkbox formControlName="email"> </mat-checkbox></td>
        </tr>

      </tbody>
     </table>
    </form>
     <div class="footer-container">
    <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-primary btn-radius" (click)="onSubmitSend()">Send</button>
        </div>
      </div>
     </div>
  </div>
</div>


<!-- <div class="notification-container">
  <button (click)="onNoClick()" class="close ng-star-inserted" type="button"><span class="close">×</span></button>
  <div class="container">
    <form [formGroup]="patientNotificationsForm">
     <table class="table">
      <thead>
        <th>Patient Appointment Notifications</th>
        <th>text</th>
        <th>email</th>
      </thead>

      <tbody>

        <div formArrayName="notifications">
          <ng-container *ngFor="let type of tracking.controls;let j=index">
            <div [formGroupName]="j">
              <input type="text" formControlName="tempName">
              <mat-checkbox [value]="true" formControlName="text"></mat-checkbox>
              <mat-checkbox  [value]="false" formControlName="email"></mat-checkbox>

            </div>
          </ng-container>
        </div>

      </tbody>
     </table>
    </form>
     <div class="footer-container">
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-primary btn-radius" (click)="onSubmitSend()">Send</button>
        </div>
      </div>
     </div>
  </div>
</div> -->