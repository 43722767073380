import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';


import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../Authentication/auth.service';
import { AlertService } from '../helpers/alert.service';
import { LoaderService } from '../services/loader.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  errorDialogService: any;
  skipErrorsEndpoints = [
    'merchant-details',
    'verifyInsurance',
    'billing-plan',
    'provider-promo-code',
    'provider-service/api/v1/temporary-provider',
    'provider-service/api/v1/referral'
  ];

  constructor(
    public auth: AuthService,
    private snack: AlertService,
    private _loader: LoaderService,
    private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getToken() !== null) {
      if (request.url.includes('video-capture/save')) {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.auth.getToken()}`,
            'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'multipart/form-data;boundary=--------------------------830081166530695065997390'
          }
        });
      }
      else if (request.url.includes('auth/billing-plan/provider')) {
        request = request.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
          }
        });
      }
      else {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.auth.getToken()}`,
            'Access-Control-Allow-Origin': '*',

          }
        });
      }
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data: any;
        data = {
          reason: error.status,
          status: error.error.message ? error.error.message : error.error.error,
          message: error.error.message ? error.error.message : error.statusText
        };
        let errMsg: any;
        this._loader.showLoader(false);
        if (data.reason === 401) {
          return throwError(error);
        } else if (data.reason === 0)
          errMsg = this.translate.instant('notification.error.server.0');
        else if (data.reason === 404)
          errMsg = this.translate.instant('notification.error.server.404');
        else if (data.reason === 500) {
          data.message ? errMsg = data.message :
            errMsg = this.translate.instant('notification.error.server.500');
        }
        else if (data.reason === 302)
          errMsg = data.message;
        else
          errMsg = this.translate.instant('notification.error.server.global');

        data.title = "Sorry . . ."
        data.text = errMsg;
        if (!this.skipErrorsEndpoints.some(a => {
          return request.url.indexOf(a) !== -1;
        })) {
          this.snack.errorAlert(data);
        }
        return throwError(error);
      }));
    // catchError((error: HttpErrorResponse) => {
    //   let data: any;
    //   data = {
    //     reason: error.status,
    //     status: error.error.message ? error.error.message : error.error.error
    //   };
    //   let errMsg: any;

    //   if (data.reason === 401) {
    //     this._loader.showLoader(false);
    //     errMsg = 'You are not authorized !!!';
    //     data.text = errMsg
    //     this.snack.errorAlert(data)
    //     return throwError(error);
    //   }
    //   // else if (data.reason === 0) {
    //   //   this._loader.showLoader(false);
    //   //   errMsg = 'Connection Error';
    //   // }
    //   // else if (data.reason === 404) {
    //   //   this._loader.showLoader(false);
    //   //   errMsg = "For some reason, we can't load this page. Try refreshing your browser.";
    //   // }
    //   // else
    //   //   errMsg = data.status

    //   // data.title = "Sorry . . ."

    // }));
  }
}
