import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-billing-dialog',
  templateUrl: './billing-dialog.component.html',
  styleUrls: ['./billing-dialog.component.css']
})
export class BillingDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BillingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
