<div class="account-wrapper">
	<div class="account-page">
		<div class="account-center">
			<div class="account-title" *ngIf="isNonProductionEnvironment"><h3>This page is for software developers. Looking for nodmd.com? Click <a href="https://www.nodmd.com/"><u>here</u></a></h3>.</div>
			<div class="login-logo"></div>
			<div class="account-box">

				<div class="account-title">{{ dashBoardType }} </div>
				<hr class="space" />
				<form
					(ngSubmit)="f.form.valid ? onSubmit() : null"
					#f="ngForm" class="form-signin validate-form">
					<div class="wrapper-input validate-input m-t-5 m-b-10">
						<!-- <input class="input" type="email" id="email" name="email" (blur)="onBlurMethod($event)" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': email.touched && email.invalid }" required>
								<span class="focus-input" data-placeholder="Email Address"></span> -->

						<input class="input" type="text" id="email" name="email" (blur)="prevent($event);onBlurMethod($event)"
							[(ngModel)]="model.email" #email="ngModel"
							[ngClass]="{ 'is-invalid': email.touched && email.invalid }" required>
						<span class="focus-input" data-placeholder="Username or Email ID"></span>

					</div>
					<div class="wrapper-input  m-t-40 validate-input m-b-10">
						<input class="input" type="password" id="password" name="password" (blur)="onBlurMethod($event)"
							[(ngModel)]="model.password" #password="ngModel"
							[ngClass]="{ 'is-invalid': password.touched && password.invalid }" required #pass>
						<span class="focus-input" data-placeholder="Password"></span>
						<span class="password-eye" (click)="showPassword(pass,pass.type)">
							<span *ngIf="pass.type == 'password'">
								<i class="fas fa-eye field-icon "></i>
							</span>
							<span *ngIf="pass.type == 'text'">
								<i class="fas fa-eye-slash field-icon"></i>
							</span>
						</span>
					</div>

					<div *ngIf="password.touched && password.invalid" class="invalid-feedback">
						<div *ngIf="password.errors.required">{{ 'login.msg.password' | translate }}</div>
					</div>

					<!-- new code -->
					<div class="login-form-btn ">
						<div class="remember-me">
							<label><input type="checkbox" name="rememberMe" [(ngModel)]="rememberMe"
									(change)="handleRememberMe($event)" [checked]="rememberMe" class="m-r-5" />
								{{ 'login.remember' | translate }} </label>
						</div>
						<button [disabled]="!f.form.valid" class="login-btn m-l-auto">
							{{ 'login.button' | translate }}
						</button>
					</div>

					<div (dblclick)="mobileTesting()" class="v-num">
						{{ versionNumber }}
					</div>

				</form>

			</div>

			<div class="login-text">

				<a class="c-p" (click)="createAccount()"> {{'login.createAccount' | translate}} </a>&nbsp; | &nbsp;
				<a class="c-p" (click)="forgotPassword()"> {{'login.forgotPass' | translate}} </a>
				<a class="c-p" data-toggle="modal" data-target="#supportModal"> &nbsp; |
					&nbsp;{{'login.contactSupport' | translate}} </a>
			</div>
		</div>

	</div>
</div>
<app-support-modal></app-support-modal>
