import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { Role } from 'src/app/config/roles';
import { UserService } from '../services/user.service';
import { isArray } from 'util';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUrlPart: string;
  constructor(private user: UserService,
    private matDialog: MatDialog,
    private router: Router) { }
  setSessionToken(token, currentUser): void {
    sessionStorage.setItem('secretToken', token);
    sessionStorage.setItem('type', window.btoa(currentUser.userType));
    sessionStorage.setItem('specialist', window.btoa(currentUser.providerId));
    sessionStorage.setItem('name', window.btoa(unescape(encodeURIComponent(currentUser.providerDisplayName))));
    sessionStorage.setItem('group', window.btoa(currentUser.providerGroupId));
    sessionStorage.setItem('roles', window.btoa(JSON.stringify(currentUser.roles)));
    sessionStorage.setItem('userId', window.btoa(currentUser.id));
    sessionStorage.setItem('username', window.btoa(currentUser.emailId));
    sessionStorage.setItem('firstName', window.btoa(unescape(encodeURIComponent(currentUser.firstName))));
    sessionStorage.setItem('lastName', window.btoa(unescape(encodeURIComponent(currentUser.lastName))));
    sessionStorage.setItem('isPcp', window.btoa(currentUser.isPcp));
    this.getandSetPermissions();
    // this.myRoute.navigateByUrl(`${currentUser.role}/patients`);
  }
  setLocalToken(userLogin: any) {
    userLogin.isRememberMe = true;
    localStorage.setItem('isRememberMeData', btoa(JSON.stringify(userLogin)));
  }


  getToken() {
    return sessionStorage.getItem('secretToken') || localStorage.getItem('secretToken');
  }
  getType() {
    return (sessionStorage.getItem('type') || localStorage.getItem('type'));
  }
  getSpecialist() {
    return atob(sessionStorage.getItem('specialist') || localStorage.getItem('specialist'));
  }

  getCurrentUserName() {
    return atob(sessionStorage.getItem('name') || localStorage.getItem('name'));
  }

  getGroupId() {
    return atob(sessionStorage.getItem('group') || localStorage.getItem('group'));
  }
  getRoleName() {
    return atob(sessionStorage.getItem('role') || localStorage.getItem('role'));
  }


  getUserId() {
    return atob(sessionStorage.getItem('userId') || localStorage.getItem('userId'));
  }

  getUserName() {
    return atob(sessionStorage.getItem('username') || localStorage.getItem('username'));
  }

  getRememberMeData() {
    return (localStorage.getItem('isRememberMeData') && atob(localStorage.getItem('isRememberMeData')));
  }

  clearIfRememberMeExist() {
    if (this.getRememberMeData()) localStorage.removeItem('isRememberMeData');
  }


  getandSetPermissions() {
    this.user.setUser(JSON.parse(window.atob(sessionStorage.getItem('roles'))));
  }


  isLoggedIn(): boolean {
    if (this.getToken() !== null)
      return true;
    else
      return false;
  }


  getTokenExpirationDate(token: string): Date {
    const decoded = decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    this.showLoginAlert(date);
    return !(date.valueOf() > new Date().valueOf());
  }

  showLoginAlert(date): boolean {
    var expTime = new Date(date);
    var currTime = new Date().valueOf();
    var durationInMinutes = 1;
    var alertTime = expTime.setMinutes(expTime.getMinutes() - durationInMinutes);
    if (currTime >= alertTime) return true;
    return false;

  }
  logout() {
    localStorage.removeItem("nod_username");
    localStorage.removeItem('slotCreation');
    sessionStorage.removeItem('secretToken');
    sessionStorage.removeItem('type');
    sessionStorage.removeItem('specialist');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('group');
    sessionStorage.removeItem('roles');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('LASTACTION');
    sessionStorage.removeItem('isReset');
    sessionStorage.removeItem('openTaskFilter');
    sessionStorage.removeItem('patientFacilityId');
    sessionStorage.removeItem('inPatientStatus');
    sessionStorage.removeItem('redirect');
    localStorage.removeItem("skipPcc");
    localStorage.removeItem("notes");
    localStorage.removeItem("skipEmailVerification");
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('updatedTimezone');
    sessionStorage.removeItem('plan');
    sessionStorage.removeItem('isPcp');
    sessionStorage.removeItem('timezone');
    sessionStorage.removeItem('saasSigned');
    this.currentUrlPart = window.location.pathname + window.location.search;
    this.router.navigate(['/login']);
    this.matDialog.closeAll();
  }


  checkPermission(permission: any) {
    var currentUser: any;
    let hasPermission = false;
    this.user.currentUser.subscribe(user => {
      currentUser = user;
    });

    if (currentUser && currentUser.length > 0) {
      if (isArray(permission)) {
        for (const checkPermission of permission) {
          const permissionFound = currentUser.find(x => x.toUpperCase() === checkPermission.toUpperCase());

          if (permissionFound)
            hasPermission = true;
          else
            hasPermission = false;
        }
      } else {
        if (currentUser.includes(permission.toUpperCase()))
          hasPermission = true;
        else
          hasPermission = false;
      }
    }

    return hasPermission;
  }

  getFirstName() {
    return atob(sessionStorage.getItem('firstName') || localStorage.getItem('firstName'));
  }

  getLastName() {
    return atob(sessionStorage.getItem('lastName') || localStorage.getItem('lastName'));
  }

  isCurrentUserAdmin(): boolean {
    const roles = JSON.parse(window.atob(sessionStorage.getItem('roles'))) || [];
    return roles.some(e => (e.name === 'Specialist Admin' || e.name === 'Primary Care Admin'));
  }

  hasRole(role: string): boolean {
    const roles = JSON.parse(window.atob(sessionStorage.getItem('roles'))) || [];
    return roles.some(({ name }) => name === role);
  }

  async setPlanToken(plan) {
    await sessionStorage.setItem('plan', window.btoa(plan.planName));
  }

  getPlan() {
    return atob(sessionStorage.getItem('plan') || localStorage.getItem('plan'));
  }

  setTimeZone(timeZone) {
    sessionStorage.setItem('timeZone', window.btoa(timeZone.planName));
  }

  getTimeZone() {
    return atob(sessionStorage.getItem('timeZone') || localStorage.getItem('timeZone'));
  }


  setSaasSigned(data) {
    sessionStorage.setItem('saasSigned', window.btoa(data));
  }

  getSaasSigned() {
    return atob(sessionStorage.getItem('saasSigned') || localStorage.getItem('saasSigned'));
  }
}


