import { AuthService } from 'src/app/core/Authentication/auth.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css']
})
export class NotAuthorizedComponent implements OnInit {

  planName: any;

  constructor(public _location: Location, private _auth: AuthService) { }

  ngOnInit() {
    this.planName = this._auth.getPlan();
  }

}
