import { Component, HostListener, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/api-services/common.service';
import * as Twilio from 'twilio-client';

@Component({
  selector: 'app-audio-call',
  templateUrl: './audio-call.component.html',
  styleUrls: ['./audio-call.component.css']
})
export class AudioCallComponent implements OnInit {

  device: Twilio.Device;
  iframeVar: any;
  sendData: any = {
    isFor: '',
    from: 'audioApp'
  };

  constructor(private commonService: CommonService) {
    this.device = new Twilio.Device();
  }

  ngOnInit() {
    this.iframeVar = document.getElementById('iFrame');
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }

  receiveMessage(event) {
    let data: any = event.data;
    if (data && data.isFor && data.from == "videoIframe") {
      switch (event.data.isFor) {
        case "audioCallSetup":
          this.getCapabilityTokenAndSetupDevice();
          break;
        case "makeCall":
          this.makeCall(data.number)
          break;
        case "disconnectCall":
          this.device.disconnectAll();
          break;
        case "destroy":
          this.device && this.device.destroy();
          break;
      }
    }
  }

  getCapabilityTokenAndSetupDevice() {
    this.commonService.getCapabilityTokenAndSetupDevice().subscribe((data: any) => {

      if (data.token) {
        this.setUpTwilioDevice(data);
      }
    })
  }

  setUpTwilioDevice(data) {

    //setup Twilio.Device
    this.device.setup(data.token);

    // Is twilio device ready
    this.onReady();

    this.onError();

    //Call connect 
    this.connectCall();

    // On disconnect call
    this.onDisconnectCall();

    // On device change
    this.onDeviceChange();

    // Show audio selection UI if it is supported by the browser.
    if (this.device.audio.isVolumeSupported) {

    }
  }

  onReady() {
    var that = this;
    that.device.on('ready', function (device) {

      that.onDeviceReady();
    })
  }

  onError() {
    var that = this;
    this.device.on('error', function (error) {

    });
  }

  onDeviceChange() {
    // this.device.audio.on('deviceChange', this.updateAllDevices);
  }


  makeCall(number) {
    // get the phone number to connect the call to
    var params = {
      To: number
    };

    this.device.connect(params);
  }

  connectCall() {
    this.device.connect(conn => {

      this.sendData.isFor = 'connectionEstablished';
      this.sendDataToVideoIframe(this.sendData);
    });
  }

  onDisconnectCall() {
    var that = this;
    this.device.on('disconnect', function (conn) {

      that.disconnetCall();
    });
  }

  disconnetCall() {
    this.sendData.isFor = "disconnectCall"
    this.sendDataToVideoIframe(this.sendData);
  }

  onDeviceReady() {
    this.sendData.isFor = "deviceIsReady"
    this.sendDataToVideoIframe(this.sendData)
  }

  sendDataToVideoIframe(data) {
    this.iframeVar && this.iframeVar.contentWindow.postMessage(data);
  }

}
