import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {InvalidFieldsComponent} from '../../components/shared/components/invalid-fields/invalid-fields.component';

@Injectable()
export class ValidationHelperService {
  constructor(private dialog: MatDialog) {
  }

  public getInvalidKeys(form: FormGroup, fieldsErrorMap: { [key: string]: string }, currentKey = ''): string[] {
    const invalidFields = [];
    for (const abstractControlsKey in form.controls) {
      if (form.controls[abstractControlsKey].invalid) {
        if (form.controls[abstractControlsKey] instanceof FormGroup || form.controls[abstractControlsKey] instanceof FormArray) {
          invalidFields.push(
            ...this.getInvalidKeys(
              form.controls[abstractControlsKey] as FormGroup,
              fieldsErrorMap,
              isNaN(+abstractControlsKey) ? `${currentKey}${abstractControlsKey}.` : currentKey)
          );
        } else {
          if (form.controls[abstractControlsKey] instanceof FormControl) {
            invalidFields.push(fieldsErrorMap[`${currentKey}${abstractControlsKey}`] || `${currentKey}${abstractControlsKey}`);
          }
        }
      }
    }
    return invalidFields.filter((f, i) => invalidFields.indexOf(f) === i);
  }

  public openErrDialog(invalidFields: string[]): void {
    invalidFields.sort();
    this.dialog.open(InvalidFieldsComponent, {
      data: invalidFields,
      panelClass: 'error-fields-dialog'
    });
  }
}
