import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddressComponent} from './components/address/address.component';
import {HumanReadablePipe} from './pipes/human-readable.pipe';
import {OverflowTooltipDirective} from '../../core/directives/overflow-tooltip.directive';
import {TimezoneNamePipe} from './pipes/timezone-name.pipe';
import {CommonTooltipDirective} from '../../core/directives/common-tooltip.directive';

@NgModule({
  declarations: [
    AddressComponent,
    HumanReadablePipe,
    OverflowTooltipDirective,
    TimezoneNamePipe,
    CommonTooltipDirective
  ],
  imports: [CommonModule],
  providers: [TimezoneNamePipe],
  exports: [
    AddressComponent,
    HumanReadablePipe,
    OverflowTooltipDirective,
    TimezoneNamePipe,
    CommonTooltipDirective
  ]
})
export class SharedModule { }
