export const patientType = [
    { "id": 1, "name": "New Patient" },
    { "id": 2, "name": "Follow Up" }
]

export const filterOptions = [
    { "id": 1, "name": "Day" },
    { "id": 2, "name": "Week" },
    { "id": 3, "name": "Month" }

]

export const appointmentActions = [
    { "id": 1, "name": "Edit" },
    { "id": 2, "name": "Cancel" }]

export const appointmentStatus: any = {
    complete: "COMPLETED",
    rescheduled: "RESCHEDULED",
    canceled: "CANCELED",
    scheduled: "SCHEDULED"
}

export const preventKeyArr: any[] =
    [9, 16, 35, 36, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 60, 62, 94, 111, 123,
        125, 186, 187, 188, 189, 190, 191, 192, 219, 220, 221, 222];



export const preventKeyEmailArr: any[] = [32, 220];

export const MaritalStatus = [
    {
        value: "S",
        status: "Single"
    },
    {
        value: "P",
        status: "Partner"
    },
    {
        value: "M",
        status: "Married"
    },
    {
        value: "X",
        status: "Separated"
    },
    {
        value: "D",
        status: "Divorced"
    },
    {
        value: "W",
        status: "Widowed"
    },
    {
        value: "U",
        status: "Unknown"
    }
]

export const GenderList = [
    {
        value: "M",
        name: "Male"
    },
    {
        value: "F",
        name: "Female"
    }
]
// export const statusFilter:any=[
//     {"id":0, "name":"All"},
//     {"id":1, "name":"Active"},
//     {"id":2, "name":"In-Active"}]

export const CALLBTNTIME = 15;

export const TransactionType: any = {
    AUTHONLY: "authOnlyTransaction",
    PRIORAUTH: "priorAuthCaptureTransaction",
    AUTHCAPTURE: "authCaptureTransaction"
}

export enum SearchTerms {
    TWO_CHAR = "Enter at least 2 characters to search",
    THREE_CHAR = "Enter at least 3 characters to search",
    NOT_FOUND = "No items found"
}

export const ExpandView: any = {
    MEDICATION: "MEDICATION",
    DIAGNOSES: "DIAGNOSES",
    PROGRESSNOTES: "PROGRESSNOTES"
}
export const ROUND_ACTION = {
    ADD: "ADD",
    REMOVE: "REMOVE"
}

export const appointmentType = {
    all: "ALL_APPOINTMENTS",
    outPatient: "OUT_PATIENT_APPOINTMENT",
    inPatient: "IN_PATIENT_ROUNDING_APPOINTMENT"
}

export enum AdmitOrDenyEventName {
    ADMIT = "PROVIDER_ADMIT_USER",
    DENY = "PROVIDER_DENY_USER",
    USER_REQUEST = "USER_REQUEST_TO_PROVIDER",
    USER_CANCEL = "USER_CANCEL_REQUEST"
}


export const DropdownType = {
    checkbox: "CHECKBOX",
    viewOnly: "VIEW_ONLY",
    viewAndSelect: "VIEW_AND_SELECT",
    radio: "RADIO"
}

export const TooltipInfo = {
    INFO_PROVIDER_NPI: "If you are a solo provider without a group NPI, enter your provider NPI number.",
    INFO_TAX_ID: "A tax ID for the practice is required for appointment insurance verification.",
    INFO_PRACTICE_MAIL: "Practice email address receives emails related to billing, agreements and general account questions.",
    INFO_SPECIALITY: "Select all specialties offered by your practice!",
    INFO_PRACTICE_DESC: "Describe the practice (mission, vision, culture) using up to 1000 characters.  This field is displayed with each provider profile in nodMD's find-a-specialist directory.",
    INFO_CONFIRMATION_EMAIL_ADDR: "A practice can have up to 4 email addresses to confirm acceptance of appointments and referrals sent to your practice. At least 1 email is required. Select email addresses for individuals authorized to accept referrals and online bookings.",
    INFO_SUPER_USER: "A superuser has maximum permissions including to add/edit/delete other users in the practice.  View settings tab in the specialist portal for more details.",

    INFO_PROFILE: "This photo is displayed in search results and your profile.  If you do not provide a profile photo, an icon selected by nodMD is displayed in your profile.",
    INFO_STATE: "Select all states where you are licensed!",
    INFO_SUB_SPECIALITIES: "Select up to 6 sub-specialties which demonstrate your primary areas of expertise.",
    INFO_LANGUAGE: "List language fluency other than English - great for second opinions!",
    INFO_ACCEPT_NEW_PATIENT: "Opt-out if you are not currently accepting new patients.",
    INFO_ACCEPT_CASH_PAYMENT: "Opt-in if you accept cash payments.",
    INFO_FIND_A_SPECIALIST: "Select if you want your profile listed for public searches, private (only providers/patients with nodMD accounts) or both.",
    INFO_REMOTE_PATIENT: "Select YES if you offer remote patient monitoring to patients. List which RPM vendor you use.",
    INFO_RPM_PROGRAM: "Select YES if you want information about nodMD's RPM program with a third-party vendor.",
    INFO_INSURANCE_ACCEPTANCE: "Select insurance carriers and plans your practice accepts.  Accuracy can increase bookings.",
    INFO_PRIOR_AUTH: "Would you like information about nodMD's automated prior auth service?",
    INFO_SECOND_OPINION: "Would you like information about nodMD's second opinion service?",
    INFO_ACUTE_SPECIALTY: "Would you like information about nodMD's acute specialty services?",
    INFO_INSURANCE_VERIFICATION: "This is a free service. You may want to opt-out if your office doesn’t require automated insurance verification.",
    INFO_MESSAGE_TO_PATIENT: "A brief message about current events can be changed regularly, such as: facemask policy, closed for Thanksgiving and so on.",
    INFO_EXPERTISE: `In addition to sub-specialties selected, list other "areas of expertise" to promote your specialty and allow the patient to find you.`,
    INFO_SAMPLE_PROFILE: `Provide a general overview about you.  See "view sample profile" for ideas.`,
    INFO_PROVIDER_BIO: `Provide a general overview about you.  See "view sample profile" for ideas.`,
    INFO_SLOT_CREATION:`Select date range to create appointment slots when you are available for work at a particular location.`,
    INFO_BLOCK_DAYS:`Select a block of unavailable work days for a particular work location (example: vacation).`,
    INFO_AVAILABILITY_SETTINGS:`Work calendar availability settings selected are applied to ALL work locations (unlike block days and slot creation settings which are applied to specific locations).`,
    INFO_MINIMUM_SCHEDULING_NOTICE:`This is the earliest appointment online booking available.  Example: selecting "1 day" prevents online booking earlier than the next 24 hours.`,
    INFO_EVENT_BUFFER:` Adds a buffer before a next appointment.  Example: select 15 min to place a 15 min mandatory space between appointments.`,
    INFO_TIMEZONE:`Select a timezone for viewing appointments - this does not change the timezone selected by the user in the availability settings.`,
    INFO_PROVIDER_PHONE_NUMBER:`Some platform communication to user is via text`,
    INFO_ABOUT_ADV_WEEK: ' How far in advance do you want appointments set (# of weeks selected by the user)'
}

export const SpecialistBasicInfoSelection = {
    ENROLL_ME: "ENROLL_ME",
    DO_NOT_ENROLL_ME: "DO_NOT_ENROLL_ME",
    EMAIL_ME: "EMAIL_ME"
}

export const BillingPlanNames = {
    BASIC: "Basic",
    PREMIUM: "Premium",
    FREE: "Free",
    BONUS_TRIAL: "BonusTrialOffer"
}
