import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Subscription, timer} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class InboxCountService {
  public unreadMessagesCount = 0;
  private interval: Subscription;
  private requestInProgress = false;

  constructor(private http: HttpClient) {
  }

  public startPingingCount(): void {
    if (this.interval) {
      return;
    }
    this.interval = timer (0, 10000).pipe(
      filter(() => !this.requestInProgress),
      switchMap(() => this.http.get<number>(`${environment.baseUrl}communicator-service/api/v1/inbox/count-unread`))
    ).subscribe((count) => {
      this.unreadMessagesCount = count;
    });
  }

  public stopPinging(): void {
    if(this.interval) {
      this.interval.unsubscribe();
      this.interval = null;
    }
  }
}
