import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from 'src/app/core/api-services/admin.service';
import { AuthService } from 'src/app/core/Authentication/auth.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('closeModal') private closeModal: ElementRef;

  resetPasswordForm: FormGroup;
  isSubmit: boolean = false;
  showSpinner: boolean = false;
  userId: any;
  regX: any;
  patternErr: any;

  constructor(private formBuilder: FormBuilder,
    private _admin: AdminService,
    private _auth: AuthService,
    private _alert: AlertService,
    private _translate: TranslateService) {

    if (this._auth.getUserId())
      this.userId = this._auth.getUserId();
  }

  ngOnInit() {
    this.getRegX();
    this.validateForm();
  }

  getRegX() {
    this.regX = new RegExp("aa");
    this._admin.getRegX().subscribe((res: any) => {
      if (res) {
        let pattern = res.pattern as string;
        this.regX = new RegExp(pattern);
        this.patternErr = res.message;
        this.resetPasswordForm.controls['password'].setValidators(Validators.pattern(this.regX))
      }
    })
  }

  validateForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
        Validators.minLength(8)
      ]],
      rePassword: ['', Validators.required],
    },
      { validator: this.checkIfMatchingPasswords('password', 'rePassword') }
    )
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  get formControls() { return this.resetPasswordForm.controls; }

  // showPassword(pass, type) {
  //   if (type == 'password') {
  //     pass.setAttribute('type', 'text')
  //   } else if (type == 'text') {
  //     pass.setAttribute('type', 'password')
  //   }
  // }

  onSubmit() {
    this.isSubmit = true;
    this.showSpinner = true
    if (this.resetPasswordForm.valid) {
      this.showSpinner = false;
      if (this.userId) {
        this.userId = this._auth.getUserId()
      }
      let data: any = {
        id: this.userId,
        password: this.resetPasswordForm.get('password').value
      }
      this.updatePassword(data)
    } else {
      this.showSpinner = false;
    }
  }

  updatePassword(data) {
    this._admin.updateUsersPassword(data).subscribe(
      res => {
        sessionStorage.setItem('isReset', 'false');
        this.closeModal.nativeElement.click();
        this._alert.successAlert(this._translate.instant('notification.success.user.resetpass'));
      },
      err => {

      }
    )
  }
}
