import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-invalid-fields',
  templateUrl: './invalid-fields.component.html',
  styleUrls: ['./invalid-fields.component.css']
})
export class InvalidFieldsComponent {
  constructor(public dialogRef: MatDialogRef<InvalidFieldsComponent>,
              @Inject(MAT_DIALOG_DATA) public fields: string[]) { }

  onCloseClick() {
    this.dialogRef.close();
  }

}
