import { Component, ViewChild, ElementRef, OnDestroy, Renderer, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoaderService } from './core/services/loader.service';
import { AppointmentService } from './core/api-services/appointment.service';
import { CommonService } from './core/services/common.service';
import { interval } from 'rxjs/internal/observable/interval';
import { AutoLogoutService } from './core/services/auto-logout.service';
import { AdminService } from './core/api-services/admin.service';
import { AuthService } from './core/Authentication/auth.service';

declare global {
  interface Window { dataLayer: any[]; }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('paymentForm') paymentForm: ElementRef<HTMLElement>
  @ViewChild('one') d1: ElementRef;
  @ViewChild('openResetPassowrd') openResetPassowrd: ElementRef<HTMLElement>;

  loading;
  full: boolean = true;
  show: boolean = false;
  // myInterval: any;
  constructor(private translate: TranslateService,
    titleService: Title,
    private router: Router,
    public loader: LoaderService,
    private autoLogout: AutoLogoutService,
    public _appointment: AppointmentService,
    public _common: CommonService,
    private _auth: AuthService,
    private _admin: AdminService) {
    {
      if (sessionStorage.getItem('roles') !== null) this._auth.getandSetPermissions();
      this.loading = true;
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join('-');
          var newTitle = title.split(',');
          titleService.setTitle(newTitle[0]);
        }
      });
      // this.translate.setDefaultLang('en');
      translate.addLangs(['en']);
      translate.setDefaultLang('en');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en/) ? browserLang : 'en');
    }
  }

  ngOnInit() {
    this._admin.$resetPasswordSubject.subscribe(res => {
      if (res) {
        this.openResetPasswordForm()
      }
    });

    if (sessionStorage.getItem('isReset') == 'true') {
      this.openResetPasswordForm()
    }
  }

  openResetPasswordForm() {
    this.openResetPassowrd.nativeElement.click();
  }


  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {

          if (event.url === '/') this.loading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loading = false;
        }
      });

    let formData = `<input type="hidden" name="dataValue" id="dataValue" />
    <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
    <button type="button" class="AcceptUI" data-billingAddressOptions='{"show":true, "required":false}'
      data-paymentOptions='{"showCreditCard":true, "showBankAccount":true}' id="AcceptUI" data-apiloginid=""
      data-clientKey="" data-acceptUIFormBtnTxt="Submit"
       data-responseHandler="responseHandler" hidden></button>`;

    this.paymentForm.nativeElement.innerHTML = formData;
    // this.getMerchantCredentialsForAuthNet();

  }

}
