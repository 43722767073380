import { TitleCasePipe } from '@angular/common';
import { Injectable, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import * as Rx from "rxjs";
import { ProgressNotes } from 'src/app/components/shared/models/progressNotes';
import { ArraySortPipe } from '../pipes/sort.pipe';
import { VitalAcronymPipe } from '../pipes/vital-acronym.pipe';
const addUserSubject = new Rx.BehaviorSubject(false);

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  public providerSubject = new Rx.BehaviorSubject(null);
  iframeStatus = new Rx.BehaviorSubject(false);
  currentStatus$ = this.iframeStatus.asObservable();

  // Audio call
  isCalling: boolean = false;
  isConnectionEstablished: boolean = false;
  isConnection: boolean = false;

  constructor(private router: Router,
    private titleCase: TitleCasePipe,
    private vitalPipe: VitalAcronymPipe,
    private arraySortPipe: ArraySortPipe) {
  }

  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url;
    this.router.navigate([currentUrl])
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }


  searchSlots(model: any) {
    throw new Error("Method not implemented.");
  }
  private data: any;
  private group: any
  show: boolean = false;

  setProviders(data: any) {
    this.data = data;
  }

  getProviders(): any {
    return this.data;
  }
  setGroupDetail(data: any): any {
    this.group = data;
  }
  getGroupDetail(): any {
    return this.group;
  }
  showIframe(value) {
    this.show = value;
  }

  getIframeStatus(): boolean {
    return this.show;
  }

  addUser(open: boolean) {
    addUserSubject.next(open);
  }
  checkIsModalActive() {
    let isOpen = false;
    addUserSubject.subscribe((data: boolean) => {
      isOpen = data;
    })
    return isOpen;
  }

  setFormatForProgressNotes(data, inPatientId, providerId): ProgressNotes {

    let progressNotes: ProgressNotes = new ProgressNotes(inPatientId, providerId);
    let daiagnoses = (data.inPatientDiagnosisDTOs && data.inPatientDiagnosisDTOs.length > 0) ?
      data.inPatientDiagnosisDTOs.map(e => this.titleCase.transform(e.icd10Description)).join(', ') : '';

    let medications = (data.inPatientMedicationsDTOs && data.inPatientMedicationsDTOs.length > 0) ?
      data.inPatientMedicationsDTOs.map(e => {
        let d = e.description ? e.description : '';
        let g = e.generic ? e.generic : '';
        let s = e.strength ? e.strength : '';
        let su = e.strengthUOM ? e.strengthUOM : '';
        let re: string = "";
        if (g || s || su) {
          let bracketText = `${g} ${s} ${su}`;
          re = `${d} (${bracketText.trim()})`;
          return re;
        }
      }) : '';

    medications = medications ? medications.filter(e => e != undefined) : '';
    medications = medications ? medications.join(', ') : '';

    let allergies = (data.inPatientAllergiesDTOs && data.inPatientAllergiesDTOs.length > 0) ? data.inPatientAllergiesDTOs.map(e => e.allergen).join(', ') : '';

    let subjective: string = `Reason for Consultation: \n\nHistory of Present Illness:\n\nPast Medical History:\n${daiagnoses}\n\nMedications:\n${medications}\n\nAllergies:\n${allergies}\n\nSocial History:\n\nFamily History:\n\nReview of Systems:\n`;
    progressNotes.subjectiveNotes = subjective;
    let vitalAcronym: Array<any> = this.vitalPipe.transform(data.inPatientVitalsDTOs);
    let orderedVitals: Array<any>;
    let vitals: string;
    if (vitalAcronym) {
      orderedVitals = this.arraySortPipe.transform(vitalAcronym, 'sortOrder');
      if (orderedVitals) {
        vitals = orderedVitals
          .map(e => `${e.type ? e.type : ''} ${(e.value || e.value == 0) ? e.value : ''} ${e.unit ? e.unit : ''}`).join(', ');
      } else {
        vitals = '';
      }
    }

    let objective: string = `Physical Exam:\n${vitals}\n\n`;
    progressNotes.objectiveNotes = objective;

    return progressNotes;
  }


  showHideScroll(selectedDiv): boolean {
    let atBottom = selectedDiv.scrollTop + 1.5 >= (selectedDiv.scrollHeight - selectedDiv.offsetHeight)
    if (atBottom) {
      return false;
    } else {
      return true;
    }
  }

  setIsMakingCall(isCalling) {
    this.isCalling = isCalling;
  }

  getIsMakingCall(): boolean {
    return this.isCalling;
  }

  setIsConnectionEstablished(flag) {
    this.isConnectionEstablished = flag;
  }

  getIsConnectionEstablished(): boolean {
    return this.isConnectionEstablished;
  }

  setInternetConnectionFlag(flag) {
    this.isConnection = flag;
  }

  getInternetConnectionFlag(): boolean {
    return this.isConnection;
  }
}
