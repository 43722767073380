import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private pusherClient: Pusher;
  env: any = environment;

  constructor() {
    this.pusherClient = new Pusher(`${this.env.pusherKey}`, { cluster: 'us3' });
  }

  getPusherClient() {
    return this.pusherClient;
  }

  subscribe(channel) {
    return this.pusherClient.subscribe(channel);
  }

  unsubscribe(channel) {
    return this.pusherClient.unsubscribe(channel);
  }

}
