import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'availability-modal-alert',
  templateUrl: './availability-modal-alert.component.html',
  styleUrls: ['./availability-modal-alert.component.css']
})
export class AvailabilityAlertComponent {
  constructor(
    public dialogRef: MatDialogRef<AvailabilityAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
