export const INDEX_ROUTE = '/';

// Auth Routes
export const SPECIALIST_LOGIN_ROUTE = 'login';
export const SPECIALIST_HOME_ROUTE = '/alert';
export const SPECIALIST_SPECIALISTS_ROUTE = '/specialists';
export const SPECIALIST_APPOINTMENTS_ROUTE = '/appointments';
export const SPECIALIST_PATIENTS_ROUTE = '/patients';
export const SPECIALIST_SETTINGS_ROUTE = '/settings';
export const LEARN_MORE_ROUTE = '/learn-more';
export const SPECIALIST_ALERT_ROUTE = '/alert';
export const SPECIALIST_OPENTASKS_ROUTE = '/opentask';
export const SPECIALIST_PATHWAYS_ROUTE = '/pathways';
export const SPECIALIST_BILLING_ROUTE = '/reports';
export const SPECIALIST_FAST_CONSULT = '/smart-consult';
export const SPECIALIST_FACILITIES = '/facilities';
export const REFERRAL_SPECIALIST_FACILITIES = '/referral';
export const FIND_A_DOC = '/nodbookings';
export const SPECIALIST_ENDORSEMENTS = "/endorsements";
export const SPECIALIST_INBOX = "/inbox";


export const SPECIALIST = '/';
