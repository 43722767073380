import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';

@Component({
  selector: 'app-redirect',
  template: `
    <p> </p>
  `,
})
export class RedirectComponent implements OnInit {
  private apiUrl: string;
  private shortUrl: string | null = null;
  private longUrl: string | null = null;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.apiUrl = this.getApiUrl();
  }

  // test url 1 = 
  // test url 2 = 
  // test url 3 = 
  // test url 4 = 
  // test url 5 = 
  // test url 6 = 

  ngOnInit(): void {
    this.shortUrl = this.route.snapshot.paramMap.get('shortUrl');
    console.log('Received short URL:', this.shortUrl); // Add log for debugging
    if (this.shortUrl) {
      this.redirectToLongUrl(this.shortUrl).catch(error => {
        console.error('Redirection failed:', error);
        // Optionally, navigate to an error page or show an error message
      });
    }
  }

  private getApiUrl(): string {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      return 'https://api.qa.nodmd.com/appointment-service/api/v1/auth/shorten';  // Local development URL
    } else if (hostname.includes('qa.nodmd.com')) {
      return 'https://api.qa.nodmd.com/appointment-service/api/v1/auth/shorten';  // QA environment URL
    } else {
      return 'https://api.nodmd.com/appointment-service/api/v1/auth/shorten';  // Production URL
    }
  }

  private async getLongUrl(shortUrl: string): Promise<string> {
    try {
      const response = await axios.get(`${this.apiUrl}/${shortUrl}`);
      console.log('API Response:', response);
      console.log('Long URL:', response.data);
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      return response.data; // Accessing longUrl directly from response.data
      console.log('Long URL:', response.data);
    } catch (error) {
      console.error('Error fetching long URL:', error);
      console.log('short URL:', shortUrl);
      console.log('API URL:', this.apiUrl);
    }
  }

  private async redirectToLongUrl(shortUrl: string): Promise<void> {
    try {
      this.longUrl = await this.getLongUrl(shortUrl);
      console.log('Long URL:', this.longUrl);  // Log the long URL to the console
      if (this.longUrl) {
        window.location.href = this.longUrl; // Redirect to the long URL
        console.log('Redirected to Long URL:', this.longUrl);
      } else {
        throw new Error('Long URL is undefined or null.');
      }
    } catch (error) {
      console.error('Error redirecting to long URL:', error);
      console.log('shortUrl:', shortUrl);
      console.log('longUrl:', this.longUrl);
      // Optionally, navigate to an error page or show an error message
    }
  }


  async navigateToLongUrl(): Promise<void> {
    console.log('Long URL button clicked.');
    if (this.shortUrl) {
      console.log('Short URL is available:', this.shortUrl);
      try {
        this.longUrl = await this.getLongUrl(this.shortUrl);
        console.log('Fetched Long URL:', this.longUrl);
        if (this.longUrl) {
          console.log('Navigating to Long URL:', this.longUrl);
          this.router.navigate([this.longUrl]);
        } else {
          console.error('Long URL is undefined or null. longUrl:', this.longUrl);
          console.log('shortUrl:', this.shortUrl);
        }
      } catch (error) {
        console.error('Error navigating to long URL:', error);
      }
    } else {
      console.error('Short URL is not available.');
    }
  }
}
