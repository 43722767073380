
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';
import { PendingChangesModalComponent } from 'src/app/components/shared/common/pending-changes-modal/pending-changes-modal.component';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private dialog: MatDialog) { }

  canDeactivate(component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | Observable<boolean> {

    if (nextState.url === '/login') return true;

    return component.canDeactivate() ? true : this.openConfirmation();
  }

  openConfirmation(): Observable<boolean> {
    const dialogRef = this.dialog.open(PendingChangesModalComponent, {
      data: `Do you want to leave without saving your changes?`,
      panelClass: 'delete-confirm-dialog-mat-dialog-container'
    });
    return dialogRef.afterClosed().pipe(
      map(res => res === true)
    );
  }
}
