import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/Authentication/auth.service';
import { Router, ActivatedRoute, NavigationStart, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AdminService } from 'src/app/core/api-services/admin.service';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import decode from 'jwt-decode';
import { UserService } from 'src/app/core/services/user.service';
import { Permissions } from 'src/app/config/permissions';
import { CommonService } from 'src/app/core/api-services/common.service';
import { ProvidersService } from 'src/app/core/api-services/providers.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AlertService } from '../../core/helpers/alert.service';
import { EmailVerificationPopupComponent } from 'src/app/components/shared/common/email-verification-popup/email-verification-popup.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  baseUrl: string = environment.baseUrl;
  model: any = {
    // "domain":"SoronaCare",
    "email": '',
    "password": ''
  }
  rememberMe: boolean = false;
  redirectPageModel: any = {
    redirectPageName: '',
    patientId: '',
    appointmentId: ''
  }

  forgetPasswordMessage: any;
  signupUrl = environment.signupUrl;
  versionNumber = environment.versionNumber;
  dashBoardType: string = "Specialist Login";
  selectedBillingPlan: any = {};
  headerText: any;
  billingPlanResponse: any = [];
  featurePlan: any = {};
  providerId: any;
  availability: any;
  isSubscriptionActive: any;
  isBonusTrialOfferActepted: any;
  userId: any;
  userType: any;
  isNonProductionEnvironment: boolean;

  constructor(private _http: AdminService,
    private _auth: AuthService,
    private user: UserService,
    private loader: LoaderService,
    private router: Router,
    private translate: TranslateService,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _loader: LoaderService,
    private alertService: AlertService,
    private _provider: ProvidersService,
    private _message: MessageService,
    private _admin: AdminService,
    private dialog: MatDialog) {

    const snapshot: RouterStateSnapshot = router.routerState.snapshot;

    if (snapshot.url == "/pcp-login") {
      this.dashBoardType = "Primary Care Login";
    } else {
      this.dashBoardType = "Specialist Login";
    }
    this.checkIfRememberMe();
    this._route.queryParamMap.subscribe(queryParams => {
      let redirctPageToken = queryParams.get("redirectPage");

      const decodeToken = decode(redirctPageToken);

      if (decodeToken) {
        this.redirectPageModel.patientId = decodeToken.patientId;
        this.redirectPageModel.appointmentId = decodeToken.appointmentId;
        this.redirectPageModel.redirectPageName = decodeToken.redirectPageName;
        this.redirectPageModel.startTime = decodeToken.startTime;
        this.redirectPageModel.endTime = decodeToken.endTime;
      };
    });


  }

  ngOnInit() {
    if (window.location.href.includes('localhost') || window.location.href.includes('dev') || window.location.href.includes('qa') || window.location.href.includes('staging')) {
      this.isNonProductionEnvironment = true;
    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // public beforeunloadHandler($event) {
  //   $event.returnValue = "Are you sure?";
  // }

  //  on blur method
  onBlurMethod(e) {
    if (e.target.value != '')
      e.target.classList.add('has-val');
  }

  handleRememberMe(event) {
    if (event.target.checked === true) {
      this.rememberMe = true;

    }
  }

  onBlurMethodEmail(email) {
    if (email) {
      // e.target.classList.add('has-val');
      this.checkIsEmailVerified(email);
    }
  }
  checkIsEmailVerified(email) {
    email = encodeURIComponent(email)
    this._http.getEmailVerificationDetail(email).subscribe(
      (res: any) => {


        let response = res;
        this.userId = response.userId;

        if (res.status) {
          const dialogRef = this.dialog.open(EmailVerificationPopupComponent, {
            data: { 'email': this.model.email, userId: this.userId }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) console.log("okay clicked");
          });
        }
        else this.auth();
      }
    )
  }

  verifyEmail(res) {
    Swal.fire({
      position: 'center',
      imageUrl: '/assets/images/nod-logo.png',
      imageWidth: 400,
      imageHeight: 110,
      title: `${res.message}`,
      showConfirmButton: true,
      backdrop: `rgb(39, 103, 106)`,
    }).then((result) => {

    });
  }

  /* **********************************************************************************
                                 LOGIN API CALL
  **********************************************************************************/
  onSubmit(): void {
    this.onBlurMethodEmail(this.model.email);
  }

  auth() {
    let data: any = {
      email: this.model.email + '|' + 'SPECIALIST',
      password: this.model.password
    };
    this.loader.showLoader(true);
    this._http.login(data).then(
      (response: any) => {
        const jwtToken = response.headers.get("authorization");
        const userInfo = response.body;
        userInfo.isPcp = this.router.url.includes('pcp');
        this.loader.showLoader(false);
        this.checkIsPasswordReset(userInfo)
        if (userInfo && jwtToken != null) {
          this._auth.setSessionToken(jwtToken, userInfo);
          if (this.rememberMe)
            this._auth.setLocalToken(this.model);
          else
            this._auth.clearIfRememberMeExist();
        }

        let roles: any[] = response.body.roles;

        let isAdmin = roles.some(r => (r.name == "Specialist Admin" || r.name == "Primary Care Admin"));
        let isProvider = isAdmin ? false : true;
        if (isProvider) {
          this.userType = "Provider";
          sessionStorage.setItem('type', "Provider");
        }
        else {
          this.userType = "Admin";
          sessionStorage.setItem('type', "Admin");
        }

        this.checkPermissionAndNavigate();
        if (window.innerWidth < 768) {
          this._admin.sendMobileLoginInfo(userInfo.id);
        }
        if (!this._auth.isTokenExpired()) {
          this.navigateToHome();
        }
        else
          this._auth.logout();
      }, () => {
        this.alertService.errorAlert(
          this.translate.instant('notification.error.loginError')
        );
      })
  }

  async navigateToHome() {
    this.loader.showLoader(true);
    if (this.userType == "Provider")
      await this.getGroupBillingPlanDetails();
    else
      await this.getHighestPlanForAdmin();



    if (this._auth.getPlan() == "Free") {
      this.router.navigateByUrl(environment.nodbookingRoot);
      this.loader.showLoader(false);
      return;
    }
    if (this._auth.currentUrlPart) {
      this.router.navigateByUrl(this._auth.currentUrlPart);
      this.loader.showLoader(false);
      return;
    }
    if (this.redirectPageModel.redirectPageName) {
      this.loader.showLoader(false);
      if (this.redirectPageModel.redirectPageName == 'WAITING_ROOM') {

        sessionStorage.setItem('redirect', JSON.stringify(this.redirectPageModel));
      }
    }

  }

  checkPermissionAndNavigate() {
    var currentUser: any;
    this.user.currentUser.subscribe(user => {
      currentUser = user;
    });
    this.loader.showLoader(false);
    if (currentUser && currentUser.length > 0) {
      if (this.redirectPageModel.redirectPageName) {
        switch (this.redirectPageModel.redirectPageName) {
          case "NOTIFICATION":
            if (currentUser.includes(Permissions.VIEW_NOTIFICATION_SETTING)) this.navigateByUrl('settings/notification-setting')
            else this.navigateByUrl('settings/profile');
            break;
          case "PCC_FACILITY_CONFIGURATION":
            this.navigateByUrl('facilities')
            break;
          case "WAITING_ROOM":
            this.navigateByUrl('appointments');
            break;
        }
      } else {
        if (currentUser.includes(Permissions.VIEW_APPOINTMENT) || currentUser.includes(Permissions.CREATE_EDIT_CANCEL_VIEW_APPOINTMENT))
          this.navigateByUrl('appointments');
        else if (currentUser.includes(Permissions.VIEW_PATIENT) || currentUser.includes(Permissions.CREATE_EDIT_VIEW_PATIENT))
          this.navigateByUrl('patients');
        else
          this.navigateByUrl('settings/profile');
      }
    }
    else
      this.navigateByUrl('settings/profile');

  }


  navigateByUrl(url: string) {
    this.router.navigate([url]).then(nav => {
      this.loader.showLoader(false);
    }, err => {

      this.loader.showLoader(false);
    });
  }

  createAccount() {
    window.location.href = environment.signupUrl;
  }

  forgotPassword() {
    Swal.fire({
      title: this.translate.instant('verification.unameOrEmail'),
      input: 'text',
      imageUrl: '/assets/images/nod-logo.png',
      imageWidth: 400,
      imageHeight: 110,
      backdrop: `rgb(39, 103, 106)`,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (isUser) => {

        if (!isUser) {
          Swal.showValidationMessage(
            'Please Enter Email ID'
          )
        } else {
          isUser = encodeURIComponent(isUser);
          return fetch(`${this.baseUrl}user-service/api/v1/auth/forgot-password?userName=${isUser}&dashboard=specialist`, { method: 'post', body: null })
            .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(response => {

              var data: any = response;
              this.forgetPasswordMessage = data.message;
              if (data.status) {
                return data;
              } else {
                throw new Error(data.message)
              }
            })
            .catch(error => {
              Swal.showValidationMessage(
                this.forgetPasswordMessage == 'Your account is not completed. Please complete the account from here' ? `<a target="_blank" href=${this.signupUrl}> ${error} </a>` : `${error}`
              )
            })
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value && result.value.status) {
        Swal.fire({
          position: 'center',
          imageUrl: '/assets/images/nod-logo.png',
          imageWidth: 400,
          imageHeight: 110,
          title: `${result.value.message}`,
          confirmButtonText: 'Ok',
          backdrop: `rgb(39, 103, 106)`,
          timer: 5000
        }).then((r) => {
          this.router.navigateByUrl(`/login`)
        });
      }
    })
  }

  checkIfRememberMe() {
    if (this._auth.getRememberMeData()) {
      let data = JSON.parse(this._auth.getRememberMeData());
      this.model.email = data.email;
      this.model.password = data.password;
      this.rememberMe = data.isRememberMe;
    }

  }

  ngAfterViewInit() {
    window.document.getElementById('email').setAttribute('class', 'input has-val');
    window.document.getElementById('password').setAttribute('class', 'input has-val');
  }
  prevent(event) {
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  checkIsPasswordReset(data) {
    // 
    if (data.pwdUpdatedFromAdmin) {

      sessionStorage.setItem("isReset", "true");
    } else {

    }
  }

  showPassword(passEl, type) {
    var el = passEl;
    if (type == "text") {
      el.setAttribute("type", "password")
    } else {
      el.setAttribute("type", "text")
    }
  }


  checkPccConnection() {
    this._http.checkPccConnection(this._auth.getSpecialist()).subscribe((res: any) => {
    })
  }
  mobileTesting() {

    if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // true for android or ios/ipad/ipod devices
      //
      // window.location.href = "https://sleepy-engelbart-2d62cc.netlify.app/";

      this.router.navigate(['mobile-testing']);
    }
  }

  async getHighestPlanForAdmin() {
    let billingPlanId;
    await this._provider.getHigestBillingPlan(this._auth.getGroupId()).toPromise().then(((res: any) => {

      if (res)
        billingPlanId = res.billingPlanId;
    }))
    await this.getBillingPlanByIdforAdmin(billingPlanId);
  }

  async getBillingPlanByIdforAdmin(id: any) {

    await this._commonService.getBillingPlanById(id).toPromise().then((res: any) => {

      if (res) {
        this.selectedBillingPlan['amount'] = res['amount'];
        this.selectedBillingPlan['headerText'] = res['headerText'];
        this.headerText = res['headerText'];
      }
      this.featurePlan = res;
    });
    if (this.featurePlan && this.featurePlan['featurePlanId']) {
      await this._commonService.getFeaturePlanById(this.featurePlan['featurePlanId']).toPromise().then((resp) => {
        if (resp) {
          this.selectedBillingPlan['planName'] = resp['name'];
          this._auth.setPlanToken(this.selectedBillingPlan);


        }
      });
    }
    this._admin.setPlan.next(this.selectedBillingPlan['planName']);
  }


  async getGroupBillingPlanDetails() {
    await this._commonService.getBillingPlanByProviderIdAndGroupId(parseInt(this._auth.getSpecialist()), this._auth.getGroupId()).toPromise().then((res: any[]) => {
      if (res && res.length > 0) {
        const currentPlan = res.find(({ isCurrent }) => isCurrent) || {};
        this.billingPlanResponse = res;
        if (currentPlan) {
          this.isSubscriptionActive = (currentPlan.isSubscriptionActive);
          this.isBonusTrialOfferActepted = (currentPlan.acceptBonusTrialOffer);
          this._admin.trialAllowed.next(currentPlan.isFreeTrialAllowed);
        }
        this._auth.setSaasSigned(currentPlan && currentPlan.isSaasSigned);
        this._admin.setIsSubscriptionActive.next(this.isSubscriptionActive);
        this._admin.setIsBonusTrialAccepted.next(this.isBonusTrialOfferActepted);
      }
    }, (err: any) => {

    })
    await this.getBillingPlanById(this.billingPlanResponse);
  }

  async getBillingPlanById(res: any) {
    const currentPlan = res.find(({ isCurrent }) => isCurrent);
    if (currentPlan.billingPlanId) {
      await this._commonService.getBillingPlanById(currentPlan.billingPlanId).toPromise().then((res: any) => {
        if (res) {
          this.selectedBillingPlan['amount'] = res['amount'];
          this.selectedBillingPlan['headerText'] = res['headerText'];
          this.headerText = res['headerText'];
        }
        this.featurePlan = res;
      });
      if (this.featurePlan && this.featurePlan['featurePlanId']) {
        await this._commonService.getFeaturePlanById(this.featurePlan['featurePlanId']).toPromise().then((resp) => {
          if (resp) {
            this.selectedBillingPlan['planName'] = resp['name'];
          }
        });
      }
      await this._auth.setPlanToken(this.selectedBillingPlan);
      this._admin.setPlan.next(this.selectedBillingPlan['planName']);
    }
  }

  ngOnDestroy(){
    this.loader.showLoader(false);
  }
}
