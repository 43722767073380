import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-pending-changes-modal',
  templateUrl: './pending-changes-modal.component.html',
  styleUrls: ['./pending-changes-modal.component.css']
})
export class PendingChangesModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PendingChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any,
    private _loader: LoaderService) { }

  onYesClick() {
    this._loader.showLoader(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
