import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PatientService } from 'src/app/core/api-services/patient.service';
import { AlertService } from 'src/app/core/helpers/alert.service';

@Component({
  selector: 'app-email-verification-popup',
  templateUrl: './email-verification-popup.component.html',
  styleUrls: ['./email-verification-popup.component.css']
})
export class EmailVerificationPopupComponent implements OnInit {

  varificationLink:any;
  userId:any;
  userEmail:any;

  constructor(
    public dialogRef: MatDialogRef<EmailVerificationPopupComponent>,
    private _patient:PatientService,
    private _alert:AlertService,
    @Inject(MAT_DIALOG_DATA) public userData: any
  ) {
    this.userId = userData.userId;
    this.userEmail = userData.email;
   }


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  sendVerificationLink() {
    this._patient.sendMailVerification(this.userId, encodeURIComponent(this.userEmail)).subscribe((res: any) => {
      if (res) {
        this.onNoClick();
        this._alert.successAlert(res.message);
      }
    },(err)=>{
      this._alert.errorAlert("error occured while sending verification link");
    })
  }

}
