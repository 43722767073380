import { CommonService } from './../api-services/common.service';
import { AdminService } from './../api-services/admin.service';
import { AuthService } from 'src/app/core/Authentication/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckPlanGuard implements CanActivate {

  planName: String;

  constructor(
    private _auth: AuthService,
    private _route: Router,
    private _admin: AdminService,
    private _commonService: CommonService) {
  }
  selectedBillingPlan: any = {};
  headerText: any;
  canActivate(): boolean {
    try {
      const token = this._auth.getToken();
      if (token && !this._auth.isTokenExpired()) {
        if (this._auth.getPlan() != "Free") {
          return true;
        }
        else {
          this._route.navigate(['notauthorized']);
          return false;
        }
      }
      else {
        this._auth.logout();
        return false;
      }
    } catch (error) {
      this._route.navigate(['login']);
      return false;
    }
  }
}
