<button class="close-button" mat-button (click)="onCloseClick()">x</button>
<div mat-dialog-content class="content">
  <i class="fa fa-times-circle"></i>
  <h3>Required fields with missing data:</h3>
  <div class="message-soon break-w">
    <ul>
      <li *ngFor="let field of fields">{{field}}</li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <div class="col-md-12 text-right m-t-15 d-flex justify-content-center">
    <button class="btn cancel-modal-btn text-right" (click)="onCloseClick()">Close</button>
  </div>
</div>
