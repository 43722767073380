import { AdminService } from './../../../../core/api-services/admin.service';
import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Permissions } from 'src/app/config/permissions';
import {
  SPECIALIST_ALERT_ROUTE,
  SPECIALIST_SPECIALISTS_ROUTE,
  SPECIALIST_PATIENTS_ROUTE,
  SPECIALIST_SETTINGS_ROUTE,
  SPECIALIST_OPENTASKS_ROUTE,
  SPECIALIST_APPOINTMENTS_ROUTE,
  SPECIALIST_HOME_ROUTE,
  SPECIALIST_PATHWAYS_ROUTE,
  SPECIALIST_BILLING_ROUTE,
  SPECIALIST_FAST_CONSULT,
  SPECIALIST_FACILITIES,
  SPECIALIST_INBOX,
  SPECIALIST_ENDORSEMENTS,
  REFERRAL_SPECIALIST_FACILITIES, FIND_A_DOC, LEARN_MORE_ROUTE
} from '../../../../config/constant'
import { AuthService } from 'src/app/core/Authentication/auth.service';
import { AppointmentService } from 'src/app/core/api-services/appointment.service';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/core/api-services/common.service';
import { InboxCountService } from '../../../../core/services/inbox-count.service';
import { MessageService } from 'src/app/core/services/message.service';
import { TooltipInfo } from "src/app/config/data";
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProvidersService } from 'src/app/core/api-services/providers.service';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'fix-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {
  showSideBar: boolean = false;
  alert = SPECIALIST_ALERT_ROUTE;
  home = SPECIALIST_HOME_ROUTE;
  patients = SPECIALIST_PATIENTS_ROUTE;
  specialists = SPECIALIST_SPECIALISTS_ROUTE;
  settings = SPECIALIST_SETTINGS_ROUTE;
  learnMore = LEARN_MORE_ROUTE;
  appointments = SPECIALIST_APPOINTMENTS_ROUTE;
  opentasks = SPECIALIST_OPENTASKS_ROUTE;
  findAdoc = FIND_A_DOC;
  pathway = SPECIALIST_PATHWAYS_ROUTE;
  reports = SPECIALIST_BILLING_ROUTE;
  fastConsult = SPECIALIST_FAST_CONSULT;
  facilities = SPECIALIST_FACILITIES;
  referral = REFERRAL_SPECIALIST_FACILITIES;
  inbox = SPECIALIST_INBOX;
  endorsements = SPECIALIST_ENDORSEMENTS;
  Permissions: any = Permissions;
  currentEpochTimeOfSelectedTZ: any;
  currEpoch: any;
  currentTimeZone: any;
  timzoneList: any;
  offsetMap = new Map<string, any>();
  providerId: any;
  groupId: any;
  planName: any;
  tooltipInfo = TooltipInfo;

  openTaskCount: any;
  subscriptions: Subscription[] = [];
  versionNumber = environment.versionNumber;


  @ViewChild("sideBarList") sideBarList: ElementRef;
  providerAdresses: Object;
  providerAdressCalendarMap: { 1: number; };
  availability: any;
  timezonesListForAbbrivations = [];
  filterTimeZones = [];

  constructor(private auth: AuthService,
    private _appointment: AppointmentService,
    private route: ActivatedRoute,
    private _common: CommonService,
    public inboxCountService: InboxCountService,
    private _admin: AdminService,
    private _message: MessageService,
    private _loader: LoaderService,
    private _provider: ProvidersService) {
    this.getOpenTaskCount();
    this.currEpoch = moment().utc().unix() * 1000;
    this.currentEpochTimeOfSelectedTZ = this.currEpoch;
    this.providerId = this.auth.getSpecialist();

    // set updated timezone from session
    this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (sessionStorage.getItem('updatedTimezone') !== null) this.currentTimeZone = sessionStorage.getItem('updatedTimezone');
    if (this.currentTimeZone == "Asia/Calcutta") this.currentTimeZone = "Asia/Kolkata";
    if (this.currentTimeZone == "America/Arizona" || this.currentTimeZone == "US/Arizona") this.currentTimeZone = "America/Phoenix";
    this._admin.$getTimeZone.subscribe(
      res => {
        if (res) {
          this.currentTimeZone = res;
        }
      }
    )
    this.onTimeZoneChange();

  }

  ngOnInit() {
    let date = new Date();
    this.getListOfTimezones();
    this.subscriptions.push(this._appointment.openTaskCount$.subscribe(
      res => {
        if (res) {
          this.getOpenTaskCount();
        }
      }
    ));
    this.inboxCountService.startPingingCount();
    this.providerId = this.auth.getSpecialist();
    this.groupId = this.auth.getGroupId();

    this._admin.$getPlan.subscribe(res => {
      this.planName = res;
      if (!this.planName)
        this.planName = this.auth.getPlan();
    })
    this.getWeekAvailabilty(this.providerId);
  }

  closeNav() {
    this.showSideBar = !this.showSideBar;

    this._appointment.sideBarClickedSubject.next('')
  }
  closeNav1() {
    this.showSideBar = false;
  }
  logOut() {
    this.auth.logout();
  }

  getOpenTaskCount() {
    let searchModal: any = {
      providerId: this.auth.getSpecialist(),
      type: '',
      patientName: '',
      includeClosedTasks: false
    }

    this._appointment.getOpenTaskCount(this.auth.getSpecialist()).subscribe(
      (res) => {
        if (res >= 0) {
          this.openTaskCount = res;
          localStorage.setItem('isOpenTaskCountUpdate', 'false')
        }
      }
    )
  }

  getListOfTimezones() {
    this._common.getTimezonesDetails().subscribe((res: any) => {
      this.timzoneList = res;
      this.timzoneList[this.timzoneList.indexOf("US/Arizona")] = "America/Phoenix";
      this.filterTimeZones.push(this.timzoneList);
      this.filterTimeZones.forEach(x => x.map((zones) => {
        let object ={
          timeZoneName:zones.zoneName,
          abbreviation:zones.abbreviation
        }
        this.timezonesListForAbbrivations.push(object);
      }));
    })
  }

  onTimeZoneChange() {
    this.getTheCurrentTimzone();
    this.getCurrentTimeZoneAbbrivationName(this.currentTimeZone);
  }

  getTheCurrentTimzone() {
    let usaTime = new Date().toLocaleString("en-US", { timeZone: this.currentTimeZone });


    this._appointment.timezoneChangeSubscription.next(this.currentTimeZone);
    this._appointment.timeZoneChangeAbbreviation.next(this.getCurrentTimeZoneAbbrivationName(this.currentTimeZone));
    this.currentEpochTimeOfSelectedTZ = moment(new Date(usaTime).getTime()).utc().unix() * 1000;
    this._appointment.updatedEpochSubscription.next(this.currentEpochTimeOfSelectedTZ);

    sessionStorage.setItem('updatedTimezone', this.currentTimeZone);
  }

  getCurrentTimeZoneAbbrivationName(currentTimeZoneName:string){
   let zone =  this.timezonesListForAbbrivations.find((zone) =>  zone.timeZoneName == currentTimeZoneName);
   return zone ? zone.abbreviation : ""; 
  }

  setUTCintoMap() {
    this.offsetMap.set("US/Arizona", "UTC-7")
    this.offsetMap.set("America/New_York", "UTC-4")
    this.offsetMap.set("America/Chicago", "UTC-5")
    this.offsetMap.set("America/Denver", "UTC-6")
    this.offsetMap.set("America/Los_Angeles", "UTC-7")
    this.offsetMap.set("Asia/Kolkata", "UTC+5:30")
    this.offsetMap.set("America/Anchorage", "UTC-8")
    this.offsetMap.set("America/Adak", "UTC-9")
    this.offsetMap.set("Pacific/Honolulu", "UTC-10")
  }

  getWeekAvailabilty(providerId) {
    this._loader.showLoader(true);
    this._provider.getAvailabilityByProviderId(providerId).subscribe(data => {
      this.availability = data;
      if (Object.keys(this.availability).length === 0) {
        this._admin.setTimeZone.next(this.currentTimeZone);
      } else {
        this._admin.setTimeZone.next(this.availability[1].timeZone);
      }
      this.onTimeZoneChange();
      this._loader.showLoader(false);
    });
  }

  fetchNewTimeZone() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.inboxCountService.stopPinging();
  }

}
