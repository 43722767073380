<router-outlet>
    <div class="nod-logo" [hidden]="!loading"><img src="../assets/images/nod-logo.png">
        <div class="nod-loading">Loading...</div>
    </div>
    <div class="loading" [hidden]="!loader.isLoading()">Loading&#8230;</div>
    <div class="loading" [hidden]="!loader.isLoadingForAppointments()">
        Loading&#8230;
    </div>

    <iframe id="iFrame" class="full-screen" style="display:none" frameborder='0' scrolling='no' frameborder="0"
        allowfullscreen></iframe>
    <!-- </div> -->
</router-outlet>

<button hidden #openResetPassowrd data-target="#resetPassword" data-toggle="modal"></button>
<app-reset-password></app-reset-password>

<ng-container>
    <app-audio-call #audioCall></app-audio-call>
</ng-container>

<form #paymentForm id="paymentForm">
</form>