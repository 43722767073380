import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'vitalAcronym',
})
export class VitalAcronymPipe implements PipeTransform {

  vitalMap = new Map()
    .set("bloodPressure", "BP")
    .set("temperature", "Temp")
    .set("heartrate", "Pulse")
    .set("weight", "Weight")
    .set("respirations", "Resp")
    .set("bloodSugar", "BS")
    .set("oxygenSaturation", "O2")
    .set("painLevel", "Pain")
    .set("height", "Height");

  vitalMapOrder = new Map()
    .set("bloodPressure", "0")
    .set("temperature", "1")
    .set("heartrate", "2")
    .set("weight", "3")
    .set("respirations", "4")
    .set("bloodSugar", "5")
    .set("oxygenSaturation", "6")
    .set("painLevel", "7")
    .set("height", "8");

  transform(items: any): any {
    if (Array.isArray(items)) {
      {
        var that = this;
        items.filter(e => {
          e.sortOrder = that.vitalMapOrder.get(e.type);
          e.type = that.vitalMap.get(e.type);
          if (!e.value && e.value != 0) e.value = "--";
          if (e.type == "BP") e.value = e.systolicValue + '/' + e.diastolicValue;
        });
      }
      return items;
    }
  }
}