import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../Authentication/auth.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import * as Rx from "rxjs";
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Timezones } from 'src/app/components/shared/models/timezone';
import { ReferralEmail } from 'src/app/components/shared/models/referralEmail';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl: string = environment.baseUrl;
  groupId: any;
  constructor(private _http: HttpClient, private router: Router, private _auth: AuthService
  ) {
    var that = this;
    that.groupId = that._auth.getGroupId();

  }

  /**********************************************************************************************************
   *       GET APIs
   **************************************************************************************************************/

  //Get Master List Data
  getLanguge() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/` + 'languages');
  }
  getTimezones() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/time-zones`);
  }
  getCountry () {
    return this._http.get(`${this.baseUrl}common-service/api/v1/` + 'states');
  }
  public getStateList(): Observable<any> {
    return this._http.get(`${this.baseUrl}common-service/api/v1/` + 'states');
  }
  getRace() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/` + 'race');
  }
  //Labs old
  getLabs(query: string) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/search/labs?name=${query}`);
  }

  getRadiologyForPatient(query: string) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/search/radiologies?name=${query}`);
  }

  getAlergies(query: string) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/search/allergies?name=${query}`);
  }

  getPrescriptions(query: string) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/search/prescriptions?name=${query}`);
  }
  getPharmacies(query: string) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/search/pharmacies?name=${query}`);
  }

  getFacilityListForOrderType(data) {
    if (data.patientAthenaId == undefined || data.patientAthenaId == null) data.patientAthenaId = '';
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${data.groupId}/facilities?name=${data.name}&ordertype=${data.orderType}&athenaPatientId=${data.patientAthenaId}&patientId=${data.patientId}`);
  }


  //Labs new
  getLabsList(name, groupId) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${groupId}/order/lab?searchvalue=${name}`);
  }
  getRadiology(query: String, groupId) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${groupId}/order/imaging?searchvalue=${query}`);
  }
  getMedications(name, groupId) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${groupId}/order/prescription?searchvalue=${name}`);
  }

  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }

  debounceTimeObservable(nativeElement, value, delay: number): Rx.Observable<any> {
    return Rx.fromEvent(nativeElement, 'input')
      .pipe(
        map(() => {
          return value;
        }),
        debounceTime(delay),
        distinctUntilChanged()
      )
  }

  getDosesUnits(name) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/dosagequantityunit/search?name=${name}`)
  }

  getFrequencies() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/frequencies`)
  }

  getTotalQuantityList(name) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/totalquantityunit/search?name=${name}`)
  }
  getAllergiesFromAthena(data) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${data.groupId}/allergies?searchvalue=${data.name}`)
  }

  getEthnicity() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/ethnicities`);
  }

  getInsurancePackages(data) {
    return this._http.get<any[]>(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${data.groupId}/insurance/insurancepackages?insurancePlanName=${data.insurancePlanName}&memberid=${data.memberid}`)
  }


  getUsersSetting(userId) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/notification/setting/${userId}`)
  }

  updateUsersSetting(data) {
    return this._http.put(`${this.baseUrl}user-service/api/v1/notification/setting`, data)
  }
  resetUsersSetting(userId) {
    return this._http.put(`${this.baseUrl}user-service/api/v1/notification/reset/${userId}`, null)
  }

  getUserConsetInfo(userId) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/user/${userId}/consent`)
  }

  postUserConsetInfo(userId) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/user/${userId}/consent?textNotificationIsAgreed=true`, null)
  }

  getCapabilityTokenAndSetupDevice() {
    return this._http.get(`${this.baseUrl}communicator-service/api/v1/audio/capability-token`)
  }

  getCancelSubscriptionReasons() {
    return this._http.get(
      `${this.baseUrl}common-service/api/v1/cancel-subscription-reasons`);
  }

  saveCancelSubscriptionInfo(data) {
    return this._http.post(`${this.baseUrl}common-service/api/v1/cancel-subscription`, data);
  }

  cancelSubscriptionPusher(groupId) {
    return this._http.post(`${this.baseUrl}communicator-service/api/v1/pusher/provider/${groupId}/cancel-subscription/trigger`, null);
  }

  getAgreement(param) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/auth/agreements?veil=${param.veil}&name=${param.name}`);
  }

  getProviderGroupFacilityBillingPlan(id: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/provider-group-facility-billing-plan?providerGroupId=${id}`);
  }

  getBillingPlanById(id: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/${id}`);
  }

  downloadBusinessAgreement(groupId) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/business-agreement/download?groupId=${groupId}`, { responseType: "blob" });
  }

  getFeaturePlanById(id: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/feature-plans/${id}`);
  }

  storeBusinessAgreement(data) {
    return this._http.post(`${this.baseUrl}common-service/api/v1/auth/store-business-agreement`, data);
  }

  getSubSpecialties(specialityIds) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/sub-specialties?specialityIds=${specialityIds}`)
  }

  getAgeGroupSeen() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/age-group-seen`);
  }

  getFindASpecialties() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/find-a-specialist`);
  }
  getProviderEmployment() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider-employment`);
  }
  getLanguages() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/auth/languages`)
  }
  getTimezonesDetails() {
    return this._http.get<Timezones[]>(`${this.baseUrl}common-service/api/v2/time-zones`);
  }
  getYearsOfExp() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/years-of-experience`);
  }
  getAgesGroups() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/ages-accepted`);
  }
  getEMRList() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/practice/emr`)
  }
  getReferralEmailList(groupId) {
    return this._http.get<ReferralEmail[]>(`${this.baseUrl}provider-service/api/v1/auth/providers/email/${groupId}`)
  }
  storeSaasAgreement(data) {
    return this._http.post(`${this.baseUrl}common-service/api/v1/auth/store-saas-agreement`, data);
  }
  getBillingPlanByProviderIdAndGroupId(providerId: any, groupId: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/billing-plan/provider/${providerId}`).pipe(
      map((resp: any[]) => {
        if (!resp.length) {
          return [{
            billingPlanDto: {
              amount: 0,
              billingCycle: null,
              billingStatus: 'FREE',
              billingType: 'Free',
              id: 6
            },
            isCurrent: true,
            isFreeTrial: true,
            isFreeTrialAllowed: true
          }];
        }
        return resp;
      })
    );
  }

  sendFeedback(data) {
    return this._http.post(`${this.baseUrl}common-service/api/v1/submit/feedback`, data);
  }

  getDepartmentById(departmentId: any) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/ehr/department/${departmentId}`)
  }

  getAddressByaddressId(addressId){
    return this._http.get(`${this.baseUrl}common-service/api/v1//address/${addressId}`);
  }

  // getUsersSettingByPatientId(patientId){
  //   return this._http.get(`${this.baseUrl}user-service/api/v1/notification/setting/patientId/${patientId}`)
  // }

}
