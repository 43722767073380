import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// import SweetAlert from 'sweetalert/dist/sweetalert.min.js';

@Injectable({
  providedIn: 'root'
})
export class AlertService {




  message: string = '';
  actionButtonLabel: string = 'Retry';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 7000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  successhorizontalPosition: MatSnackBarHorizontalPosition = 'end';
  successverticalPosition: MatSnackBarVerticalPosition = 'bottom';


  addExtraClass: boolean = false;


  constructor(public snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService) { }

  errorAlert(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? 5000 : 0;
    config.panelClass = ['error-alert'];
    let snackBarRef = this.snackBar.open(data.text ? data.text : data, "okay", config);
  }


  successAlert(message: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open(message.text ? message.text : message, this.action ? 'Okay' : undefined, config);
  }

  successReferral(message: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    const snackBarRef = this.snackBar.open(message, 'Go To Referrals', config);
    snackBarRef.onAction().subscribe( () => {
      this.router.navigate(['/referral/new']);
    });
  }

  rejectedReferral(message: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    this.snackBar.open(message, 'Close', config);
  }

  successAppointment(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open(data.message, '', config);
  }

  successPatient(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open(this.translate.instant('notification.success.patient.create'), this.action ? 'Detail' : undefined, config);
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(['/', 'patients', btoa(data.id), 'profile'])
    });
  }

  supportAlert() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = 3000;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open('thank you', '', config);
  }

  succussSpecialist() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open('Provider Created Successfully', '', config);
  }

  editPatientAlert() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open(this.translate.instant('notification.success.patient.update'), 'Okay', config);
  }

  editPatientErrorAlert() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['error-alert'];
    let snackBarRef = this.snackBar.open(this.translate.instant('notification.success.patient.error'), '', config);
  }

  checkEligibilityError(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = false ? this.autoHide : 0;
    config.panelClass = ['success-alert'];
    let snackBarRef = this.snackBar.open(data.text, "okay", config);
  }

  /**
  * Show alert on successfully patient created
  * @param data
  */
  duplicatePatient(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['error-alert'];
    let snackBarRef = this.snackBar.open(data.message, this.action ? 'View Patient' : undefined, config);
    snackBarRef.onAction().subscribe(() => {


      this.router.navigate(['patients', btoa(data.id), 'profile'])
    });
  }

  supportEmailAlert(data) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? 3000 : 0;
    config.panelClass = ['alert-warning'];
    this.snackBar.open(data.text ? data.text : data, "okay", config);
  }


  successAppointmentAlert(message: any, autoHide: boolean) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = autoHide ? 60000 : 0;
    config.panelClass = ['success-alert'];
    this.snackBar.open(message.text ? message.text : message, this.action ? 'Okay' : undefined, config);
  }
  processAlert(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? 1500 : 0;
    config.panelClass = ['success-alert'];
    this.snackBar.open(data.text ? data.text : data, this.action ? 'Okay' : undefined, config);
  }


  errorAlertBottomRight(data: any) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.successverticalPosition;
    config.horizontalPosition = this.successhorizontalPosition;
    config.duration = this.setAutoHide ? 4000 : 0;
    config.panelClass = ['error-alert'];
    let snackBarRef = this.snackBar.open(data.text ? data.text : data, "okay", config);
  }
}
