import { Pipe, PipeTransform } from '@angular/core';
import {map} from 'rxjs/operators';
import {ProvidersService} from '../../../core/api-services/providers.service';


@Pipe({
  name: 'timezoneName'
})
export class TimezoneNamePipe implements PipeTransform {
  constructor(private providerService: ProvidersService) {
  }

  public transform(value: string) {
    return this.providerService.getTimezonesDetails().pipe(
      map(timezones => {
        const timezone = timezones.find(t => t.zoneName === value);
        return timezone ? timezone.area : value;
      })
    );
  }
}
