import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<any>([]);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  setUser(roles: any[]) {
    var permissions: any = [];
    for (const role of roles) {
      if (role && role.permissions) {
        for (const permission of role.permissions)
          if (!permissions.includes(permission.name)) permissions.push(permission.name);
      }

    }
    this.currentUserSubject.next(permissions);
  }

}