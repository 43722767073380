import { I } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/api-services/common.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
import { LoaderService } from 'src/app/core/services/loader.service';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @ViewChild('close') closebutton;
  isInvalidEaseOfUse: boolean = false;
  isInvalidReliability: boolean = false;
  isInvalidValue: boolean = false;
  submitted: boolean = false;
  userId: any;
  isSubmitEnabled: boolean = true;
  feedbackForm: FormGroup;
  rateOfExperience: any = ['Beginner', 'Intermediate', 'Advanced'];
  useOfPlatform: any = ['Daily', 'Weekly', 'Monthly'];
  w = window.innerWidth;
  h = window.innerHeight;

  constructor(
    private formBuilder: FormBuilder,
    private _common: CommonService,
    private _loader: LoaderService,
    private _alert: AlertService) {

  }

  ngOnInit() {
    this.userId = atob(sessionStorage.getItem('userId'));
    this.validateForm();

  }

  get f() { return this.feedbackForm.controls; }

  validateForm() {
    this.feedbackForm = this.formBuilder.group({
      userId: 0,
      rating: ['', [Validators.required, Validators.pattern]],
      platformUse: ['', [Validators.required]],
      easeOfUse: [null, [Validators.pattern(/^(?:[1-9]|0[1-9]|10)$/)]],
      reliability: [null, [Validators.pattern(/^(?:[1-9]|0[1-9]|10)$/)]],
      value: [null, [Validators.pattern(/^(?:[1-9]|0[1-9]|10)$/)]],
      suggestions: ['']
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    } else {
      this._loader.showLoader(true);
      let data = this.feedbackForm.value;
      data.userId = this.userId;
      if (!data.value) data.value = 0;
      if (!data.reliability) data.reliability = 0;
      if (!data.easeOfUse) data.easeOfUse = 0;

      this._common.sendFeedback(data).subscribe((res: any) => {
        if (res) {
          this._loader.showLoader(false);
          this._alert.successAlert("Thank you for giving us a 'nod' with feedback.");
          this.closebutton.nativeElement.click();
          this.feedbackForm.reset();
          this.feedbackForm.controls['platformUse'].setValue('');
          this.feedbackForm.controls['rating'].setValue('');
          this.submitted = false;
        }
      }, (error: any) => {
        this._loader.showLoader(false);
        this._alert.errorAlert(error);
      });

    }
  }

}
