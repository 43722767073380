export enum Permissions {
  CREATE_EDIT_CANCEL_VIEW_APPOINTMENT = "CREATE_EDIT_CANCEL_VIEW_APPOINTMENT",
  VIEW_APPOINTMENT = "VIEW_APPOINTMENT",
  VIEW_ALL_APPOINTMENT = "VIEW_ALL_APPOINTMENT",
  CREATE_EDIT_VIEW_PATIENT = "CREATE_EDIT_VIEW_PATIENT",
  VIEW_PATIENT_LABS = "VIEW_PATIENT_LABS",
  VIEW_PATIENT_RADIOLOGIES = "VIEW_PATIENT_RADIOLOGIES",
  VIEW_PATIENT_PRESCRIPTIONS = "VIEW_PATIENT_PRESCRIPTIONS",
  VIEW_PATIENT = "VIEW_PATIENT",
  JOIN_CALL = "JOIN_CALL",
  VIEW_APPOINTMENT_WITH_NOTES = "VIEW_APPOINTMENT_WITH_NOTES",
  VIEW_PATIENT_LABS_RADIOLOGY_PRESCRIPTIONS = "VIEW_PATIENT_LABS_RADIOLOGY_PRESCRIPTIONS",
  VIEW_PATIENT_NOTIFICATIONS = "VIEW_PATIENT_NOTIFICATIONS",
  CREATE_EDIT_VIEW_MESSAGE_SETTINGS = "CREATE_EDIT_VIEW_MESSAGE_SETTINGS",
  VIEW_MESSAGE_SETTINGS = "VIEW_MESSAGE_SETTINGS",
  CREATE_EDIT_VIEW_DELETE_USER = "CREATE_EDIT_VIEW_DELETE_USER",
  VIEW_USER = "VIEW_USER",
  CREATE_EDIT_VIEW_AVAILABILITY = "CREATE_EDIT_VIEW_AVAILABILITY",
  VIEW_AVAILABILITY = "VIEW_AVAILABILITY",
  HAVE_AVAILABILITY = "HAVE_AVAILABILITY",
  CREATE_EDIT_VIEW_EMAIL_SETTINGS = "CREATE_EDIT_VIEW_EMAIL_SETTINGS",
  VIEW_EMAIL_SETTINGS = "VIEW_EMAIL_SETTINGS",
  CREATE_EDIT_VIEW_PAYMENT_SETTINGS = "CREATE_EDIT_VIEW_PAYMENT_SETTINGS",
  VIEW_PAYMENT_SETTINGS = "VIEW_PAYMENT_SETTINGS",
  VIEW_WIDGET_URL = "VIEW_WIDGET_URL",
  CREATE_EDIT_VIEW_EHR_SETTING = "CREATE_EDIT_VIEW_EHR_SETTING",
  VIEW_EHR_SETTING = "VIEW_EHR_SETTING",
  VIEW_SPECIALIST_EHR = "VIEW_SPECIALIST_EHR",
  CREATE_EDIT_VIEW_SPECIALIST_BILLING_SETTINGS = "CREATE_EDIT_VIEW_SPECIALIST_BILLING_SETTINGS",
  VIEW_SPECIALIST_BILLING_SETTINGS = "VIEW_SPECIALIST_BILLING_SETTINGS",
  CREATE_EDIT_VIEW_BILLING_SETTINGS = "CREATE_EDIT_VIEW_BILLING_REPORT_SETTINGS",
  VIEW_MESSAGES = "VIEW_MESSAGES",
  VIEW_EMAIL = "VIEW_EMAIL",
  VIEW_REPORTS = "VIEW_REPORTS",
  VIEW_OPEN_TASK = "VIEW_OPEN_TASK",
  CREATE_EDIT_VIEW_OPEN_TASK = "CREATE_EDIT_VIEW_OPEN_TASK",
  EDIT_OWN_PROFILE = 'EDIT_OWN_PROFILE',
  EDIT_USER_ROLE = 'EDIT_USER_ROLE',
  VIEW_NOTIFICATION_SETTING = "VIEW_NOTIFICATION_SETTING",
  EDIT_NOTIFICATION_SETTING = "EDIT_NOTIFICATION_SETTING",
  CHARGE_APPOINTMENT = "CHARGE_APPOINTMENT",
  DOES_INPATIENT_ROUNDING = "DOES_INPATIENT_ROUNDING",
  SEND_EMAIL_VERIFICATION = "SEND_EMAIL_VERIFICATION",
  ACCOUNT_SETTING = "VIEW_UPDATE_ACCOUNTS",
  VIEW_PRACTICE = "VIEW_PRACTICE",
  VIEW_UPDATE_SPECIALIST_GROUP = "VIEW_UPDATE_SPECIALIST_GROUP",
}
