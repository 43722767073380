
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'stateName',
})
export class StateNamePipe implements PipeTransform {

    transform(items: any, filter: any): any {

        if (!Array.isArray(items)) {
            if (items) {
                {
                    let name = items;
                    let count = name.indexOf('-') + 1;
                    return name.slice(count, name.length);
                }
            }
        }

        if (Array.isArray(items)) {
            items.filter(
                obj => {
                    let name: string = obj.name;
                    let count = name.indexOf('-') + 1;
                    obj.name = name.slice(count, name.length);
                }
            );
            return items;
        }
    }
}