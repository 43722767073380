import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-stop-encounter-dialog',
  templateUrl: './stop-encounter-dialog.component.html',
  styleUrls: ['./stop-encounter-dialog.component.css']
})
export class StopEncounterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<StopEncounterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
