import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-patient-dialog',
  templateUrl: './patient-eligibility-dialog.component.html',
  styleUrls: ['./patient-eligibility-dialog.component.css']
})
export class PatientDialogComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any) {
    dialogRef.disableClose = false;
  }

}
