import { Directive, Input, ElementRef } from '@angular/core';


@Directive({
    selector: '[onlyNumbers]'
})
export class OnlyNumberDirectives {

    constructor(public el: ElementRef) {

        this.el.nativeElement.onkeypress = (evt) => {
            if (evt.which != 46) {
                if (evt.which < 48 || evt.which > 57) {
                    evt.preventDefault();
                }
            }
        };
    }
}
