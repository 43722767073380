import { CheckPlanGuard } from './core/Authentication/check-plan.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { Role } from './config/roles';
import { VerificationComponent } from './modules/verification/verification.component';
import { NotAuthorizedComponent } from './components/shared/common/not-authorized/not-authorized.component';
import { HomeComponent } from './core/layout/home/home.component';
import { LoginGuard } from './core/Authentication/login.guard';
import { MobileTestingComponent } from './modules/mobile-testing/mobile-testing.component';
import { environment } from 'src/environments/environment';
import { EmailSentPopupComponent } from './modules/email-sent-popup/email-sent-popup.component';
import { RedirectComponent } from './redirect/redirect.component'; 


const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'appointments', pathMatch: 'full' },
      { path: 'alert', loadChildren: './modules/alert/alert.module#AlertModule' },
      { path: 'patients', canActivate: [CheckPlanGuard], loadChildren: './modules/patients/patient.module#PatientModule' },
      {
        path: 'appointments', canActivate: [CheckPlanGuard], loadChildren: './modules/appointment/appointment.module#AppointmentModule'
      },
      { path: 'opentask', canActivate: [CheckPlanGuard], loadChildren: './modules/open-task/opentask.module#OpenTaskModule' },
      { path: 'settings', loadChildren: './modules/settings/settings.module#SettingsModule' },
      {
        path: 'pathways',
        loadChildren: './modules/clinical-pathways/pathways.module#PathwaysModule',
      },
      {
        path: 'reports',
        canActivate: [CheckPlanGuard],
        loadChildren: './modules/reports/reports.module#ReportsModule',
      },
      {
        path: 'smart-consult',
        loadChildren: './modules/fast-consult/fast-consult.module#FastConsultModule'
      },
      {
        path: 'notauthorized',
        component: NotAuthorizedComponent,
        data: { title: 'nodMD - Specialist | 401' }
      },
      {
        path: 'facilities',
        canActivate: [CheckPlanGuard],
        loadChildren: './modules/facility/facility/facility.module#FacilityModule'
      },
      {
        path: 'inbox',
        loadChildren: './modules/inbox/inbox.module#InboxModule'
      },
      {
        path: 'endorsements',
        canActivate: [],
        loadChildren: './modules/endorsements/endorsements.module#EndorsementsModule'
      },
      {
        path: 'referral',
        canActivate: [CheckPlanGuard],
        loadChildren: './modules/referral/referral.module#ReferralModule'
      },
      {
        path: environment.nodbookingRoot,
        loadChildren: './modules/find-a-doc/find-a-doc.module#FindADocModule'
      },
      {
        path: 'learn-more', loadChildren: './modules/learn-more/learn-more.module#LearnMoreModule'
      }
    ]
  },
  {
    path: 'video',
    loadChildren: './modules/twilio-video/twilio-video.module#TwilioVideoModule'
  },

  {
    path: '***',
    redirectTo: ''
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    data: { "title": "nodMD - Specialist | Login" },
    component: LoginComponent
  },
  {
    path: 'pcp-login',
    canActivate: [LoginGuard],
    data: { "title": "nodMD - Specialist | PCP-Login" },
    component: LoginComponent
  },

  {
    path: 'verification/link',
    component: VerificationComponent,
    data: { "title": "nodMD - Specialist | Verification" }
  },
  {
    path: 'forgot-password/verification/link',
    component: VerificationComponent,
    data: { "title": "nodMD - Specialist | Password Verification" }
  },
  {
    path: 'mobile-testing',
    component: MobileTestingComponent,
    data: { "title": "Mobile Testing" }
  },
  {
    path:'appointment/:appointmentId/send-appointment-confirmation',
    component:EmailSentPopupComponent,
    data: { "title": "nodMD - Specialist | Email Verification" }
  },
  {
    path: 'short/:shortUrl',
    component: RedirectComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
