import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../Authentication/auth.service';
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  pccExpireSessionSubject = new BehaviorSubject(null);
  $pccExpireSessionSubject = this.pccExpireSessionSubject.asObservable();

  baseUrl: string = environment.baseUrl;
  timeZone: string;
  constructor(private _http: HttpClient, private _auth: AuthService) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.$pccExpireSessionSubject.subscribe((res: any) => {
      if (res) this.checkPccConnection(res).subscribe();
    })
  }

  resetPasswordSubject = new BehaviorSubject(null);
  $resetPasswordSubject = this.resetPasswordSubject.asObservable();

  setGroupDetailsSubject = new BehaviorSubject(null);
  $getGroupDetailsSubject = this.setGroupDetailsSubject.asObservable();

  groupBillingPlanSubject = new BehaviorSubject(null);
  $groupBillingPlanSubject = this.groupBillingPlanSubject.asObservable();

  setProviderUpdateData = new BehaviorSubject(null);
  $setProviderUpdateData = this.setProviderUpdateData.asObservable();

  setPlan = new BehaviorSubject(null);
  $getPlan = this.setPlan.asObservable();

  setTimeZone = new BehaviorSubject(null);
  $getTimeZone = this.setTimeZone.asObservable();

  setDataForLearnMoreProgram = new BehaviorSubject(null);
  $getDataForLearnMoreProgram = this.setDataForLearnMoreProgram.asObservable();

  setIsSubscriptionActive = new BehaviorSubject(null);
  $getIsSubscriptionActive = this.setIsSubscriptionActive.asObservable();

  setIsBonusTrialAccepted = new BehaviorSubject(null);
  $getIsBonusTrialAccepted = this.setIsBonusTrialAccepted.asObservable();
  trialAllowed = new BehaviorSubject(null);

  setGroupProviderLength = new BehaviorSubject(null);
  $getGroupProviderLength = this.setGroupProviderLength.asObservable();


  /*######################################################################################################################
                                             Get API Calls
  ######################################################################################################################*/
  verifyLink(data) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/auth/verification/link?token=${data}&dashboard=specialist`);
  }
  verifyUsername(username) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/auth/username-check?userName=${username}`)
  }
  getEmailVerificationDetail(email) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/auth/check/account/verification?email=${email}&dashboard=specialist`)
  }

  getSpecialistRoles() {
    return this._http.get(`${this.baseUrl}admin-service/api/v1/roles?type=specialist`);
  }
  getAllRoleTypes() {
    return this._http.get(`${this.baseUrl}admin-service/api/v1/role-types`);
  }

  getAllRoles() {
    return this._http.get(`${this.baseUrl}user-service/api/v1/roles`);
  }


  getAllPrivilegesByRoleTypeId(id: number) {
    return this._http.get(`${this.baseUrl}user-service/api/v1/privileges-type?role_type_id=${id}`);
  }

  /**
   *
   * @param name
   */
  getRolesByType(name) {
    return this._http.get(`${this.baseUrl}admin-service/api/v1/roles?type=${name}`);
  }


  getAgesGroups() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/ages-accepted`);
  }
  getState() {
    return this._http.get(`${this.baseUrl}common-service/api/v1/states`);
  }

  getStateLicensed() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/states-licensed`);
  }
  getProviderClassiification() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-classifications`);
  }
  getFacilitiesOfGroup(id: any) {
    return this._http.get(`${this.baseUrl}facility-service/api/v1/facility/provider-group/${id}`);
  }
  getRace() {
    return this._http.get(`${this.baseUrl}patient-service/api/` + 'race');
  }

  getProviderGroupList(): any {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/` + 'provider-groups');
  }
  getProviderList(id, specialityId: any = '') {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/providerGroup/${id}/search?specialityId=${specialityId}`);
  }
  getProviderGroupDetails(id) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${id}`);
  }
  getPracticeDetail(id: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/no-login-practice/${id}`);
  }
  getProviderDetail(id: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/${id}`);
  }

  getAllUsers(size: any) {
    return this._http.get(`${this.baseUrl}admin-service/api/v1/users?size=${size}`);
  }



  /*######################################################################################################################
                                                     Search API Calls
  ######################################################################################################################*/

  searchSpeciality() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/specialities`);
  }
  searchInsurance(query: string) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/insurances-accepted?name=${query}`);
  }

  searchProviderGroup(query: string) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-groups?name=${query}`);
  }

  searchFacility(query: string) {
    return this._http.get(`${this.baseUrl}facility-service/api/v1/facilities?name=${query}`);
  }

  searchAlergies(query: string) {
    return this._http.get(`${this.baseUrl}patient-service/api/search/allergies?q=${query}`);
  }
  searchPrescriptions(query: string) {
    return this._http.get(`${this.baseUrl}patient-service/api/search/prescriptions?q=${query}`);
  }
  searchPharmacies(query: string) {
    return this._http.get(`${this.baseUrl}patient-service/api/search/pharmacies?q=${query}`);
  }

  searchRadiology(query: string) {
    return this._http.get(`${this.baseUrl}patient-service/api/search/radiologies?q=${query}`);

  }

  searchPatientByNameOrDob(data) {
    return this._http.get(`${this.baseUrl}/admin-service/api/v1/patients/search?name=${data.name}&dob=${data.dob}`)
  }


  filterFacilityList(data) {
    return this._http.get(`${this.baseUrl}facility-service/api/v1/facilities?stateId=${data.stateId}&providerGroupId=${data.groupId}&name=${data.name}`);
  }
  getPermissionsWithModuleByRoleId(id: number): any {
    return this._http.get(`${this.baseUrl}user-service/api/v1/role/role-permissions-module/${id}`);
  }

  /*######################################################################################################################
                                                    PUT/PATCH API call
  ######################################################################################################################*/

  updateProviderGroup(model: any) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-group`, model);
  }

  updatePassword(model: any) {
    return this._http.put(`${this.baseUrl}admin-service/api/v1/user/password`, model);
  }

  updateSpecialist(data: any) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider`, data);
  }



  updateProviderGroupStatus(data: any) {
    return this._http.patch(`${this.baseUrl}provider-service/api/v1/provider-group/toggle`, data);
  }

  updateUsersPassword(data) {
    return this._http.put(`${this.baseUrl}user-service/api/v1/user/password?isPwdUpdatedFromAdmin=false `, data);
  }

  updateFacilityStatus(data: any) {
    return this._http.patch(`${this.baseUrl}facility-service/api/v1/facility/toggle`, data);
  }

  updateUserStatus(data: any) {
    return this._http.put(`${this.baseUrl}facility-service/api/v1/facility-staff/toggle`, data)
  }

  editRole(data: any) {
    return this._http.put(`${this.baseUrl}user-service/api/v1/role`, data);
  }




  /*######################################################################################################################
                                                    POST API call
  ######################################################################################################################*/

  login(model) {
    return this._http.post(`${this.baseUrl}auth`, model, { observe: 'response' }).toPromise();
  }
  verifyLinkForgotPassword(data) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/auth/forgot-password/verification/link?token=${data}`, null);
  }
  createPassword(data) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/auth/set-password`, data)
  }
  createNewPassword(data) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/auth/new-password`, data)
  }
  addProviderGroup(model) {
    return this._http.post(`${this.baseUrl}admin-service/api/v1/provider-group`, model);

  }

  addSpecialist(data: any) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/provider`, data);
  }

  addNewRole(data: any) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/role`, data);
  }
  addNewUser(data: any) {
    return this._http.post(`${this.baseUrl}admin-service/api/v1/user`, data);
  }

  saveEHRDateSettingsForGroup(data) {
    return this._http.post(`${this.baseUrl}ehr-integration-service/api/v1/ehr-setting`, data);
  }
  gethaveAvailabilityStatusByRole(formData: any[]) {
    return this._http.post(`${this.baseUrl}user-service/api/v1/role/have-availability-permission/roles`, formData);
  }

  getDepartmentList(id) {
    return this._http.get<any[]>(`${this.baseUrl}ehr-integration-service/api/v1/athena/provider-group/${id}/departments`);
  }
  getEHRSettingsForProviderGroup(id) {
    return this._http.get<any[]>(`${this.baseUrl}ehr-integration-service/api/v1/ehr-setting/provider-group/${id}`);
  }

  getAllEHRProviders() {
    return this._http.get<any[]>(`${this.baseUrl}ehr-integration-service/api/v1/ehr-service-providers`)
  }

  getRegX() {
    return this._http.get(`${this.baseUrl}user-service/api/v1/auth/password/validation-regex`)
  }

  /**
  * Verify recaptcha
  * @param token
  * @returns
  */
  verifyRecaptcha(token: string) {
    return this._http.get(`${this.baseUrl}auth-service/api/v1/auth/re-captcha/validate?version=v3&token=${token}`);
  }

  sendAccessTokenToBE(data) {
    return this._http.post(`${this.baseUrl}ehr-integration-service/api/v1/access_token?code=${data.code}&providerId=${data.providerId}&facilityId=${data.facilityId}`, null)
  }

  signOutToPCC(data) {
    return this._http.post(`${this.baseUrl}ehr-integration-service/api/v1/pcc/sign-out?providerId=${data.providerId}&facilityId=${data.facilityId}`, null)
  }

  checkPccConnection(providerId) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/pcc/connection/provider/${providerId}`)
  }

  getUsageReportData(data: any) {

    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/usage-report/total-counts`,
      {
        params: {
          timezone: this.timeZone,
          startDate: data.startDate,
          endDate: data.endDate,
          specialistGroupId: data.providerGroupId,



        }
      })
      .pipe(retry(1),
        catchError(this.handleError));
  }

  getQualityReportData(data: any) {

    //return this._http.get(`http://localhost:8090/api/v1/analytics/quality-report`,
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/quality-report`,
      {
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
          specialistGroupId: data.providerGroupId,
          page: data.page,
          pageSize: data.pageSize
        }
      })
      .pipe(retry(1),
        catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  exportCsv(data) {
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/usage-report/export-csv?userType=${data.userType}&facilityId=${data.facilityId}&specialistGroupId=${data.providerGroupId}&startDate=${data.startDate}&endDate=${data.endDate}&timezone=${this.timeZone}`, { responseType: "blob" });
  }

  exportQualityReportCsv(data) {
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/quality-report/export-csv?specialistGroupId=${data.providerGroupId}&startDate=${data.startDate}&endDate=${data.endDate}`, { responseType: "blob" });
  }


  getProviderReportData(data: any) {

    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/provider-report`,
      {
        params: {
          providerGroupId: data.providerGroupId,
          startDate: data.startDate,
          endDate: data.endDate,
          timezone: this.timeZone,


          page: data.page,
          pageSize: data.pageSize
        }
      })
      .pipe(retry(1),
        catchError(this.handleError));
  }

  exportProviderReportCsv(data) {
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/provider-report/export-csv?providerGroupId=${data.providerGroupId}&startDate=${data.startDate}&endDate=${data.endDate}&timezone=${this.timeZone}`, { responseType: "blob" });
  }

  getProviderPhotos(id: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-profile-photos/${id}`);
  }

  getProviderVideo(id) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-video/${id}`, { responseType: "text" });
  }

  getEncounterReportData(data: any) {

    //return this._http.get(`http://localhost:8090/api/v1/analytics/quality-report`,
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/encounter-report`,
      {
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
          specialistGroupId: data.providerGroupId,
          page: data.page,
          pageSize: data.pageSize
        }
      })
      .pipe(retry(1),
        catchError(this.handleError));
  }

  exportEncounterReportCsv(data) {
    return this._http.get(`${this.baseUrl}analytics-service/api/v1/analytics/encounter-report/export-csv?specialistGroupId=${data.providerGroupId}&startDate=${data.startDate}&endDate=${data.endDate}`, { responseType: "blob" });
  }

  sendMobileLoginInfo(userId: number): void {
    this._http.post(`${this.baseUrl}analytics-service/api/v1/history/mobile-logins`, { userId }).subscribe();
  }

  sendCopyBookingButtonInfo(userId: string, groupId: number): void {
    this._http.post(`${this.baseUrl}analytics-service/api/v1/history/booking-button-copy`, { userId, groupId }).subscribe();
  }
}
