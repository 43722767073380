import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { saveAs } from 'file-saver';

import {Content, TCreatedPdf, TDocumentDefinitions, TDocumentHeaderFooterFunction} from '../models/make-pdf';
import {ENCODED_CIRCULAR_STD, LOGO_BASE64, NO_BORDERS, NO_MARGINS, PDF_SETTINGS, SEPARATOR_COLOR, STYLES} from '../constants/pdf.constants';
import {LoaderService} from '../../../core/services/loader.service';
import {ReferralDetails} from '../models/referral.model';
import {AppointmentService} from '../../../core/api-services/appointment.service';
import {ReferralService} from './referral.service';
import {AdminInfo} from '../models/admin-info';

@Injectable()
export class ReferralPdfService {

  constructor(private loaderService: LoaderService,
              private referralService: ReferralService,
              private appointmentService: AppointmentService) {
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    };

    pdfMake.vfs = {
      'Roboto-Italic.ttf': ENCODED_CIRCULAR_STD,
      'Roboto-Medium.ttf': ENCODED_CIRCULAR_STD,
      'Roboto-MediumItalic.ttf': ENCODED_CIRCULAR_STD,
      'Roboto-Regular.ttf': ENCODED_CIRCULAR_STD
    };
  }

  public generateReferralPdf(data: ReferralDetails, appointmentData: any): void {
    this.generatePdf(data, appointmentData);
  }

  private generatePdf(data: ReferralDetails, appointmentData: any): void {
    this.loaderService.loading = true;
    const docDefinition = this.generateDocDefinitions(data, appointmentData);
    const pdfDocGenerator: TCreatedPdf = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob(pdfBlob => {
      saveAs(pdfBlob, `referral.pdf`);
      this.loaderService.loading = false;
    });
  }

  private generateDocDefinitions(data: ReferralDetails, appointmentData: any): TDocumentDefinitions {
    return {
      pageMargins: [
        PDF_SETTINGS.CONTENT_MARGIN_LEFT_RIGHT,
        PDF_SETTINGS.MARGIN_TOP_BOTTOM + 40,
        PDF_SETTINGS.CONTENT_MARGIN_LEFT_RIGHT,
        PDF_SETTINGS.MARGIN_TOP_BOTTOM
      ],
      header: this.generateHeaderDocDefinition(data.isReferral),
      footer: this.generateFooterDocDefinition(),
      content: this.generateContentDocDefinition(data, appointmentData),
      styles: STYLES
    };
  }

  private generateHeaderDocDefinition(isReferral: boolean): TDocumentHeaderFooterFunction {
    return (currentPage, pageCount) => {
      return [
        {
          margin: [
            PDF_SETTINGS.MARGIN_LEFT_RIGHT,
            12,
            PDF_SETTINGS.MARGIN_LEFT_RIGHT,
            0
          ],
          columns: [
            {
              text: `${currentPage} of ${pageCount}`,
              alignment: 'right'
            }
          ]
        },
        {
          margin: [
            PDF_SETTINGS.CONTENT_MARGIN_LEFT_RIGHT,
            8,
            PDF_SETTINGS.CONTENT_MARGIN_LEFT_RIGHT,
            0
          ],
          text: `${isReferral ? 'Referral' : 'Appointment'} Details`,
          alignment: 'center',
          bold: true,
          style: 'pageTitle'
        },
        {
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{
                borderColor: [SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR],
                text: '',
                border: [false, true, false, false]
              }]
            ]
          },
          margin: [
            PDF_SETTINGS.MARGIN_LEFT_RIGHT,
            10,
            PDF_SETTINGS.MARGIN_LEFT_RIGHT,
            10
          ],
        }
      ];
    };
  }

  private generateContentDocDefinition(data: ReferralDetails, appointmentData: any): Array<string | Content> {
    return [
      {
        text: 'Appointment Information',
        style: 'sectionTitle'
      },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          margin: NO_MARGINS,
          body: [
            [
              [{
                text: 'Referred to',
                style: 'label'
              }, {
                text: data.referredTo.name,
                style: 'value'
              }],
              [{
                text: 'Speciality',
                style: 'label'
              }, {
                text: data.specialty,
                style: 'value'
              }],
              [{
                text: 'Office phone number',
                style: 'label'
              }, {
                text: data.referredTo.officeNumber,
                style: 'value'
              }],
              [{
                text: 'Office address',
                style: 'label'
              }, {
                text: data.referredTo.address,
                style: 'value'
              }]
            ],

            [
              [{
                text: 'Appointment Date',
                style: 'label'
              }, {
                text: data.apptDate,
                style: 'value'
              }],
              [{
                text: 'Appointment Time',
                style: 'label'
              }, {
                text: data.apptTime +
                    (this.appointmentService.timezoneChangeSubscription.getValue() ?
                    (' (' + this.appointmentService.timezoneChangeSubscription.getValue() + ')') : ''),
                style: 'value'
              }],
              [{
                text: 'Visit Type',
                style: 'label'
              }, {
                text: appointmentData ? (appointmentData.calendarId === 1 ? 'Virtual' : 'In-person') : '-',
                style: 'value'
              }],
              [{
                text: 'Office fax number',
                style: 'label'
              }, {
                text: data.referredTo.officeFax,
                style: 'value'
              }]
            ],

            [
              [{
                text: 'Reason for Referral',
                style: 'label'
              }, {
                text: data.referralReason,
                style: 'value'
              }],
              [{
                text: 'Appointment Status',
                style: 'label'
              }, {
                text: this.transformToHumanReadable(data.appointmentStatus),
                style: 'value'
              }],
              [{
                text: 'Patient Pay Type',
                style: 'label'
              }, {
                text:  data.isPatientSelfPay ? 'Self-pay' : 'Insurance' ,
                style: 'value'
              }],
              []
            ],
          ]
        }
      },

      {
        table: {
          headerRows: 1,
          widths: ['100%'],
          body: [
            [{
              borderColor: [SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR],
              text: '',
              border: [false, true, false, false]
            }]
          ]
        },
        margin: [
          0,
          10,
          0,
          10
        ],
      },

      (data.isReferral ? [{
        text: 'Referral Information',
        style: 'sectionTitle'
      },

      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          margin: NO_MARGINS,
          body: [
            [
              [{
                text: 'Referred by',
                style: 'label'
              }, {
                text: data.referredBy && data.referredBy.name || '-',
                style: 'value'
              }],
              [{
                text: 'Office phone number',
                style: 'label'
              }, {
                text: data.referredBy.officeNumber,
                style: 'value'
              }],
              [{
                text: 'Office fax number',
                style: 'label'
              }, {
                text: data.referredBy.officeFax,
                style: 'value'
              }],
              [{
                text: 'Office address',
                style: 'label'
              }, {
                text: data.referredBy.address,
                style: 'value'
              }]
            ],

            [
              [{
                text: 'Date Referred',
                style: 'label'
              }, {
                text: data.dateReferred,
                style: 'value'
              }],
              [{
                text: 'Prior Authorization',
                style: 'label'
              }, {
                text: data.isPriorAuth,
                style: 'value'
              }],
              [{
                text: 'Referral Status',
                style: 'label'
              }, {
                text: this.transformToHumanReadable(data.status),
                style: 'value'
              }],
              [{
                text: 'ICD-10 Code/Diagnosis',
                style: 'label'
              }, {
                text: data.icd,
                style: 'value'
              }]
            ]
          ]
        }
      }, {
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{
                borderColor: [SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR],
                text: '',
                border: [false, true, false, false]
              }]
            ]
          },
          margin: [
            0,
            10,
            0,
            10
          ],
        }] : {}),

      {
        text: 'Patient Information',
        style: 'sectionTitle'
      },

      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          margin: NO_MARGINS,
          body: [
            [
              [{
                text: 'Name',
                style: 'label'
              }, {
                text: data.name,
                style: 'value'
              }],
              [{
                text: 'Date of Birth',
                style: 'label'
              }, {
                text: data.birth,
                style: 'value'
              }],
              [{
                text: 'Legal Sex',
                style: 'label'
              }, {
                text: data.patientDto && data.patientDto.gender ? (data.patientDto.gender === 'F' ? 'Female' : 'Male') : '-',
                style: 'value'
              }],
              [{
                text: 'Zip Code',
                style: 'label'
              }, {
                text: data.zipCode,
                style: 'value'
              }]
            ],

            [
              [{
                text: 'Email',
                style: 'label'
              }, {
                text: data.email,
                style: 'value'
              }],
              [{
                text: 'Mobile Phone Number',
                style: 'label'
              }, {
                text: data.phoneNumber,
                style: 'value'
              }],
              {},
              {}
            ],
          ]
        }
      },

      {
        table: {
          headerRows: 1,
          widths: ['100%'],
          body: [
            [{
              borderColor: [SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR, SEPARATOR_COLOR],
              text: '',
              border: [false, true, false, false]
            }]
          ]
        },
        margin: [
          0,
          10,
          0,
          10
        ],
      },

      {
        text: 'Insurance Information',
        style: 'sectionTitle'
      },
      {
        text: data.insurance.verificationFailed ? 'Unable to verify' : '',
        style: 'value',
        color: '#B92237'
      },
      {
        text:  `Disclaimer - The provided insurance verification information is not a guarantee of coverage.
          Actual benefits are determined only when the claim is received by the insurance carrier.`,
        style: 'value'
      },
      {
        text: data.insurance.verificationFailed && data.insurance.verificationFailedReason ? data.insurance.verificationFailedReason : '',
        style: 'value',
        color: '#B92237'
      },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          margin: NO_MARGINS,
          body: [
            [
              [{
                text: 'Primary Insurance',
                style: 'label'
              }, {
                text: data.insurance.name,
                style: 'value'
              }],
              [{
                text: 'Primary Insurance ID',
                style: 'label'
              }, {
                text: data.insurance.id,
                style: 'value'
              }],
              [{
                text: 'Insurance Subscriber',
                style: 'label'
              }, {
                text: data.insurance.relationWithPatient,
                style: 'value'
              }],
              [{
                text: 'Secondary Insurance',
                style: 'label'
              }, {
                text: data.insurance.secondaryName,
                style: 'value'
              }]
            ],

            [
              [{
                text: 'Secondary Insurance iD Number',
                style: 'label'
              }, {
                text: data.insurance.secondaryId,
                style: 'value'
              }],
              {},
              {},
              {}
            ],
          ]
        }
      }
    ];
  }

  private generateFooterDocDefinition(): TDocumentHeaderFooterFunction {
    return () => [{
      text: 'powered by nodMD',
      alignment: 'center',
      style: 'smallText'
    }];
  }

  private transformToHumanReadable(value: string): string {
    const words = value.split('_');
    const uppercaseWords = words.filter(word => word.length).map(word => {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    });
    return uppercaseWords.join(' ');
  }

}
