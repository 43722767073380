import { Injectable } from '@angular/core';

// Ensure TypeScript knows about the dataLayer on the window object
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loading = false;
  private loadingStartTime: number | null = null;
  public aptLoading = false;
  constructor() {}

  set loading(status: boolean) {
    if (status === true && this._loading === false) {
      this.loadingStartTime = performance.now();
    } else if (status === false && this._loading === true) {
      if (this.loadingStartTime != null) {
        const duration = performance.now() - this.loadingStartTime;
        const fullPageUrl = window.location.href; // Get the full page URL, including domain and subdomains
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'loaderVisibilityDuration',
          'loaderDuration': duration,
          'fullPageUrl': fullPageUrl // Send the full page URL along with the duration
        });
        console.log(`Loading Spinner duration: ${duration}ms, Full Page URL: ${fullPageUrl}`);
        this.loadingStartTime = null;
      }
    }
    this._loading = status;
  }

  get loading() {
    return this._loading;
  }
    showAptLoader(status){
        this.aptLoading = status;
    }

    isLoading(){
        return this.loading;
    }
    
    showLoader(status: boolean) {
        this.loading = status;
    }

    isLoadingForAppointments(){
        return this.aptLoading;
    }

}