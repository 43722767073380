
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'timeZonePipe',
})
export class TimeZonePipe implements PipeTransform {

    transform(items: any, filter: any): any {
        if (!Array.isArray(items)) {
            if (items) {
                {
                   let name : string = items;
                   if(name.startsWith("US/")){
                       return name.replace("US/","America/");
                   }else{
                       return name;
                   }
                }
            }
        }

        if (Array.isArray(items)) {
             items.filter(
                obj => {
                    let name: string = obj;
                    if(name.startsWith("US/")){
                        let temp : string  = name;
                        obj = name.replace("US/","America/");
                        items[items.indexOf(temp)] = obj;
                        // items.splice(items.indexOf(temp),1,obj)
                    }
                    return obj;
                }
            );
        return items;
        }
    }
}