import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { DateRangePipe } from './daterange.pipe';
import { UniquePipe } from './unique.pipe';
import { StateNamePipe } from './state-name.pipe';
import { DobPipe } from './dob.pipe';
import { RoleNamePipe } from './role-name.pipe';
import { ArraySortPipe } from './sort.pipe';
import { BestAvailablePipe } from './best-available.pipe';
import { SanitizeHtmlPipe } from './sanitize.pipe';
import { OpenTaskReasonPipe } from './opentask-reason.pipe';
import { TimeZonePipe } from './timezone.pipe';
import { MomentDatePipe } from './momentDate.pipe';
import { VitalAcronymPipe } from './vital-acronym.pipe';
import { PccTimezonePipe } from './pcc-timezone.pipe';
import { UnderScorePipe } from './underscore.pipe';
import { TimezoneConvertPipe } from './timezone-convert.pipe';



@NgModule({
    imports: [],
    declarations: [
        FilterPipe,
        DateRangePipe,
        UniquePipe,
        StateNamePipe,
        DobPipe,
        RoleNamePipe,
        ArraySortPipe,
        BestAvailablePipe,
        SanitizeHtmlPipe,
        OpenTaskReasonPipe,
        TimeZonePipe,
        MomentDatePipe,
        VitalAcronymPipe,
        PccTimezonePipe,
        UnderScorePipe,
        TimezoneConvertPipe
    ],
    providers: [TimezoneConvertPipe],
    exports: [
        FilterPipe,
        DateRangePipe,
        UniquePipe,
        StateNamePipe,
        DobPipe,
        RoleNamePipe,
        ArraySortPipe,
        BestAvailablePipe,
        SanitizeHtmlPipe,
        OpenTaskReasonPipe,
        TimeZonePipe,
        MomentDatePipe,
        VitalAcronymPipe,
        PccTimezonePipe,
        UnderScorePipe,
        TimezoneConvertPipe
    ]
})
export class PipesModule { }
