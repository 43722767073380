import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProvidersService } from '../api-services/providers.service';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
declare var TigerConnect: any;

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    eINFECTIONMD_ORGANIZATION_ID = 'TN5OK1i1ptY8OW6sVaQCBNqj'
    client = new TigerConnect.Client({ defaultOrganizationId: this.eINFECTIONMD_ORGANIZATION_ID });

    userId = "apitest1@einfectionmd.com";
    password = "ApiTestUser234!"
    // userId = "shubham.soni@fidelitservices.com";
    // password = "Shubham@123"

    baseUrl = environment.baseUrl;

    constructor(
        private _http: HttpClient, private _provider: ProvidersService) {
        localStorage.setItem('organization', this.eINFECTIONMD_ORGANIZATION_ID)
    }

    uuid() { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16) }) }

    async signIn() {
        var that = this;
        // const session = await that.client.signIn(
        //     this.userId, this.password
        // );
        // this.onSignedIn(session)
        await that.client.signIn(that.userId, that.password, that.uuid()).then(
            res => {
                that.onSignedIn(res);
            },
            err => {
                // console.logconsole.log(err)
            }
        )
    }
    async onSignedIn(session) {
        var that = this;
        that.client.events.connect();
        that._provider.messageClient.next(that.client);
    }


    getMessagingProvider() {
        return this._http.get(`${this.baseUrl}communicator-service/api/v1/messaging/providers`);
    }
    saveMessageSetting(data) {
        return this._http.post(`${this.baseUrl}communicator-service/api/v1/messaging/settings`, data)
    }
    getMessagingSetting(data) {
        let params = '';
        if (data.groupType) {
            params += `groupType=${data.groupType}&`;
        }
        if (data.groupId) {
            params += `groupId=${data.groupId}&`;
        }
        return this._http.get(`${this.baseUrl}communicator-service/api/v1/messaging/settings?${params}`);
    }
    getProviderByEmail(id) {
        return this._http.get(`${this.baseUrl}provider-service/api/v1/providers/msgProfile?email=${id}`);
    }

    getCredential(id) {
        return this._http.get(`${this.baseUrl}communicator-service/api/v1/messaging/creds?userId=${id}`);
    }

    saveLoginDetails(data) {
        return this._http.post(`${this.baseUrl}communicator-service/api/v1/messaging/save-creds`, data)
    }

    searchProvider(id, name) {
        return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/providerGroup/${id}/search?name=${name}`);
    }

    getUserListInMessageSetting(data) {
        return this._http.get(`${this.baseUrl}communicator-service/api/v1/messaging/group/users?groupType=${data.groupType}&groupId=${data.groupId}`)
    }
    addUserInTigerconnect(data) {
        return this._http.post(`${this.baseUrl}communicator-service/api/v1/messaging/user`, data);
    }
    updateUserInTigerconnect(data) {
        return this._http.put(`${this.baseUrl}communicator-service/api/v1/messaging/save-creds`, data);
    }
    updateMessageSetting(data) {
        return this._http.put(`${this.baseUrl}communicator-service/api/v1/messaging/settings`, data)
    }
}
