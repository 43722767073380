import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';

import {InsuranceType} from '../models/insurance-type';
import {environment} from '../../../environments/environment';
import {CachableDataService} from './cachable.service';


@Injectable()
export class InsurancesService extends CachableDataService<InsuranceType[]> {
  public defaultInsurancesTypes: InsuranceType[] = [
    {
      id: -1,
      insuranceName: 'I will select a plan later',
      insurancePlanName: '',
      fullName: 'I will select a plan later'
    },
    {
      id: 0,
      insuranceName: 'I will pay cash',
      insurancePlanName: '',
      fullName: 'I will pay cash'
    }
  ];
  protected url = `${environment.baseUrl}provider-service/api/v1/accepted-insurances-with-plan`;
  private insurancesCarrierLabelMap;

  public getData(): Observable<InsuranceType[]> {
    return super.getData().pipe(map((data) => {
      return data.filter(insurance => {
        if (!insurance.insurancePlanName) {
          insurance.insurancePlanName = 'Unknown';
        }
        return insurance.insuranceName && insurance.insuranceName.trim();
      });
    }));
  }

  public generateInsurancesMap(): Observable<{ [insuranceName: string]: InsuranceType }> {
    return this.getData().pipe(
      map(insurancesTypes => {
        const insurancesMap = {};
        insurancesTypes.forEach(insurance => {
          let key = '';
          if (insurance.state) {
            key += `[${insurance.state}] `;
          }
          key += `${insurance.insuranceName || ''} - ${insurance.insurancePlanName || ''}`;
          insurance.name = key;
          if (insurance.thirdPartyAdministrator) {
            key += ` (${insurance.thirdPartyAdministrator})`;
          }
          insurancesMap[key] = insurance;
          insurance.fullName = key;
        });
        return insurancesMap;
      }, share())
    );
  }

  public generateInsurancesCarriersPlanNamesMap(): Observable<{ [insuranceName: string]: InsuranceType[] }> {
    return this.getData().pipe(
      map(insurancesTypes => {
        if (this.insurancesCarrierLabelMap) {
          return this.insurancesCarrierLabelMap;
        }
        this.insurancesCarrierLabelMap = {};
        insurancesTypes.forEach(insurance => {
          let key = '';
          if (insurance.state) {
            key += `[${insurance.state.trim()}] `;
          }
          key += `${insurance.insuranceName || ''} - ${insurance.insurancePlanName || ''}`;
          insurance.name = key;
          if (insurance[key]) {
            if (this.insurancesCarrierLabelMap[key].indexOf(insurance) === -1) {
              this.insurancesCarrierLabelMap[key].push(insurance);
            }
          } else {
            this.insurancesCarrierLabelMap[key] = [insurance];
          }
        });
        return this.insurancesCarrierLabelMap;
      })
    );
  }
}

