import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// import decode from 'jwt-decode';
import { AuthService } from './auth.service';
import { UserService } from '../services/user.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private _auth: AuthService, private _route: Router, private _location: Location) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    try {
      if (this._auth.isLoggedIn()) {
        this._location.back();
        return false;
      }

      else return true;

    } catch (error) {
      this._route.navigate(['login']);
      return false;
    }
  }

}
