<div class="no-data">
  <ng-container *ngIf="planName=='Free'">
    <div class="align_center" style="width: 80%;padding-left: 478px;">A FREE account is not authorized to view the nodMD referral module or to schedule an appointment.  You need to upgrade to a PAID nodMD account.</div>
  </ng-container>
  <ng-container *ngIf="planName!='Free'">
    <div>You are not Authorized for this page.</div>
  </ng-container>
  <div class="align_right">
    <i class="fal fa-frown p-l-10"></i><i class="fal fa-frown p-l-10"></i><i class="fal fa-frown p-l-10"></i>
    <p><button class="btn btn-primary-one" (click)="_location.back()"> Go Back </button></p>
  </div>

</div>