export const environment = {
    production: false,
    isDebugMode: true,
    environmentName: 'QA',
    baseUrl: 'https://api.qa.nodmd.com/',
    visitPortalURL: 'https://visit.qa.nodmd.com/visit',
    iFrameUrl: 'https://specialist.qa.nodmd.com',
    s3BaseUrl: 'https://nod-qa.s3.us-west-2.amazonaws.com/',
    pusherKey: 'd055aead8fed2512d4d0',
    captchaV3SiteKey: '6Lc5ZNYUAAAAAIxjUg5Fw26O00110AZIF4zstACF',
    captchaV2SiteKey: '6LfPZNYUAAAAABOGiYSgofoW3_gfLWQO_2_pDDcZ',
    pccClientId: '6mKA7xPAqAGtJPOlx9trw4JgLicM3QST',
    pccResponseType: 'code',
    pccRedirectUri: 'https://specialist.qa.nodmd.com/facilities',
    pccBaseUrl: 'https://connect.pointclickcare.com/',
    nodMDUrl: 'https://www.nodmd.com/',
    linkToCreatePatientInPcc: 'https://usnpint.pointclickcare.com/admin/client/cp_careclientprofile.jsp',
    clientId: "75@AK349C",
    versionNumber: "V 3.10.0",
    authorizeDotNetAcceptJsUrl: 'https://jstest.authorize.net/v3/AcceptUI.js',
    signupUrl: 'https://signup.qa.nodmd.com/',
    nodbookingRoot: 'nodbookings',
    pluginTnCLink: "https://www.nodmd.com/terms-and-conditions/",
};
