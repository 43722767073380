import { NgModule, ErrorHandler } from '@angular/core';
import { GlobalErrorHandlerService } from './core/services/global-error-handler.service'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './core/Authentication/auth.guard';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import {DatePipe, CommonModule, TitleCasePipe, DecimalPipe} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VerificationComponent } from './modules/verification/verification.component';
import { NotAuthorizedComponent } from './components/shared/common/not-authorized/not-authorized.component';
import { AutoLogoutService } from './core/services/auto-logout.service';
import { PatientDialogComponent } from './components/appointment/patient-eligibility-dialog/patient-eligibility-dialog.component';
import { HomeComponent } from './core/layout/home/home.component';
import { SidenavComponent } from './components/shared/common/sidenav/sidenav.component';
import { DirectivesModule } from './core/directives/directive.module';
import { UserService } from './core/services/user.service';
import { PipesModule } from './core/pipes/pipes.module';
import { LoginComponent } from './modules/login/login.component';
import { MaterialModule } from './components/shared/module/material.module';
import { LoginGuard } from './core/Authentication/login.guard';
import { MobileTestingComponent } from './modules/mobile-testing/mobile-testing.component';
import { ConfirmationDialogComponent } from './components/shared/components/confirmation-dialog/confirmation-dialog.component';
import { StopEncounterDialogComponent } from './components/shared/components/stop-encounter-dialog/stop-encounter-dialog.component';
import { SupportModalComponent } from './components/shared/components/support-modal/support-modal.component';
import { ResetPasswordComponent } from './components/shared/components/reset-password/reset-password.component';
import { AvailabilityAlertComponent } from './components/shared/components/availability-modal-alert/availability-modal-alert.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { PccExpireSessionComponent } from './components/shared/components/pcc-expire-session/pcc-expire-session.component';
import { AudioCallComponent } from './components/shared/components/audio-call/audio-call.component';
// tslint:disable-next-line:max-line-length
import { DeleteConfirmationDialogComponent } from './components/shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { StateNamePipe } from './core/pipes/state-name.pipe';
import { PendingChangesGuard } from './core/Authentication/pending-changes.guard';
import { PendingChangesModalComponent } from './components/shared/common/pending-changes-modal/pending-changes-modal.component';
import { SharedModule } from './modules/shared/shared.module';
import { InboxCountService } from './core/services/inbox-count.service';
import { ReferralPdfService } from './modules/referral/services/referral-pdf.service';
import { ChangeSuperuserDialogComponent } from './components/shared/components/change-superuser-dialog/change-superuser-dialog.component';
import { OkayDialogComponent } from './components/shared/components/okay-dialog/okay-dialog.component';
import {InsurancesService} from './core/services/insurances.service';
import {PreferredProvidersListComponent} from './components/shared/components/preferred-providers-list/preferred-providers-list.component';
import {ValidationHelperService} from './core/services/validation-helper.service';
import {InvalidFieldsComponent} from './components/shared/components/invalid-fields/invalid-fields.component';
import { BillingDialogComponent } from './components/shared/components/billing-dialog/billing-dialog.component';
import { EmailVerificationPopupComponent } from './components/shared/common/email-verification-popup/email-verification-popup.component';
import { AppointmentDoubleBookingComponent } from './components/shared/common/appointment-double-booking/appointment-double-booking.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import {ReferralCsvService} from "./modules/referral/services/referral-csv.service";

import { EmailSentPopupComponent } from './modules/email-sent-popup/email-sent-popup.component';
import { AppointmentConfirmationModalComponent } from './components/shared/components/appointment-confirmation-modal/appointment-confirmation-modal.component';
import { PatientNotificationModalComponent } from './components/shared/components/patient-notification-modal/patient-notification-modal.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { RedirectComponent } from './redirect/redirect.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    InvalidFieldsComponent,
    AvailabilityAlertComponent,
    VerificationComponent,
    NotAuthorizedComponent,
    StopEncounterDialogComponent,
    PatientDialogComponent,
    MobileTestingComponent,
    HomeComponent,
    SidenavComponent,
    SupportModalComponent,
    ResetPasswordComponent,
    PccExpireSessionComponent,
    AudioCallComponent,
    DeleteConfirmationDialogComponent,
    PendingChangesModalComponent,
    ChangeSuperuserDialogComponent,
    OkayDialogComponent,
    BillingDialogComponent,
    EmailVerificationPopupComponent,
    AppointmentDoubleBookingComponent,
    FeedbackComponent,
    EmailSentPopupComponent,
    AppointmentConfirmationModalComponent,
    PatientNotificationModalComponent,
    RedirectComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DirectivesModule,
    PipesModule,
    CommonModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    BillingDialogComponent,
    StopEncounterDialogComponent,
    PatientDialogComponent,
    AvailabilityAlertComponent,
    DeleteConfirmationDialogComponent,
    PendingChangesModalComponent,
    ChangeSuperuserDialogComponent,
    OkayDialogComponent,
    InvalidFieldsComponent,
    EmailVerificationPopupComponent,
    AppointmentDoubleBookingComponent,
    ChangeSuperuserDialogComponent,
    AppointmentConfirmationModalComponent,
    PatientNotificationModalComponent
  ],
    
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.captchaV3SiteKey
    } as RecaptchaSettings
  },
  { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
    AuthGuard,
    LoginGuard,
    AutoLogoutService,
    UserService,
    PipesModule,
    DatePipe,
    TitleCasePipe,
    StateNamePipe,
    PendingChangesGuard,
    ValidationHelperService,
  {
    provide: 'env',
    useValue: environment
  },
    InboxCountService,
  {
    provide: 'env',
    useValue: environment
  },
    ReferralPdfService,
    InsurancesService,
    DecimalPipe,
    ReferralCsvService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
