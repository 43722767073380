import { Router, ActivatedRoute } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import * as store from 'store';
import { AuthService } from '../Authentication/auth.service';

const MINUTES_UNITL_AUTO_LOGOUT = 30; // in Minutes
const CHECK_INTERVALL = 1000 // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {

  constructor(
    private auth: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {
    this.check();
    this.initListener();
    this.initInterval();

  }

  get lastAction() {
    return parseInt(sessionStorage.getItem('LASTACTION'));
  }
  set lastAction(value: any) {
    sessionStorage.setItem('LASTACTION', value)
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    const currUrl = window.parent.document.getElementById('iFrame');
    if (currUrl)
      var iFrameSrc = currUrl.getAttribute('src');
    this.ngZone.run(() => {
      if (isTimeout && this.auth.isLoggedIn() && iFrameSrc == null) {
        this.auth.logout();
      }
    });
  }
}