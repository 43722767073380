<div class="modal fade" id="FeedbackModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> -->
        <button type="button" #close class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="w-100">
            <span class="left-heading">
              <h3>Please help us improve the platform...</h3>
              <h5>We listen to feedback. The "nod" in nodMD means we listen...like when someone "nods" their head as
                they listen.</h5>
            </span>
            <img src="./assets/images/Crowd-01.png">
          </div>
          <div class="w-100">
            <div class="formBox">
              <span class="text-center">
                <h4>Please share your thoughts...</h4>
              </span>
              <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
                <div class="col-12 formContent">

                  <div class="row">
                    <span class="m-t-3_per ml-2">First, let's get to know you...</span>
                  </div>


                  <div class="row formGroup">
                    <label class="required-field">Please rate your experience using the nodMD platform</label>
                    <select class="form-control" formControlName="rating" name="rating"
                      [ngClass]="{ 'invalid-input': submitted && f.rating.errors }">
                      <option value="" hidden disabled selected>Select option
                      </option>
                      <option *ngFor="let rate of rateOfExperience" [value]="rate">
                        {{rate}}
                      </option>
                    </select>
                    <div *ngIf="submitted && f.rating.errors">
                      <div *ngIf="submitted && f.rating.errors.required" class="errors">Please select option
                      </div>
                    </div>
                  </div>
                  <div class="row formGroup">
                    <label class="required-field">How often do you use the platform?</label>
                    <select class="form-control" formControlName="platformUse" name="platform_used"
                      [ngClass]="{ 'invalid-input': submitted && f.platformUse.errors }">
                      <option value="" hidden disabled selected>Select option
                      </option>
                      <option *ngFor="let use of useOfPlatform" [value]="use">
                        {{use}}
                      </option>
                    </select>
                    <div *ngIf="submitted && f.platformUse.errors">
                      <div *ngIf="submitted && f.platformUse.errors.required" class="errors">Please select option
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <span class="m-t-3_per ml-2">How would you rate the platform on a scale of 1-10 (1=very poor, 10=
                      awesome)?</span>
                  </div>
                  <div class="row formGroup">
                    <label><i class="fa fa-circle m-t-5_per f-s-10"></i> Ease of use - i.e.
                      platform is easy to navigate /
                      intuitive</label>
                    <textarea class="form-control" formControlName="easeOfUse" rows="2" placeholder="" [tabIndex]=""
                      onlyNumbers maxlength="2"></textarea>
                    <div *ngIf="submitted && f.easeOfUse.errors">
                      <div *ngIf="submitted && f.easeOfUse.errors.pattern" class="errors">Required input is between
                        1-10</div>
                    </div>
                  </div>
                  <div class="row formGroup">
                    <label><i class="fa fa-circle m-t-5_per f-s-10"></i> Reliability - platform
                      works, not glitchy, no waiting for pages to
                      load/appear/refresh</label>
                    <textarea class="form-control" formControlName="reliability" rows="2" placeholder="" [tabIndex]=""
                      onlyNumbers maxlength="2"></textarea>
                    <div *ngIf="submitted && f.reliability.errors">
                      <div *ngIf="submitted && f.reliability.errors.pattern" class="errors">Required input is between
                        1-10</div>
                    </div>
                  </div>
                  <div class="row formGroup">
                    <label><i class="fa fa-circle m-t-5_per f-s-10"></i> Value - platform offers
                      great value to our practice</label>
                    <textarea class="form-control" formControlName="value" rows="2" placeholder="" [tabIndex]=""
                      onlyNumbers maxlength="2"></textarea>
                    <div *ngIf="submitted && f.value.errors">
                      <div *ngIf="submitted && f.value.errors.pattern" class="errors">Required input is between
                        1-10</div>
                    </div>
                  </div>

                  <div class="row formGroup">
                    <span class="m-t-3_per"> Suggestions / Observations / Ways we can improve…</span>
                    <textarea class="form-control" formControlName="suggestions" rows="4" placeholder="" maxlength="500"
                      [tabIndex]=""></textarea>
                  </div>
                </div>

              </form>
              <div class="row meetingLink">
                <h5>If you want to schedule a 15 min meeting with our Client Success team, <a
                    href="https://calendly.com/nodmd-csr/tiago_cruz" target="_blank"><span
                      style="color: #195659;"><u><b>click
                          here.</b></u></span></a></h5>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();">Submit</button>
      </div>
    </div>
  </div>
</div>