import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import Swal from 'sweetalert2'
import { AdminService } from 'src/app/core/api-services/admin.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit {

  verificationForm: FormGroup;
  showPasswordForm: boolean = false;
  data: any = {
    emailId: "",
    otp: ""
  }
  isDuplicateUname: boolean = false;
  isForgotPassword: Boolean = false;
  token: any;
  isPcp: any;
  regX: any;
  patternErr: any;
  accountTitle: any = "Specialist";
  /**
   * Creates an instance of verification component.
   * @param formBuilder
   * @param _router
   * @param route
   * @param _loader
   * @param _admin
   */
  constructor(private formBuilder: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private _admin: AdminService) {

    this.route.queryParamMap.subscribe(queryParams => {
      this.token = queryParams.get("token");
      this.isPcp = queryParams.get("ispcp") == "true" ? true : false;
      this.accountTitle = this.isPcp ? "Primary Care" : "Specialist";
    });

    if (this.route.snapshot.url.join().split(',')[0] === 'forgot-password') {
      this.isForgotPassword = true;
    }

    if (!this.isForgotPassword && this.token) {
      this.verificationLink();
    } else if (this.isForgotPassword && this.token) {
      this.forgotPasswordVerificationLink()
    }
  }

  ngOnInit() {
    this.getRegX();
    this.validateForm();
  }

  getRegX() {
    this.regX = new RegExp("aa");
    this._admin.getRegX().subscribe((res: any) => {

      if (res) {
        let pattern = res.pattern as string;
        this.regX = new RegExp(pattern);
        this.patternErr = res.message;
        this.verificationForm.controls['password'].setValidators(Validators.pattern(this.regX))
      }
    })
  }

  /**
   * Validates form
   */
  validateForm() {
    this.verificationForm = this.formBuilder.group({
      emailId: [this.data.emailId],
      otp: [this.data.otp, Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern(this.regX),
        Validators.minLength(8)
      ]],
      rePassword: ['', Validators.required],
    })
  }

  /**
  * @returns Forms Controls
  */
  get formControls() { return this.verificationForm.controls; }

  /**
   * Verifications link
   */
  async verificationLink() {
    this._loader.showLoader(true);
    await this._admin.verifyLink(this.token).subscribe(
      (res: any) => {

        this._loader.showLoader(false);
        if (res.status == true) {
          this.data.emailId = res.emailId;
          this.data.otp = res.otp;
          this.setFormData();
          this.verifiedSwal(res.passwordSet);
        }
        else {
          this.showVerificationMessageAlert(res)
          this.showPasswordForm = false;
        }
      },
      error => {
        this._loader.showLoader(false);
        this.showPasswordForm = false;
      }
    )
  }

  /**
   * Forgots password verification link
   */
  forgotPasswordVerificationLink() {
    this._loader.showLoader(true);
    this._admin.verifyLinkForgotPassword(this.token).subscribe(
      (res: any) => {
        this._loader.showLoader(false);
        if (res.status == true) {
          this.showPasswordForm = true;
          this.data.emailId = res.emailId;
          this.data.otp = res.otp;
          this.setFormData();
        }
        else {
          this.showVerificationMessageAlert(res)
          this.showPasswordForm = false;
        }
      },
      error => {
        this._loader.showLoader(false);
        this.showPasswordForm = false;
      }
    )
  }

  /**
   * Sets form data
   */
  setFormData() {
    this.verificationForm.get('emailId').setValue(this.data.emailId);
    this.verificationForm.get('otp').setValue(this.data.otp);
  }

  /**
   * Determines whether blur username on
   * @param e
   */
  onBlurUsername(e) {

    if (e.target.value != "") {
      this._admin.verifyUsername(e.target.value).subscribe(
        (res: any) => {

          if (res.status) {
            this.isDuplicateUname = true;
          } else {
            this.isDuplicateUname = false;
          }
        },
        error => {

        }
      )
    }
  }

  onSubmit() {
    if (this.verificationForm.valid) {
      let password: string = this.verificationForm.get('password').value;
      let rePassword: string = this.verificationForm.get('rePassword').value
      if (password === rePassword) {
        if (!this.isForgotPassword) {
          this.submitForPasswordCreation();
        } else {
          this.submitForForgotPassword();
        }
      }
    }
  }

  /**
   * Submits for password creation
   */
  submitForPasswordCreation() {
    this._loader.showLoader(true)
    this._admin.createPassword(this.verificationForm.value).subscribe(
      (res: any) => {
        this._loader.showLoader(false);
        if (res.status == true) {
          if (this.isPcp) {
            this._router.navigateByUrl(`/pcp-login`);
          } else {
            this._router.navigateByUrl(`/login`);
          }
          localStorage.setItem("nod_username", this.verificationForm.get('emailId').value);
        }
        else {
          this.showErrorVerificationMessageAlert(res);
        }
      },
      error => {

      }
    )
  }

  /**
   * Submits for forgot password
   */
  submitForForgotPassword() {

    this._loader.showLoader(true)
    this._admin.createNewPassword(this.verificationForm.value).subscribe(
      (res: any) => {
        this._loader.showLoader(false);
        if (res.status == true) {
          this._router.navigateByUrl(`/login`);
        }
        else {
          this.showErrorVerificationMessageAlert(res);
        }
      },
      error => {

      }
    )
  }

  verifiedSwal(flag) {
    Swal.fire({
      position: 'center',
      type: 'success',
      title: 'Verified',
      showConfirmButton: false,
      backdrop: `rgb(39, 103, 106)`,
      timer: 2000
    }).then((r) => {
      if (flag) {
        if (this.isPcp) {
          this._router.navigateByUrl(`/pcp-login`);
        } else {
          this._router.navigateByUrl(`/login`);
        }
      } else {
        this.showPasswordForm = true;
      }
    });
  }

  /**
   * Shows verification message alert
   * @param data
   */
  showVerificationMessageAlert(data: any) {
    let html: string;
    let message: string;
    let showBtn: boolean;
    if (data.message == "Your account verification has been failed") {
      html = '<strong>Need more help? Email <a href= "mailto:helpme@nodmd.com" target="_blank">helpme@nodmd.com</a> </strong>'
      message = data.message;
      showBtn = false;
    } else {
      showBtn = true;
      message = '';
      html = data.message;
    }

    Swal.fire({
      title: message,
      html: html,
      imageUrl: '/assets/images/nod-logo.png',
      imageWidth: 400,
      imageHeight: 110,
      imageAlt: 'Custom image',
      animation: false,
      showConfirmButton: showBtn,
      confirmButtonText: "login",
      backdrop: `rgb(39, 103, 106)`
    }).then((result) => {
      if (result.value) {
        if (this.isPcp) {
          this._router.navigateByUrl(`/pcp-login`);
        } else {
          this._router.navigateByUrl(`/login`);
        }
      }
    })
  }

  showErrorVerificationMessageAlert(res) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: res.message,
      timer: 3000,
    })
  }

  prevent(event) {
    if (event.keyCode == 220) {
      event.preventDefault();
    }
  }

  showPassword(passEl, type) {
    var el = passEl;
    if (type == "text") {
      el.setAttribute("type", "password")
    } else {
      el.setAttribute("type", "text")
    }
  }

}
