import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {share, tap} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';

@Injectable()
/** Common service for data caching. */
export abstract class CachableDataService<T> {
  /** URL for the request. */
  protected url: string;
  /** Cached data. */
  protected data: T;
  /** Intermediate source of the data before caching. */
  protected dataProgress$: Observable<T>;

  /**
   * Class constructor.
   * Subscribes on `isLoggedIn$` variable.
   */
  constructor(protected http: HttpClient, @Inject('env') protected environment) {
    this.tapper = this.tapper.bind(this);
  }

  /**
   * Gets the data.
   * If data is not cached, it sends a request for it.
   * If data is cached, it returns cached data.
   * @returns Cached data.
   */
  public getData(): Observable<T> {
    if (!this.dataProgress$ && !this.data) {
      this.dataProgress$ = this.getDataFromApi();
    }
    if (this.data) {
      return of(this.data);
    }
    return this.dataProgress$;
  }

  /**
   * Gets data from the API for the first time and caches it.
   * @returns Response from the server with the data to cache.
   */
  protected getDataFromApi(): Observable<T> {
    return this.http.get<T>(this.url).pipe(
      tap(this.tapper),
      share()
    );
  }

  protected tapper(data): void {
    this.data = data;
  }
}
