import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PracticeClosest, ProviderClosest, ProviderInfo, TemporaryPractice, TemporaryProvider } from '../models/provider.model';
import { Speciality } from '../models/speciality.model';
import { Address } from '../../endorsements/models/address';
import { PaginationResult } from 'nodbookings/lib/models/pagination-result';
import { Referral, ReferralForm } from '../models/referral.model';
import { MatSort } from "@angular/material/sort";
import { catchError } from 'rxjs/operators';
import { AlertService } from '../../../core/helpers/alert.service';
import { AdminInfo } from '../models/admin-info';

@Injectable({
    providedIn: 'root'
})
export class ReferralService {

    constructor(private http: HttpClient,
        private alertService: AlertService) {
    }

    public getProviderInfo(providerId: string): Observable<ProviderInfo> {
        return this.http.get<ProviderInfo>(`${environment.baseUrl}provider-service/api/v1/provider-for-referral/${providerId}`);
    }

    public getInsurance(value: string): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}common-service/api/v1/insurance/insurance-carrier?text=${value}`);
    }
    public getAllAdmins(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}provider-service/api/v1/provider-group/all-admins`);
    }
    public getSpecialities(value: string): Observable<Speciality[]> {
        return this.http.get<Speciality[]>(`${environment.baseUrl}provider-service/api/v1/specialities?name=${value}`);
    }

    public getPaidProviders(practiceId: any): Observable<ProviderInfo[]> {
        return this.http.get<ProviderInfo[]>(`${environment.baseUrl}provider-service/api/v1/practice-paid-providers?practiceId=${practiceId}`);
    }
    public getProviderLocations(providerId: string): Observable<Address[]> {
        return this.http.get<Address[]>(`${environment.baseUrl}provider-service/api/v1/provider-locations/${providerId}`);
    }

    // tslint:disable-next-line:max-line-length
    public getClosestProviders(text: string, zipcode: string | number, specialityId: string, page = 0, isOnlyPreferredProviders = false, includeLicenseStateFiltering = true): Observable<PaginationResult<ProviderClosest>> {
        const isOnlyPreferredProvidersParam = `isOnlyPreferredProviders=${isOnlyPreferredProviders ? 'true' : 'false'}`;
        const params = `specialtyId=${specialityId}&page=${page}&text=${text}&size=5&${isOnlyPreferredProvidersParam}&includeLicenseStateFiltering=${includeLicenseStateFiltering}`;
        return this.http.get<PaginationResult<ProviderClosest>>(
            `${environment.baseUrl}provider-service/api/v1/providers-sorted-by-closest/zip-code/${zipcode}?${params}`
        );
    }
    public getClosestPractices(text: string, zipcode: string | number, specialityId: string, page = 0,
         preferredOnly:boolean): Observable<PaginationResult<PracticeClosest>> { 
        const isOnlyPreferredParam = `${preferredOnly ? 'true' : 'false'}`;                       
        const params = `specialtyId=${specialityId}&preferredOnly=${isOnlyPreferredParam}&page=${page}&name=${text}&size=5`;
        return this.http.get<PaginationResult<PracticeClosest>>(
            `${environment.baseUrl}provider-service/api/v1/practices-sorted-by-closest/zip-code/${zipcode}?${params}`
        );
    }

    public getReferrals(providerId: string, status: string,startDate: string,endDate: string, page: number = 0, sort: MatSort): Observable<PaginationResult<Referral>> {
        const sortStr = `sort=${sort && sort.active ? sort.active : 'dateReferred'},${sort && sort.direction ? sort.direction : 'desc'}`;
        return this.http.get<PaginationResult<Referral>>(
            `${environment.baseUrl}provider-service/api/v1/referral/appointment/providerId/${providerId}?appointmentStatus=${status}&startDate=${startDate}&endDate=${endDate}&page=${page}&${sortStr}`
        );
    }
    public getSentReferrals(providerId: string,startDate: string,endDate: string, page: number = 0, sort: MatSort): Observable<PaginationResult<Referral>> {
        const sortStr = `sort=${sort && sort.active ? sort.active : 'dateReferred'},${sort && sort.direction ? sort.direction : 'desc'}`;
        return this.http.get<PaginationResult<Referral>>(
            `${environment.baseUrl}provider-service/api/v1/referral/appointment/providerId/${providerId}/sender?startDate=${startDate}&endDate=${endDate}&page=${page}&${sortStr}`
        );
    }
    public downloadReferralsBySenderProviderIdCsv(providerId: string,startDate: string,endDate: string) {
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/appointment/providerId/${providerId}/sender/export-csv?startDate=${startDate}&endDate=${endDate}`, { responseType: "blob" });
    }
    public downloadReferralsByProviderIdCsv(providerId: string,status: string,startDate: string,endDate: string) {
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/appointment/providerId/${providerId}/export-csv?appointmentStatus=${status}&startDate=${startDate}&endDate=${endDate}`, { responseType: "blob" });
    }

    public getReferral(entityId: number): Observable<Referral> {
        return this.http.get<Referral>(
            `${environment.baseUrl}provider-service/api/v1/referral/appointment/detailed/${entityId}`
        );
    }

    public createOrUpdateTemporaryProvider(provider: TemporaryProvider, patientZipCode: string, create :boolean) {
        const url = `${environment.baseUrl}provider-service/api/v1/temporary-provider/by-specialist`;
        const updateUrl = `${environment.baseUrl}provider-service/api/v1/temporary-provider/${provider.id}`;
        const data = { ...provider };
        data.phoneNumber = data.phoneNumber.replace(/\(/g, '');
        data.phoneNumber = data.phoneNumber.replace(/\)/g, '');
        data.phoneNumber = data.phoneNumber.replace(/ /g, '-');
        data.faxNumber = data.faxNumber.replace(/\(/g, '');
        data.faxNumber = data.faxNumber.replace(/\)/g, '');
        data.faxNumber = data.faxNumber.replace(/ /g, '-');
        if (create) {
            return this.http
                .post<any>(
                    url,
                    data
                ).pipe(catchError(err => {
                    this.alertService.errorAlert(err.error || 'Unknown error happened. Please try again later');
                    return throwError(err);
                }));
        } else {
            return this.http
                .put<any[]>(
                    updateUrl,
                    data
                );
        }

    }
    public createOrUpdateTemporaryPractice(practice: TemporaryPractice,create :boolean) {
        const url = `${environment.baseUrl}provider-service/api/v1/no-login-practice`;
        const updateUrl = `${environment.baseUrl}provider-service/api/v1/no-login-practice/${practice.id}`;
        const data = { ...practice };
        data.phoneNumber = data.phoneNumber.replace(/\(/g, '');
        data.phoneNumber = data.phoneNumber.replace(/\)/g, '');
        data.phoneNumber = data.phoneNumber.replace(/ /g, '-');
        data.faxNumber = data.faxNumber.replace(/\(/g, '');
        data.faxNumber = data.faxNumber.replace(/\)/g, '');
        data.faxNumber = data.faxNumber.replace(/ /g, '-');
        if (create) {
            return this.http
                .post<any>(
                    url,
                    data
                ).pipe(catchError(err => {
                    this.alertService.errorAlert(err.error || 'Unknown error happened. Please try again later');
                    return throwError(err);
                }));
        } else {
            return this.http
                .put<any[]>(
                    updateUrl,
                    data
                );
        }

    }

    public saveReferral(referralData: any, isProviderReferral:boolean): Observable<any> {        
        if(isProviderReferral){          
            return this.http.post<ReferralForm>(
                `${environment.baseUrl}provider-service/api/v1/referral`,
                referralData
            );
        }else{             
            return this.http.post<ReferralForm>(
                `${environment.baseUrl}provider-service/api/v1/referral/practice`,
                referralData
            );
        }        
    }

    public updateReferral(referralData: any): Observable<any> {        
        return this.http.put<any>(
            `${environment.baseUrl}provider-service/api/v1/referral`,
            referralData
        );
    }
    public updatePracticeReferral(referralData: any): Observable<any> {    
        let params='';
        if(referralData.cancelReasonId) {
            params =`&reasonId=${referralData.cancelReasonId}`;
        }
        if(referralData.customCancelReason) {
            params = params + `&customReason=${referralData.customCancelReason}`;
        }     
        return this.http.put<any>(`${environment.baseUrl}provider-service/api/v1/referral/practice/${referralData.acceptStatus}/${referralData.id}?inboxId=${referralData.inboxId}${params}`,referralData);
    }

    public getProviderGroupNameAndAdmin(providerId: number): Observable<AdminInfo> {
        return this.http.get<AdminInfo>(`${environment.baseUrl}provider-service/api/v1/practice-contact-info/provider/${providerId}`);
    }

    public getVisitDurations(providerId: any) {
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/provider?providerId=${providerId}`);
    }
    public assignProvider(data: any): Observable<any> {    
            return this.http.post<any>(`${environment.baseUrl}appointment-service/api/v1/appointment/assign-provider`,data);
    }
    public getInsuranceInformation(referralId: any) {        
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/${referralId}/insurance`);
    }
    public getAttachedFiles(referralId: any) {        
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/documents/${referralId}`);
    }
    public getAttachmentById(id: any) {        
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/documents/file/${id}`);
    }
    public getAllAttachedFiles(referralId: any) {        
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/referral/documents/files/${referralId}`);
    }
    public getPracticeNameByProviderId(providerId: any) {        
        return this.http.get(`${environment.baseUrl}provider-service/api/v1/practice-info/provider/${providerId}`);
    }
}
