<div mat-dialog-content>
  <div class="message-soon"> {{message}} </div>
</div>
<div mat-dialog-actions>
  <div class="m-l-auto m-t-20">
    <button class="btn modal-btn close-modal-btn m-r-20" (click)="onNoClick()">Cancel Changes</button>
    <button class="btn modal-btn close-modal-btn" [mat-dialog-close]="true" cdkFocusInitial>Proceed with
      Changes</button>
  </div>


</div>