import {Injectable} from '@angular/core';
import {LoaderService} from '../../../core/services/loader.service';
import {ReferralService} from './referral.service';
import {AppointmentService} from '../../../core/api-services/appointment.service';
import {ReferralDetails} from '../models/referral.model';
import {saveAs} from 'file-saver';

@Injectable()
export class ReferralCsvService {
    constructor(private loaderService: LoaderService,
                private referralService: ReferralService,
                private appointmentService: AppointmentService) {
    }

    public generateCsv(data: ReferralDetails, appointmentData: any): void {
        let csvContent = `Appointment Information,\n`;
        csvContent += `Referred to, "${data.referredTo.name}"\n`;
        csvContent += `Speciality, "${data.specialty}"\n`;
        csvContent += `Office phone number, "${data.referredTo.officeNumber}"\n`;
        csvContent += `Office address, "${data.referredTo.address}"\n`;

        csvContent += `Appointment Date, "${data.apptDate}"\n`;
        const aptTime = data.apptTime +
                (this.appointmentService.timezoneChangeSubscription.getValue() ?
                    (' (' + this.appointmentService.timezoneChangeSubscription.getValue() + ')') : '');
        csvContent += `Appointment Time, "${aptTime}"\n`;
        csvContent += `Visit Type, "${appointmentData ? (appointmentData.calendarId === 1 ? 'Virtual' : 'In-person') : '-'}"\n`;
        csvContent += `Office fax number, "${data.referredTo.officeFax}"\n`;

        csvContent += `Reason for Referral, "${data.referralReason}"\n`;
        csvContent += `Appointment Status, "${this.transformToHumanReadable(data.appointmentStatus)}"\n`;
        csvContent += `Patient Pay Type, "${data.isPatientSelfPay ? 'Self-pay' : 'Insurance'}"\n`;
        csvContent += ',\n';

        if (data.isReferral) {
            csvContent += 'Referral Information,\n';

            csvContent += `Referred by, "${data.referredBy && data.referredBy.name || '-'}"\n`;
            csvContent += `Office phone number, "${data.referredBy.officeNumber}"\n`;
            csvContent += `Office fax number, "${data.referredBy.officeFax}"\n`;
            csvContent += `Office address, "${data.referredBy.address}"\n`;

            csvContent += `Date Referred, "${data.dateReferred}"\n`;
            csvContent += `Prior Authorization, "${data.isPriorAuth}"\n`;
            csvContent += `Referral Status, "${this.transformToHumanReadable(data.status)}"\n`;
            csvContent += `ICD-10 Code/Diagnosis, "${data.icd}"\n`;
            csvContent += ',\n';
        }

        csvContent += `Patient Information, \n`;
        csvContent += `Name, "${data.name}" \n`;
        csvContent += `Date of Birth, "${data.birth}" \n`;
        csvContent += `Legal Sex, "${
            data.patientDto && data.patientDto.gender ? (data.patientDto.gender === 'F' ? 'Female' : 'Male') : '-'
        }" \n`;
        csvContent += `Zip Code, "${data.zipCode}" \n`;

        csvContent += `Email, "${data.email}" \n`;
        csvContent += `Mobile Phone Number, "${data.phoneNumber}" \n`;
        csvContent += ',\n';

        csvContent += 'Insurance Information, \n';
        if (data.insurance.verificationFailed) {
            const reason = data.insurance.verificationFailed &&
            data.insurance.verificationFailedReason ? data.insurance.verificationFailedReason : '';
            csvContent += `"Unable to verify (${reason})", \n`;
        } else {
            csvContent += `Primary Insurance, "${data.insurance.name}" \n`;
            csvContent += `Primary Insurance ID, "${data.insurance.id}" \n`;
            csvContent += `Insurance Subscriber, "${data.insurance.relationWithPatient}" \n`;
            csvContent += `Secondary Insurance, "${data.insurance.secondaryName}" \n`;
            csvContent += `Secondary Insurance iD Number, "${data.insurance.secondaryId}"\n`;
        }

        const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `referral.csv`);
    }

    private transformToHumanReadable(value: string): string {
        const words = value.split('_');
        const uppercaseWords = words.filter(word => word.length).map(word => {
            return word[0].toUpperCase() + word.slice(1).toLowerCase();
        });
        return uppercaseWords.join(' ');
    }
}
