<div class="data-wrapper">
</div>
<fix-sidenav></fix-sidenav>
<router-outlet>
</router-outlet>

<ng-container>
  <!-- <app-pcc-expire-session [isSessionExpire]="isSessionExpire" (onSkip)="skip($event)" [message]="message"
    [isEmailVerify]="verificationModal.flag" [userId]="userId" [userEmail]="userEmail">
  </app-pcc-expire-session> -->
</ng-container>