<ng-container *ngIf="message">
  <div mat-dialog-content>
    <div class="message-soon break-w"> {{message}} </div>
  </div>
  <div mat-dialog-actions>
    <div class="col-md-12 text-right m-t-15 d-flex justify-content-end">
      <button class="btn cancel-modal-btn text-right m-r-50" [mat-dialog-close]="true"
        (click)="onYesClick()">Yes</button>
      <button class="btn delete-modal-btn text-right" (click)="onNoClick()" cdkFocusInitial>No</button>
    </div>
  </div>
</ng-container>