import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppointmentService } from 'src/app/core/api-services/appointment.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message:string,
      id:any
    }, private appointmentService:AppointmentService) { 
      
    }
    onNoClick(): void {
    this.dialogRef.close();
  }
}
