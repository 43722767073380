<div id="sidebar" [ngClass]="showSideBar ? 'sidebar opened' : 'sidebar'" (click)="closeNav1()">
    <a href="javascript:void(0)" class="cross-button" (click)="closeNav()">&times;</a>
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                <div *ngIf="planName!='Free'">
                    <li routerLinkActive="active" (click)="closeNav()"
                        *hasPermission="[Permissions.VIEW_APPOINTMENT,Permissions.CREATE_EDIT_CANCEL_VIEW_APPOINTMENT]; op 'OR'">
                        <a [routerLink]="appointments"><i class="fal fa-th-list"></i><span class="sidenav-space">{{
                                'sidenav.appointments' |
                                translate }}</span> </a>
                    </li>
                </div>
                <li routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="findAdoc"><i class="fas fa-user-md"></i>
                        <span>Find-a-Specialist</span>
                    </a>
                </li>
                <ng-container *ngIf="planName!='Free'">
                    <li routerLinkActive="active" (click)="closeNav()" *hasPermission="[Permissions.VIEW_OPEN_TASK]">
                        <a [routerLink]="opentasks"><i class="fal fa-redo"></i>
                            <span class="sidenav-space">{{ 'sidenav.opentasks' | translate }}</span>
                            <div class="exclamation" *ngIf="openTaskCount">{{openTaskCount}}</div>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="planName!='Free'">
                    <li routerLinkActive="active" (click)="closeNav()"
                        *hasPermission="[Permissions.VIEW_PATIENT,Permissions.CREATE_EDIT_VIEW_PATIENT]; op 'OR'">
                        <a [routerLink]="patients"><i class="fal fa-users"></i><span>{{ 'sidenav.patients' | translate
                                }}</span> </a>
                    </li>
                </ng-container>
                <li *ngIf="planName!='Free'" routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="referral"><i class="fas fa-file-import"></i> <span>
                            {{ 'sidenav.referral' | translate }}
                        </span>
                    </a>
                </li>
                <li routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="inbox"><i class="fal fa-inbox" aria-hidden="true"></i> <span>
                            {{ 'sidenav.inbox' | translate }}
                        </span>
                        <div class="exclamation" *ngIf="inboxCountService.unreadMessagesCount">
                            {{inboxCountService.unreadMessagesCount}}
                        </div>
                    </a>


                </li>

                <li routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="endorsements"><i class="fal fa-user-check" aria-hidden="true"></i> <span>
                            {{ 'sidenav.endorsements' | translate }}
                        </span>
                    </a>
                </li>

                <li *ngIf="planName!='Free'" routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="'/intake-forms'"><i class="fas fa-notes-medical"></i>
                        <span>Intake Forms</span>
                    </a>
                </li>


                <li *ngIf="planName!='Free'" routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="facilities"><i class="fal fa-hospital-alt fa-w-18" aria-hidden="true"></i> <span>
                            {{ 'sidenav.facilities' | translate }}
                        </span>
                    </a>
                </li>


                <div *ngIf="planName!='Free'">
                    <li routerLinkActive="active" (click)="closeNav()" *hasPermission="[Permissions.VIEW_REPORTS]">
                        <a [routerLink]="reports"><i class="fal fa-file-csv" aria-hidden="true"></i> <span
                                class="sidenav-space">Reports
                            </span>
                        </a>
                    </li>
                </div>
                <div *ngIf="planName!='Free'">
                    <li routerLinkActive="active" (click)="closeNav()" *hasPermission="[Permissions.VIEW_MESSAGES]">
                        <a [routerLink]="fastConsult">
                            <i class="fa fa-inbox" aria-hidden="true"></i>
                            <span>{{'sidenav.smartconsult' | translate}}</span>
                        </a>
                    </li>
                </div>
                <li routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="settings"><i class="fal fa-cog" aria-hidden="true"></i><span>{{'sidenav.settings' |
                            translate}}</span>
                    </a>
                </li>
                <li routerLinkActive="active" (click)="closeNav()">
                    <a [routerLink]="learnMore"><i class="fab fa-leanpub"></i>
                        <span>Learn More</span>
                    </a>
                </li>
                <li style="color:#fff;cursor: pointer;">
                    <a (click)="logOut()" style="color:#fff;cursor: pointer"><i class="fal fa-sign-out"
                            style="transform: scaleX(-1);" aria-hidden="true"></i>
                        <span> {{'sidenav.signout' | translate}}</span></a>
                </li>
                <li>
                    <div class="timezone-feature">
                        <span class="info-icon p-l-10">
                            <span class="tool-wrapper"
                                  appCommonTooltip
                                  [tooltip]="tooltipInfo.INFO_TIMEZONE">
                                <i class="fal fa-info-circle"
                                   aria-hidden="true"></i>
                            </span>
                        </span>
                        <select class="form-control" [(ngModel)]="currentTimeZone"
                            style="cursor: pointer; width: 100%;padding-left:0" (ngModelChange)="onTimeZoneChange()">
                            <option *ngFor="let t of timzoneList" [value]="t.zoneName">
                                {{t.area |timeZonePipe | underscore}}
                            </option>
                        </select>
                        
                    </div>
                    
                </li>

            </ul>
        </div>

        <div class="privacy-policy">
            <div class="v-num">
                {{ versionNumber }}<a href="" data-toggle="modal" data-target="#FeedbackModal">Feedback?</a>
            </div>

        </div>
    </div>
</div>
<app-feedback></app-feedback>
