import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { error } from 'console';
import { AppointmentService } from 'src/app/core/api-services/appointment.service';
import { AlertService } from 'src/app/core/helpers/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-confirmation-modal',
  templateUrl: './appointment-confirmation-modal.component.html',
  styleUrls: ['./appointment-confirmation-modal.component.css']
})
export class AppointmentConfirmationModalComponent implements OnInit {
  message :string = "Appointment Confirmation";
  copyLinkToURL:boolean = false;
  public patientName:string;
  public dob:string;
  patientNotificationsForm:FormGroup;
  constructor(public dialogRef: MatDialogRef<AppointmentConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appointmentService:AppointmentService,
    private alert:AlertService, private formBuilder:FormBuilder) { }
  
    ngOnInit() {
     this.intilizeForm();
   }

   notificationsDTOList = {
    templateNotification:{},
    appointmentId:null
  }


  onNoClick(): void {
    this.dialogRef.close(true);
  }

  copyLinkToURl(){
    const baseURl = environment.iFrameUrl+'/appointment/'+this.data.appointmentId+'/send-appointment-confirmation';
    window.navigator['clipboard'].writeText(baseURl);
  }

  sendAppointmentEmail(){
    this.appointmentService.sendConfirmationEmail(this.data.appointmentId).subscribe((data:any) => {
        this.alert.successAlert("Email sent successfully");
        this.onNoClick();
    },(error:any)=>{
      this.alert.errorAlert(error);
    });
  }

 
 intilizeForm(){
  this.patientName = this.data.patientFirstName + ' ' + this.data.patientLastName;
  this.dob = this.data.dob;
  this.patientNotificationsForm = this.formBuilder.group({
    APPOINTMENT_SCHEDULED_REMINDER:this.formBuilder.group({
      text:new FormControl(false),
      email:new FormControl(false)
    }),

    FOLLOW_UP_APPOINTMENT_REMINDER:this.formBuilder.group({
      text:new FormControl(false),
      email:new FormControl(false)
    }),

    COMPLETE_ORDER_REQUEST:this.formBuilder.group({
      text:new FormControl(false),
      email:new FormControl(false)
    }),

    RATE_APPOINTMENT_SURVEY:this.formBuilder.group({
      text:new FormControl(false),
      email:new FormControl(false)
    }),

    CALL_ASAP_REQUEST:this.formBuilder.group({
      text:new FormControl(false),
      email:new FormControl(false)
    }),

  })
}

  onSubmitSend(){
    this.notificationsDTOList.templateNotification = this.patientNotificationsForm.value;
    this.notificationsDTOList.appointmentId = this.data.appointmentId;

    if(this.checkValidation()){
    this.appointmentService.sendNotifications(this.notificationsDTOList).subscribe((res) => {
      if(res !== null && res){
        this.alert.successAlert("Notification send successfully");
      }
  },
      (error) =>{
        this.alert.errorAlert("something went wrong");
      }
  )
    }else {
      this.alert.errorAlert("Please select notification to send text or email");
    }

  }

  checkValidation(){
    for (const notificationType in this.patientNotificationsForm.controls) {
      if (this.patientNotificationsForm.controls.hasOwnProperty(notificationType)) {
        const notificationGroup = this.patientNotificationsForm.get(notificationType);
    
        // Iterate over the nested form group for each notification type
        for (const controlName in notificationGroup['controls']) {
          if (notificationGroup['controls'].hasOwnProperty(controlName)) {
            const control = notificationGroup['controls'][controlName];
    
            // Check if the control is a FormControl and not a nested group
            if (control instanceof FormControl) {
              if(control.value == true){
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  sendNotification(formGroupName, formGroupsValues){
    const getFormValues = formGroupsValues.value;
    const convertFormValues = JSON.stringify(getFormValues)
  }

}
