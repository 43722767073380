<div class="modal fade" id="supportModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{'support.nodSupport' | translate}}</h3>
        <button type="button" class="close" (click)="resetForm()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="supportForm" (ngSubmit)="onSubmit()" class="form-signin validate-form">
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12">
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="required-field">{{'support.name' | translate}}</label>
                  <input type="text" formControlName="name" class="form-control" placeholder="Name">
                  <div *ngIf="submitted && formControls.name?.errors" class="invalid-feedback d-block">
                    <div *ngIf="formControls.name?.errors.required">{{'support.validation.name' | translate}}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="required-field">{{'support.email' | translate}}</label>
                  <input type="text" formControlName="emailId" class="form-control" placeholder="Email">
                  <div *ngIf="submitted && formControls.emailId?.errors" class="invalid-feedback d-block">
                    <div *ngIf="formControls.emailId?.errors.required">
                      {{'support.validation.email.required' | translate}}</div>
                    <div *ngIf="formControls.emailId?.errors.email">{{'support.validation.email.valid' | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="required-field">{{'support.phNo' | translate}}</label>
                  <input type="tel" formControlName="mobilePhone" class="form-control" numbers
                    placeholder="Phone Number" minlength="12" maxlength="12">
                  <div *ngIf="submitted && formControls.mobilePhone?.errors" class="invalid-feedback d-block">
                    <div *ngIf="formControls.mobilePhone?.errors.required">
                      {{'support.validation.phone.required' | translate}}</div>
                    <div *ngIf="formControls.mobilePhone?.errors.pattern">
                      {{'support.validation.phone.valid' | translate}}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="required-field">{{'support.message' | translate}}</label>
                  <textarea type="text" formControlName="message" class="form-control" rows="12"
                    placeholder="Enter Message"></textarea>
                  <div *ngIf="submitted && formControls.message?.errors" class="invalid-feedback d-block">
                    <div *ngIf="formControls.message?.errors.required">{{'support.validation.message' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="resetForm()" class="modal-btn btn-primary-one" data-dismiss="modal"
            data-toggle="modal" #closeModal>{{'button.cancel' | translate}}</button>
          <button type="submit" [disabled]="showSpinner" class="modal-btn btn-primary-one">
            <div *ngIf="showSpinner" class="spinner-border text-muted"></div>
            {{'button.submit' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>