import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import tippy from 'tippy.js';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

/** Directive for common tooltip. */
@Directive({
  selector: '[appCommonTooltip]'
})
export class CommonTooltipDirective implements OnInit, OnChanges, OnDestroy {
  /** Tooltip message. */
  @Input() private tooltip: string;
  @Input() private hide$: Subject<void>;
  private destroyed: boolean;

  /**
   * Class constructor.
   * @param {ElementRef} el An element where tooltip should be created.
   */
  constructor(private el: ElementRef) {}

  /**
   * `ngOnInit` lifecycle hook.
   * Creates `Tippy` tooltip instance.
   */
  public ngOnInit(): void {
    tippy(this.el.nativeElement, {
      content: this.tooltip,
      size: 'regular',
      maxWidth: 250,
      delay: 500,
      allowHTML: true
    });
    this.ngOnChanges();
    if (this.hide$) {
      this.hide$.pipe(
        takeWhile(() => !this.destroyed)
      ).subscribe(() => {
        if (this.el && this.el.nativeElement && this.el.nativeElement._tippy) {
          this.el.nativeElement._tippy.hide();
        }
      });
    }
  }

  public ngOnChanges(): void {
    if (this.el && this.el.nativeElement) {
      const tippyInstance = this.el.nativeElement._tippy;
      if (!tippyInstance) {
        return;
      }
      if (this.tooltip) {
        tippyInstance.setContent(this.tooltip);
        tippyInstance.enable();
      } else {
        tippyInstance.disable();
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.el && this.el.nativeElement && this.el.nativeElement._tippy) {
      this.el.nativeElement._tippy.destroy();
    }
    this.destroyed = true;
  }
}
