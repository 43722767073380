<header>
  <div></div>
</header> 
<main>
  <h2>Internal testing navigation</h2>
  <div class="main-content">
    <div onclick="location.href='https://specialist.dev.nodmd.com/login';" style="cursor: pointer;" class="content">
      <a style="color:#1b676b;" href="https://specialist.dev.nodmd.com/login">Dev Login</a>
      <i class="fas fa-angle-right"></i>
    </div>
    <div onclick="location.href='https://specialist.qa.nodmd.com/login';" style="cursor: pointer;" class="content">
      <a style="color:#1b676b;" href="https://specialist.qa.nodmd.com/login">QA Login</a>
      <i class="fas fa-angle-right"></i>
    </div>
    <div onclick="location.href='https://specialist.staging.nodmd.com/login';" style="cursor: pointer;" class="content">
      <a style="color:#1b676b;" href="https://specialist.staging.nodmd.com/login">Staging Login</a>         
      <i class="fas fa-angle-right"></i>
    </div>
  </div>
</main>
<br/>
<div class="back">
  <i class="fas fa-angle-left"></i>
  <a style="color:#1b676b;" href="https://specialist.nodmd.com/login"> Back To Production</a>
</div>
<br/>
<footer>
  <div></div>
</footer> 

