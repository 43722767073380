import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor() {}

  handleError(error: any): void {
    // Construct the error event object
    const fullPageURL = window.location.href; // Capture the full page URL
    const errorMessage = error.message ? error.message : 'Unknown Error';
    const errorStack = error.stack ? error.stack : 'No stack trace available';

    const errorEvent = {
      event: 'javascriptError',
      errorMessage: errorMessage,
      errorStack: errorStack,
      fullPageURL: fullPageURL // Include the full page URL in the error event
    };

    // Push the error event to the Data Layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(errorEvent);

    // Additionally, log the error to the console if needed
    console.error('Error intercepted by GlobalErrorHandler:', error);
  }
}
