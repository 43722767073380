import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../Authentication/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DaySlotsInfo, TimeSlotsRequestBody, TimeSlotsResponse } from '../../modules/appointment/models/time-slot';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class AppointmentService {

  baseUrl = environment.baseUrl;
  iFrameUrl = environment.iFrameUrl;
  timeZone: any;

  httpOptions: any = {
    responseType: 'blob' as 'json',
    observe: 'response'
  };

  messageSource = new BehaviorSubject(null);
  appointmentData = new BehaviorSubject(null);
  cptListIsEmpty = new BehaviorSubject(null);
  currentMessage$ = this.messageSource.asObservable();
  appointmentData$ = this.appointmentData.asObservable();
  cptListIsEmpty$ = this.cptListIsEmpty.asObservable();
  notesData = new BehaviorSubject(null);
  noteData$ = this.notesData.asObservable();
  callUrl: any;
  providerGroupId: number;
  stopEncounter = new BehaviorSubject(false);
  stopEncounter$ = this.stopEncounter.asObservable();

  showSaveNotificationForSOAP = new BehaviorSubject(false);
  showSaveNotificationForSOAP$ = this.showSaveNotificationForSOAP.asObservable();
  isNavigateToBillingPlan = new BehaviorSubject(false);
  isNavigateToBillingPlan$ = this.isNavigateToBillingPlan.asObservable();

  openTaskCount = new BehaviorSubject(false);
  openTaskCount$ = this.openTaskCount.asObservable();

  getAppointmentDetailForOpenTask = new BehaviorSubject(false);
  getAppointmentDetailForOpenTask$ = this.getAppointmentDetailForOpenTask.asObservable();

  groupEhrStatus = new BehaviorSubject(false);
  groupEhrStatus$ = this.showSaveNotificationForSOAP.asObservable();

  inPatientData = new BehaviorSubject(false);
  inPatientData$ = this.inPatientData.asObservable();

  progressNotesDataSubject = new BehaviorSubject(false);
  progressNotesDataSubject$ = this.progressNotesDataSubject.asObservable();

  saveProgressNotesSubject = new BehaviorSubject(false);
  saveProgressNotesSubject$ = this.saveProgressNotesSubject.asObservable();

  getProgressNotesSubject = new BehaviorSubject(false);
  getProgressNotesSubject$ = this.getProgressNotesSubject.asObservable();

  setRoundingAppointmentSubject = new BehaviorSubject(null);
  setRoundingAppointmentSubject$ = this.setRoundingAppointmentSubject.asObservable();

  resetInPatientRoundingListSubject = new BehaviorSubject(false);
  resetInPatientRoundingListSubject$ = this.resetInPatientRoundingListSubject.asObservable();

  sideBarClickedSubject = new BehaviorSubject('');
  sideBarClickedSubject$ = this.sideBarClickedSubject.asObservable();

  timezoneChangeSubscription = new BehaviorSubject(null);
  timezoneChangeSubscription$ = this.timezoneChangeSubscription.asObservable();

  updatedEpochSubscription = new BehaviorSubject(null);
  updatedEpochSubscription$ = this.updatedEpochSubscription.asObservable();
  
  timeZoneChangeAbbreviation = new BehaviorSubject(null);
  timeZoneChangeAbbreviation$ = this.timezoneChangeSubscription.asObservable();

  constructor(private _http: HttpClient, private _auth: AuthService, private datePipe: DatePipe) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.providerGroupId = + this._auth.getGroupId();
  }

  /*****************************************
   *  SUBJECTS Methods
   *****************************************/
  callSave() {
    this.messageSource.next(true);
  }

  setNotesData(data) {

    this.notesData.next(data);
  }

  setAppointmentData(data) {
    this.appointmentData.next(data);
  }

  /***************************************************************************************************
   *      GET APIs
   ***************************************************************************************************/

  getBillingReport(data: any) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/billing/encounter?startDate=${data.startDate}&endDate=${data.endDate}&facilityId=${data.facilityId}&providerGroupId=${data.providerGroupId}&providerId=${data.providerId}&timezone=${this.timeZone}`).toPromise();
  }

  downloadBillingReport(data: any) {
    return this._http.get<any>(`${this.baseUrl}appointment-service/api/v1/billing/encounter?startDate=${data.startDate}&endDate=${data.endDate}&facilityId=${data.facilityId}&providerGroupId=${data.providerGroupId}&providerId=${data.providerId}&timezone=${this.timeZone}&generateInsuranceCardPDF=true`, this.httpOptions);
  }

  public getAppointmentById(id): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}appointment-service/api/v1/appointment/${id}`);
  }

  public getOnDemandAppointmentById(id) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/on-demand/appointment/${id}`);
  }

  getReasonToCancel() {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/cancel/reasons`);
  }

  getSOAPnotesById(data) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/notes?appointmentId=${data.appointmentId}`);
  }

  getAppointmentByPatientId(model) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/appointments/${model.id}?providerId=${model.providerId}&type=${model.type}&facilityId=${model.facilityId}&appointmentStatus=${model.status}`);
  }

  getSortedAppointmentByPatientId(model){
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/appointments/date-sort/${model.id}?providerId=${model.providerId}&type=${model.type}&facilityId=${model.facilityId}&appointmentStatus=${model.status}`); 
  }

  getRadiologyByPatientId(id) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/radiology-orders/patient/${id}`);
  }

  getprescriptionsByPatientId(id) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/prescription-orders/patient/${id}`);
  }

  getLabsByPatientId(id: any) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/lab-orders/patient/${id}`);
  }

  getAvailibilityByPatientName(model) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointments/slots?isWeekView=false&timezone=${this.timeZone}`, model);
  }
  getProviderMonthAvailibilty(model: any, providerGroupId: number, updatedTimezone: any, calendarId) {
    if (updatedTimezone != null) {
      return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointments/status/count?timezone=${updatedTimezone}&providerGroupId=${providerGroupId}&calendarId=${calendarId}`, model);
    } else {
      return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointments/status/count?timezone=${this.timeZone}&providerGroupId=${providerGroupId}&calendarId=${calendarId}`, model);
    }
  }

  getEmailIds(id) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/billing-email-setting?providerGroupId=${id}`);
  }
  getWidgetEmailId(providerGroupId) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/widget/setting/email/${providerGroupId}`);
  }

  getUploadedFiles(appointmentId) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/patient/files?appointmentId=${appointmentId}`);
  }

  getOpenTasksList(data) {

    data.pageSize = data.pageSize ? data.pageSize : '';
    data.page = (data.page || data.page == 0) ? data.page : '';
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/open-tasks?providerId=${data.providerId}&type=${data.type}&patientName=${data.patientName}&includeClosedTasks=${data.includeClosedTasks}&page=${data.page}&pageSize=${data.pageSize}`);
  }

  getOpenTasksByAppointmentId(data) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/open-tasks/appointment?providerId=${data.providerId}&type=${data.type}&appointmentId=${data.appointmentId}`);
  }

  downloadFile(id) {
    return this._http.get(`${this.baseUrl}patient-service/api/v1/patient/download-file/${id}`, { responseType: 'blob' });
  }

  /***********************************************************************************************************
   *    SEARCH APIs
   **************************************************************************************************************/

  searchDiagnosis(name) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/diagnosis?name=${name}`);
  }

  searchAppointmentByProvider(model) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/appointments/facility/1/patient/${model.id}/search?status=${model.status}&providerId=${model.providerId}`);
  }

  searchPrescription(data) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/prescription-orders/patient/${data.patientId}?providerId=${data.providerId}&startDate=${data.startDate}&endDate=${data.endDate}`);
  }

  searchRadiology(data) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/radiology-orders/patient/${data.patientId}?providerId=${data.providerId}&startDate=${data.startDate}&endDate=${data.endDate}`);
  }

  searchLab(data) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/lab-orders/patient/${data.patientId}?providerId=${data.providerId}&startDate=${data.startDate}&endDate=${data.endDate}`);
  }
  markAppointmentAsNoShow(appointmentId) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/appointment/no-show/${appointmentId}`);
  }
  getCPTCodes(code) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/cpt-codes?code=${code}`);
  }
  getAppointmentMedia(appointmentMediaRequest: any) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/capture/media/${appointmentMediaRequest.appointmentId}/${appointmentMediaRequest.providerId}`);
  }
  /**********************************************************************************************************
   *        POST  And PUT APIs
   **************************************************************************************************************/
  saveCapturedImage(captureRequest) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/capture/save`, captureRequest, { responseType: 'text' });
  }
  chargeAmount(data: any) {
    return this._http.post(`${this.baseUrl}payment-service/api/v1/payment/transaction/patient/${data.patientId}/appointment/${data.appointmentId}/anet?providerGroupId=${data.providerGroupId}&amount=${data.amount}&transactionType=priorAuthCaptureTransaction`, {});

  }
  // Billing Email Settings
  saveEmailIds(data, emaiilIds) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/billing-email-setting?providerGroupId=${data.providerGroupId}&timeZone=${data.timeZone}`, emaiilIds);
  }
  saveWidgetEmailId(data, providerGroupId) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/widget/setting/email/${providerGroupId}`, data);
  }
  requestToCancelAppointment(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/notification/appointment/${data.id}/action/${data.action}`, null);
  }

  notifyPatientOnArrival(appointmentId) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/notification/appointment/${appointmentId}/action/NOTIFY_PATIENT_FACILITY_ON_ARRIVAL_OF_SPECIALIST_IN_WAITING_ROOM`, null, { responseType: 'text' });
  }
  saveSOAPnote(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/notes`, data);
  }

  updateSOAPnote(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/notes`, data);
  }

  saveAndSignOffSOAPnote(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/sign-off?notesId=${data.notesId}&assessmentId=${data.assessmentId}`, null);
  }

  saveCapturedVideo(videoCaptureSaveRequest: any) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/video-capture/save/${videoCaptureSaveRequest.appointmentId}/${videoCaptureSaveRequest.providerId}`, videoCaptureSaveRequest.formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text'
    });
  }
  saveDiagnoses(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/assessment-diagnosis`, data);
  }

  updateDiagnoses(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/assessment-diagnosis`, data);
  }

  updateDiagnosesPromise(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/assessment-diagnosis`, data).toPromise();
  }
  getIcdCodeList(data) {
    return this._http.get<any[]>(`${this.baseUrl}appointment-service/api/v1/icd-codes?diagnosisCode=${data.diagnosisCode}&icdCode=${data.icdCode}`);
  }

  savePrescription(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/prescription-order`, data);
  }

  updatePrescription(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/prescription-order`, data);
  }

  saveLab(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/lab-order`, data);
  }
  updateLab(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/lab-order`, data);
  }

  saveRadiology(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/radiology-order`, data);
  }

  updateRadiology(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/radiology-order`, data);
  }

  encounter(data) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/encounter?patientId=${data.patientId}&appointmentId=${data.appointmentId}`, null);
  }

  uploadFiles(data) {
    return this._http.post(`${this.baseUrl}patient-service/api/v1/patient/upload-file`, data).toPromise();
  }
  /**********************************************************************************************************
  *       PATCH APIs
  **************************************************************************************************************/

  cancelAppointment(model: any) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointment`, model);
  }

  orderRadiology(data) {
    return this._http.patch(`${this.baseUrl}appointment-service/api/v1/radiology-order/${data.id}`, data);
  }

  orderLab(data) {
    return this._http.patch(`${this.baseUrl}appointment-service/api/v1/lab-order/${data.id}`, data);
  }

  orderPrescription(id) {
    return this._http.patch(`${this.baseUrl}appointment-service/api/v1/prescription-order/${id}`, null);
  }

  /**********************************************************************************************************
  *       DELETE APIs
  **************************************************************************************************************/

  removeDiagnosis(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/assessment-diagnosis?assessmentDignosisId=${id}`);
  }

  removeICD10Code(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/icd-codes?id=${id}`);
  }
  getAppointmentMediaVideos(appointmentMediaRequest: any) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/video-capture/media/${appointmentMediaRequest.appointmentId}/${appointmentMediaRequest.providerId}`);
  }

  deleteAppointmentMedia(appointmentMediaRequest: any) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/capture/media`, appointmentMediaRequest);
  }
  removePrescription(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/prescription-order/${id}`);
  }
  removeRadiology(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/radiology-order/${id}`);
  }
  removeLab(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/lab-order/${id}`);
  }
  removeEmail(id) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/billing-email/provider-group/${id}`);
  }
  removeWidgetEmail(providerGroupId) {
    return this._http.delete(`${this.baseUrl}appointment-service/api/v1/widget/setting/email/${providerGroupId}`);
  }

  getAppointmentTransaction(id) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/appointment-transaction/${id}`);
  }

  getAppointmentsByDateAndProviderID(modal) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/appointments?providerId=${modal.providerId}&startDate=${modal.startDate}&endDate=${modal.endDate}&timezone=${modal.timezone}&calendarId=${modal.calendarId}`);
  }

  triggerPatient(appointmentId) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/notification/appointment/${appointmentId}`, null);
  }

  getAthenaAppointment(providerGroupId, appointmentId) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/appointment/provider-group/${providerGroupId}/appointment/${appointmentId}`);
  }

  getOpenTaskListForInpatient(data) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/in-patient/open-tasks?providerId=${data.providerId}&type=${data.type}&patientName=${data.patientName}&includeClosedTasks=${data.includeClosedTasks}&facilityId=${data.facilityId}&page=${data.page}&pageSize=${data.pageSize}`);
  }

  saveProgressNotes(data, appointmentId?) {
    appointmentId = appointmentId ? appointmentId : '';
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/in-patient/notes?appointmentId=${appointmentId}`, data);
  }

  updateProgressNotes(data, appointmentId?) {
    appointmentId = appointmentId ? appointmentId : '';
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/in-patient/notes?appointmentId=${appointmentId}`, data);
  }

  saveAndSignOffProgressnote(notesId) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/in-patient/notes/sign-off/${notesId}`, null);
  }

  getProgressNotesForPatient(inPatientId, appointmentId) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/in-patient/${inPatientId}/appointment/${appointmentId}/notes`);
  }

  getProgressNotes(id) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/in-patient/notes/${id}`);
  }
  closeOpenTask(appointmentId, type, isForIgnoreCharge) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/open-tasks/close?type=${type}&appointmentId=${appointmentId}&isForIgnoreCharge=${isForIgnoreCharge}`, {}, { responseType: 'text' });
  }

  getProgressNotesTypes(providerId, facilityId) {
    return this._http.get(`${this.baseUrl}ehr-integration-service/api/v1/pcc/progress-note-types/${providerId}/facility/${facilityId}`);
  }
  getOpenTaskCount(id) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1/open-tasks/count?providerId=${id}`);
  }
  closeOpenTaskForInPatient(id, status) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/in-patient/open-tasks/close?openTaskId=${id}&status=${status}`, {}, { responseType: 'text' });
  }

  changeRoundingCartStatus(data) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-rounding/cart-status`, data);
  }

  markAppointmentStatus(data) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/appointment/mark-status`, data);
  }
  updateFacilityStaffForRoundingAppt(appointmentId, staffId) {
    return this._http.put(`${this.baseUrl}appointment-service/api/v1/appointment/${appointmentId}/facility-staff?facilityStaffId=${staffId}`, {});
  }
  deleteUploadedFileById(id) {
    return this._http.delete(`${this.baseUrl}patient-service/api/v1/patient/files/${id}`);
  }

  triggerScreenShareEvent(data: any) {
    return this._http.post(`${this.baseUrl}communicator-service/api/v1/pusher/trigger-screen-share?dashboard=SPECIALIST&event=${data.event}&appointmentId=${data.appointmentId}`, {});
  }

  saveAppointmentBrowserDetails(data: any) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/auth/appointment-browser-details`, data);
  }

  public getAppointmentsTimeSlots(data: TimeSlotsRequestBody): Observable<TimeSlotsResponse> {
    return this._http.post<TimeSlotsResponse>(`${this.baseUrl}/appointment-service/api/v1/appointment/provider/slots-v2`, data);
  }

  sendDoubleBookingAppointment(appointmentId,isdouble) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointment/double-book?appointmentId=${appointmentId}&doubleAppointment=${isdouble}`, null)
  }

  public getAppointmentsTimeSlotsForMonthView(data: TimeSlotsRequestBody): Observable<{ [key: number]: DaySlotsInfo[] }> {
    return this._http.post<{ [key: number]: { [key: string]: DaySlotsInfo[] } }>(
      `${this.baseUrl}/appointment-service/api/v1/appointment/provider/slots/monthly`, data).pipe(
        map(r => {
          const timezoneDiff = (new Date()).getTimezoneOffset() * 60000;
          const start = new Date(+new Date(data.startDate) + timezoneDiff);
          const endDate = new Date(+new Date(data.endDate) + timezoneDiff);
          const mappedData: { [key: number]: DaySlotsInfo[] } = {};
          Object.keys(r).forEach(id => {
            const dates = r[id];
            const slotsInfoList: DaySlotsInfo[] = [];

            let current = start;
            while (current < endDate) {
              const key = this.datePipe.transform(current, 'yyyy-MM-dd');
              if (dates[key]) {
                dates[key].forEach(item => item.date = (+new Date(item.date) + timezoneDiff));
                slotsInfoList.push(...dates[key]);
              } else {
                slotsInfoList.push({
                  availableSlots: 0,
                  calendarId: +id,
                  cancelledAppointments: 0,
                  completedAppointments: 0,
                  date: +new Date(key) + timezoneDiff,
                  id: null,
                  rescheduledAppointments: 0,
                  scheduledAppointments: 0
                });
              }
              current = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1);
            }

            mappedData[id] = slotsInfoList;
          });
          return mappedData;
        })
      );
    }

    sendConfirmationEmail(appointmentId: any) {
      return this._http.get(`${this.baseUrl}appointment-service/api/v1/auth/notification/appointment/confirmation/${appointmentId}`);
    }

    sendRatingEmailToPatient(appointmentId: any) {
      return this._http.get(`${this.baseUrl}appointment-service/api/v1/auth/notification/appointment/rating/email/${appointmentId}`);
    }

    public sendNotifications(data): Observable<any> {
      return this._http.post<any>(`${this.baseUrl}appointment-service/api/v1/notifications/patients/appointments`, data);
    }

    public sendNotificationsByPatientId(data): Observable<any> {
      return this._http.post<any>(`${this.baseUrl}appointment-service/api/v1/notifications/patients/patientId`, data);
    }
    getRejectReasonsToCancel() {
      return this._http.get(`${this.baseUrl}provider-service/api/v1/referral/reject/reason`);
    }
    downloadAttachment(documentUrl:string,fileName:string,mediaType:string) {
      return this._http.get(`${this.baseUrl}patient-service/api/v1/patient/download-file?keyName=${documentUrl}&fileName=${fileName}&mediaType=${mediaType}`, { responseType: 'blob' });
    }   
    verifyPatient( appointmentId:number) {     
      return this._http.put(`${this.baseUrl}appointment-service/api/v1/appointment/${appointmentId}/verify-patient`, null);
    } 
}
