
  <ng-container *ngIf="true">
    
    <button class="close-button" mat-button (click)="onCloseClick()">x</button>
    <div mat-dialog-content>
      
      <div class="message-soon break-w">{{message}}</div>
    </div>
    <div mat-dialog-actions>
      <div class="col-md-12 text-right m-t-15 d-flex justify-content-center">
        <button class="btn cancel-modal-btn text-right m-r-50" (click)="onCancelClick()">Cancel</button>
        <button class="btn delete-modal-btn text-right" [mat-dialog-close]="true" cdkFocusInitial>Delete File</button>
      </div>
    </div>
  </ng-container>