export class ProgressNotes {
  id = null;
  subjectiveNotes: String = "";
  objectiveNotes: String = "";
  assessment: any = {
    id: null,
    assessmentNotes: ""
  };
  signOff: boolean = false;
  inPatientId: any = null;
  providerId: any = null;
  noteType: string = "";
  facilityId: string = "";

  constructor(inPatientId, providerId) {
    this.inPatientId = inPatientId;
    this.providerId = providerId;
  }
} 