import { NgModule } from '@angular/core';
import { UsPhoneDirectives } from './usphone-validation.directive';
import { HasPermissionDirective } from './has-permission.directive';
import { ReCaptchaDirective } from './recaptcha.directive';
import { PreventKeysDirective } from './prevent-keys.directive';
import { SsnDirectives } from './ssn-validation.directive';
import { ZIPDirectives } from './zip-validation.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { OnlyNumberDirectives } from './only-number.directive';
import { DraganddropDirective } from 'src/app/core/directives/draganddrop.directive';
import { TaxIdDirective } from './tax-id.directive';
import { PhoneNumberDirectives } from './phone-number-validation.directive';



@NgModule({
    imports: [
        GooglePlaceModule
    ],
    declarations: [
        UsPhoneDirectives,
        PhoneNumberDirectives,
        HasPermissionDirective,
        ReCaptchaDirective,
        PreventKeysDirective,
        SsnDirectives,
        ZIPDirectives,
        OnlyNumberDirectives,
        DraganddropDirective,
        ClickOutsideDirective,
        TaxIdDirective
    ],
    exports: [
        UsPhoneDirectives,
        PhoneNumberDirectives,
        HasPermissionDirective,
        ReCaptchaDirective,
        PreventKeysDirective,
        SsnDirectives,
        ZIPDirectives,
        GooglePlaceModule,
        OnlyNumberDirectives,
        DraganddropDirective,
        ClickOutsideDirective,
        TaxIdDirective
    ]
})
export class DirectivesModule { }