import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PusherService } from 'src/app/modules/twilio-video/service/pusher.service';
import { AuthService } from '../../Authentication/auth.service';
import { AlertService } from '../../helpers/alert.service';
import { AdminService } from '../../api-services/admin.service';
import { UserService } from '../../services/user.service';
import { Permissions } from 'src/app/config/permissions';
import { PatientService } from '../../api-services/patient.service';
import { MailVerification } from 'src/app/components/shared/models/mailVarification';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProvidersService } from '../../api-services/providers.service';
import { CommonService } from '../../api-services/common.service';
import { PaymentService } from '../../api-services/payment-service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  count: any = {};
  isSessionExpire: boolean = false;
  showLoader: boolean = false;
  message: any;
  currentUser: any;
  Permissions: any = Permissions;
  verificationModal: any;
  userId: any;
  reInitEmailVerificationSubscription: Subscription;
  userEmail: string;
  timeZone = "";
  downloadDataSubscription: Subscription = null;
  selectedBillingPlan: any = {};
  groupId: number;
  private destroyed: boolean;

  constructor(public loader: LoaderService,
    private _pusher: PusherService,
    private _auth: AuthService,
    private _alert: AlertService,
    private _admin: AdminService,
    private userService: UserService,
    private _patient: PatientService,
    private _provider: ProvidersService,
    private payment: PaymentService,
    private _commonService: CommonService) {
    if (!this._auth.isLoggedIn()) {
      this._auth.logout();
    }
    this.showLoader = loader.isLoading();
    this.verificationModal = new MailVerification();
    this.userId = this._auth.getUserId();
    this.userEmail = this._auth.getUserName();
    if (this.userId) {
      this.getVerificationLinkAndStatus();
    }

    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.groupId = +this._auth.getGroupId();
    if (this.groupId) {
      this.getProviderGroupDetails();
      this.getGroupBillingPlanDetails();
    }
  }


  ngOnInit() {
    this.subscribeSlotNotificationChannel();
    this.userService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    if (this.currentUser.includes(Permissions.DOES_INPATIENT_ROUNDING)) {
      this.subscribeToPccSessionChannel();
    }
    this.subscribeSubjectForEmailVerification();
    this.cancelSubscription();
    this.downloadProviderGroupDataSubscription();

    this.payment.billingPlanChange$.pipe(takeWhile(() => !this.destroyed)).subscribe(() => {
      this.getGroupBillingPlanDetails();
    });
  }

  private subscribeSlotNotificationChannel() {
    var that = this;
    let channelName = 'ADMIN-' + this._auth.getUserId();
    var channel = that._pusher.subscribe(channelName);
    channel.bind('SLOTS_MANIPULATION_ACK', function (data) {
      that.showNotification(data)
    });
  }

  showNotification(data) {
    this._alert.successAppointmentAlert(data.message, true);
  }

  subscribeToPccSessionChannel() {
    var that = this;
    let channelName = 'PROVIDER-' + this._auth.getSpecialist();
    var channel = that._pusher.subscribe(channelName);
    channel.bind('DISCONNECTED_FROM_PCC', function (data) {
      if (data.message) {
        that.isSessionExpire = true;
        that.message = data.message;
      } else {
        that.isSessionExpire = false;
      }
    })

    setTimeout(() => {

      if (!localStorage.getItem("skipPcc")) {
        this._admin.pccExpireSessionSubject.next(this._auth.getSpecialist());
      }
    }, 3000);
  }

  skip(skip) {
    if (skip == 'skipPcc') {
      this.isSessionExpire = false;
    } else if (skip == 'skipEmailVerification') {
      this.verificationModal.flag = false;
    }
  }

  getVerificationLinkAndStatus() {
    this._patient.getVerificationLinkAndStatus(this.userId).subscribe((res: any) => {


      if (res) {
        if (!localStorage.getItem("skipEmailVerification")) {
          this.verificationModal.userId = this.userId;
          this.verificationModal.verificationLink = res.verificationLink;
          this.verificationModal.flag = !res.status;
          if (this.verificationModal.flag) localStorage.removeItem("skipEmailVerification");
        } else if (localStorage.getItem("skipEmailVerification") == "true") {
          this.verificationModal = new MailVerification();
        }
      }
    })
  }

  subscribeSubjectForEmailVerification() {
    this.reInitEmailVerificationSubscription = this._patient.$reInitEmailVerification.subscribe((res: any) => {
      if (res) this.getVerificationLinkAndStatus();
    })
  }

  cancelSubscription() {

    var that = this;
    let channelName = 'GROUP-' + this._auth.getGroupId();
    var channel = that._pusher.subscribe(channelName);
    channel.bind('AUTO_LOGOUT_USER', function (data) {
      if (data) {
        that._alert.successAlert("Your subscription has been cancelled. We are redirecting you to nodMD.com.");
        setTimeout(() => {
          that._auth.logout();
          window.location.href = environment.nodMDUrl;
        }, 5000);
      } else {

      }
    })


  }

  downloadProviderGroupDataSubscription() {
    this.downloadDataSubscription = this._provider.downloadProviderGroupDataObservable.subscribe(data => {

      this._provider.isDownloadingData = true;
      this.downloadProviderGroupData();
    });
  }

  downloadProviderGroupData() {

    this._provider.getProviderGroupData(this._auth.getGroupId(), this.timeZone).subscribe((res: any) => {



      var binary_string = window.atob(res.data);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      var content;
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      content = new Uint8Array(bytes.buffer);

      var blob = new Blob([content], { type: "application/zip" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "ProviderGroup.zip";
      link.click();

      this._provider.isDownloadingData = false;

    }, (err) => {

      this._provider.isDownloadingData = false;
    });

  }

  getProviderGroupDetails() {
    this._provider.getProviderGroupDetails(this.groupId).subscribe((data: any) => {
      this._admin.setGroupDetailsSubject.next(data);
    },
      error => {

      });
  }

  getGroupBillingPlanDetails() {
    this._commonService.getBillingPlanByProviderIdAndGroupId(parseInt(this._auth.getSpecialist()), this._auth.getGroupId()).subscribe((res: any[]) => {
      if (res && res.length > 0) {
        const currentPlan = res.find(({ isCurrent }) => isCurrent) || { billingPlanDto: {} };
        this.selectedBillingPlan = {
          ...currentPlan,
          amount: currentPlan.billingPlanDto.amount,
          headerText: currentPlan.billingPlanDto.billingCycle === 'Annual' ? 'Yearly' : 'Monthly',
          planName: currentPlan.billingPlanDto.billingType
        };
        this._admin.setIsBonusTrialAccepted.next(currentPlan.acceptBonusTrialOffer);
        this._admin.groupBillingPlanSubject.next(this.selectedBillingPlan);
        this._admin.trialAllowed.next(currentPlan.isFreeTrialAllowed);
      }
    }, (err: any) => {

    })
  }


  ngOnDestroy() {

    // unsubscribe to ensure no memory leaks
    if (this.downloadDataSubscription != null) {
      this.downloadDataSubscription.unsubscribe();
    }
    this.destroyed = true;
  }

}
