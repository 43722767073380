import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[numbers]'
})
export class UsPhoneDirectives {

  constructor(public el: ElementRef, public control: NgControl) {

    this.el.nativeElement.onkeypress = (evt) => {
      if (evt.which != 45) {
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
        if (evt.target.value.length === 3 || evt.target.value.length === 7) {
          evt.target.value = evt.target.value + '-'
        }
      }
    };
  }
  @HostListener('blur', ['$event'])
  blur(evt) {
    let number: string = evt.target.value.toString();
    if (number.length == 10) {
      if (!number.includes("-")) {
        number = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        evt.target.value = number;
        if (this.control) this.control.control.setValue(number);
      } else {

      }
    }
  }
}
