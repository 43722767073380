import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-okay-dialog',
  templateUrl: './okay-dialog.component.html',
  styleUrls: ['./okay-dialog.component.css']
})
export class OkayDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<OkayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
