import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {Observable, Subject} from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public billingPlanChange$: Subject<void> = new Subject();
    baseUrl: string = environment.baseUrl;


    constructor(private _http: HttpClient) {
    }
    getBillingPlan(filter) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans?filter=${filter.type}&featurePlanId=${filter.featurePlanId}`)
    }

    getBillingPlansForGroup(providerGroupId) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/billing-plan-available/${providerGroupId}`);
    }

    getFeatureModules(filter) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/feature-modules?filter=${filter}`)
    }

    addPaymentMethod(data: any) {
        return this._http.post(`${this.baseUrl}payment-service/api/v1/auth/payment/anet/create-payment-profile`, data);
    }

    getBillingPlanById(id: any) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/${id}`);
    }

    getNodAdminMerchantDetails() {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/payment/anet/nod-admin/merchant-details`);
    }

    getProviderGroupsPaymentMethod(id: any) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/payment/check/payment-profile?providerGroupId=${id}`);
    }

    createProviderGroupAsCustomerInANet(data: any) {
        return this._http.post(`${this.baseUrl}payment-service/api/v1/auth/payment/create-customer-profile/anet`, data);
    }

    createProviderGroupFacilityBillingPlan(data: any) {
        return this._http.post(`${this.baseUrl}payment-service/api/v1/provider-group-facility-billing-plan`, data);
    }

    activateFreeTrial(providerId) {
        return this._http.post(`${this.baseUrl}/payment-service/api/v1/free-trial/${providerId}`, null);
    }

    updateProviderGroupFacilityBillingPlan(data: any) {
        return this._http.put(`${this.baseUrl}payment-service/api/v1/provider-group-facility-billing-plan`, data);
    }
    cancelSubscription(data) {
        return this._http.post(`${this.baseUrl}/common-service/api/v1/cancel-subscription`, data);
    }
    getBillingPlanHeaders() {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/feature-plans`);
    }

    getProviderGroupFacilityBillingPlan(id: any) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/provider-group-facility-billing-plan?providerGroupId=${id}`);

    }

    getFeaturePlanById(id: any) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans/feature-plans/${id}`);
    }

    getBillingPlansByType(filter) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/billing-plans?filter=${filter.type}`);
    }

    updateProviderGroupFacilityBillingPlanBillingId(data, BillingId) {
        return this._http.patch(`${this.baseUrl}payment-service/api/v1/provider-group-facility-billing-plan/${BillingId}`, data);
    }

    getDetailsByPromoCode(promoCode, billingPlanId, isPCP) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/promo-code/${promoCode}/billing-plan/${billingPlanId}?isPcp=${isPCP}`);
    }

    getProviderPromoCode(providerid, billingPlanId) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/provider-promo-code/providerId/${providerid}/billing-plan/${billingPlanId}`)
    }

    addPromoCodeToProvider(data: any) {
        return this._http.post(`${this.baseUrl}payment-service/api/v1/auth/provider-promo-code`, data);
    }

    deletePromoCode(data: any) {
        return this._http.delete(`${this.baseUrl}payment-service/api/v1/provider-promo-code`, data);
    }
    
    getPlansBillingPlanId(){
        return this._http.get(`${this.baseUrl}payment-service/api/v1/billing-plan/type`)
    }

    getCreditCardDetails(data: any) {
        return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/payment/check/payment-profile?providerGroupId=${data.providerGroupId}&patientId=${data.patientId}`)
    }

    getHighetBillingPlan(providerGroupId): Observable<{ billingPlanId: number }> {
        return this._http.get<{ billingPlanId: number }>(`${this.baseUrl}payment-service/api/v1/auth/highest-billing-plan/${providerGroupId}`);
    }
    // Updated method to set the custom video background setting chosen by the user in the payment-setting component dropdown.
    setCustomVideoBackgroundSetting(providerGroupId: number, selectedValue: string) {
        const data = {
          providerGroupId,
          selectedValue
        };
        return this._http.put(`${this.baseUrl}payment-service/api/v1/payment/setcustomvideobackgroundsetting`, data, { responseType: 'text' }) // Set responseType to 'text'
          .pipe(tap(response => console.log('HTTP response from setCustomVideoBackgroundSetting:', response))); // Log the HTTP response
      }

  // Updated method to get the custom video background setting chosen by the user in the payment-setting component dropdown.
  getCustomVideoBackgroundSetting(providerGroupId: number) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/payment/getcustomvideobackgroundsetting?providerGroupId=${providerGroupId}`);
  }
}