
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../Authentication/auth.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { Timezones } from 'src/app/components/shared/models/timezone';
import { ProviderListItem } from '../../components/shared/models/provider-list-item';
import { Speciality } from '../../modules/appointment/models/speciality';
import { ProviderOption } from '../../modules/appointment/models/provider-option';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  timezonesProgress$;
  timezones;
  groupId: number = 0;
  timeZone: any;
  baseUrl: string = environment.baseUrl;
  constructor(private _http: HttpClient, private _auth: AuthService) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.groupId = +this._auth.getGroupId();
  }
  messageClient = new BehaviorSubject(null);
  $messageClient = this.messageClient.asObservable();

  downloadProviderGroupData = new Subject<any>();
  downloadProviderGroupDataObservable = this.downloadProviderGroupData.asObservable();
  isDownloadingData = false;
  ehrProviderData = new BehaviorSubject(null);
  $ehrProviderData = this.ehrProviderData.asObservable();

  /**********************************************************************************************************
    *        POST  And PUT APIs
    **************************************************************************************************************/

  saveAppointment(data: any) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointment`, data);
  }
  editAppointment(data: any) {
    return this._http.post(`${this.baseUrl}appointment-service/api/v1/appointment`, data);
  }
  /**
 *  Get Slots For Day and Week View
 * @param model
 */
  getAvailibility(model: any, query: any, updatedTimezone: any) {
    let status = false;
    if (model.startDate !== model.endDate)
      status = true;
    let action;
    this.groupId = +this._auth.getGroupId();
    const data = JSON.parse(JSON.stringify(model));
    delete data.specialityId;
    if (updatedTimezone != null) {
      action = this._http.post(`${this.baseUrl}appointment-service/api/v1/appointments/slots?isWeekView=${status}&timezone=${updatedTimezone}&appointmentSlotFilter=${query.apptStatus}&providerGroupId=${this.groupId}&appointmentType=${query.apptType}`, data);
    } else {
      action = this._http.post(`${this.baseUrl}appointment-service/api/v1/appointments/slots?isWeekView=${status}&timezone=${this.timeZone}&appointmentSlotFilter=${query.apptStatus}&providerGroupId=${this.groupId}&appointmentType=${query.apptType}`, data);
    }
    return action.pipe(tap((data) => {
      Object.values(data).forEach(slots => {
        const list = slots.items;
        for (let i = 0; i < list.length; i++) {
          const slot = list[i];
          if (slot.appointments) {
            slot.appointments = slot.appointments.filter((a) => a.appointmentStatus !== 'ACCEPTED' && a.appointmentStatus !== 'PENDING');
            if (!slot.appointments || !slot.appointments.length) {
              const item = list.find(s => s.startTime === slot.startTime && (!s.appointments || !s.appointments.length));
              if (list.indexOf(item) !== i) {
                slots.items.splice(i, 1);
                i--;
              }
            }
            slot.appointments.forEach(a => a.providerSlotId = slot.id);
          }
        }
      });
    }));
  }

  uploadVideo(data: FormData) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/auth/provider/video`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  saveProviderData(data) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/auth/provider`, data)
  }

  getProviderDataById(id) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider/${id}`)
  }


  /**
   * CREATE Availability for a provider
   * @param data
   */
  createAvailability(data: any) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/create/provider-availability`, data)
  }

  updateAvailability(data: any) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-availability/${data.id}`, data)
  }

  /**
    * GET Specialities of a group
    * @param data
    */
  getSpecialitiesAndProviders(grouId): any {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${grouId}/specialities/providers`)
  }

  /**
   * 
   * @param id 
   */
  getProviderGroupById(id: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${id}?isForAppointmentView=true`);
  }


  /**
   *
   * @param data
   */
  updateProviderAvailabilty(data: any, providerId: number, calendarId) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-slot/provider/${providerId}/calendarId/${calendarId}/day`, data)
  }
  updateBillingSettings(billing: any) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-group-billing-setting`, billing)
  }
  addPaymentSetting(data: any) {
    return this._http.post(`${this.baseUrl}payment-service/api/v1/payment/provider-group-billing-account`, data)
  }
  /**********************************************************************************************************
  *        GET APIs
  **************************************************************************************************************/

  /**
   *  Get Status of slot by providerId, Appointment Type ans StartTime
   * @param model
   */

  getSlotStatus(model) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-slots/provider/${model.providerId}/appointment/create?appointmentType=${model.type}&startTime=${model.startTime}&appointmentId=${model.appointmentId}`)
  }
  searchSlots(model: any, updatedTimezone: any) {
    model.appointmentType = model.appointmentType ? model.appointmentType : '';
    if (updatedTimezone != null)
      return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-slots/provider/${model.providerId}/date?q=${model.date}&isWeekView=false&appointmentType=${model.appointmentType}&timezone=${updatedTimezone}`);
    else
      return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-slots/provider/${model.providerId}/date?q=${model.date}&isWeekView=false&appointmentType=${model.appointmentType}&timezone=${this.timeZone}`);
  }

  //GET SPECIALIST GROUP BY FACILITY ID
  getSpecialistsByFacility(id) {
    return this._http.get<any[]>(`${this.baseUrl}provider-service/api/v1/provider-group/facility/${id}`)
  }

  /**
   * GET all providers
   */
  getAllProviders() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/providers`)
  }

  /**
  * Get Availability for a provider
  * @param data
  */
  getAvailabilityByProviderId(providerId: number) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-availability/provider/${providerId}`)
  }

  getAddressCalendarId(addressId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/calendar/addressId/${addressId}`).pipe(
      map((calendarId: number) => ({ calendarId, addressId }))
    );
  }

  /**
   *
   * @param providerId
   */
  getMonthAvailabilityByProviderId(data: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-availability/provider/${data.providerId}/month
?startDate=${data.startDate}&endDate=${data.endDate}`)
  }

  getProviderAdresses(providerId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-locations/${providerId}`);
  }

  getProviderAvailabilityForMonth(data: { providerId: number; startDate: string; endDate: string; }) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1//provider-availability/provider/${data.providerId}/month/view?startDate=${data.startDate}&endDate=${data.endDate}`);
  }



  getMerchantCredential(id: number) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/payment/anet/provider-group/${id}/merchant-details`);
  }

  getStateLicensed() {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/states-licensed`);
  }

  getClinicalPathways(data: any) {
    return this._http.get(`${this.baseUrl}common-service/api/v1/clinicalpathways/keys?parentId=${data.parentId}&numberOfSelection=${data.noOfSelection}&isPrevious=${data.isPrevious}`)
  }


  addBillingSetting(data: any) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/provider-group-billing-setting`, data)
  }

  getBillingSettings(id: number) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group-billing-setting/${id}`)
  }

  deleteBillingSetting(id: number) {
    return this._http.delete(`${this.baseUrl}provider-service/api/v1/provider-group-billing-setting/${id}`, { responseType: 'text' });
  }

  getPaymentSetting(id: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/payment/provider-group-billing-account?providerGroupId=${id}`)
  }
  editPaymentSetting(data: any) {
    return this._http.put(`${this.baseUrl}payment-service/api/v1/payment/provider-group-billing-account`, data)
  }

  checkAppointmentAvailability(model): any {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/${model.providerId}/calendarId/${model.calendarId}/patient/${model.patientId}/appointment/eligibility`);
  }

  //GET Appointment Slot by Date edit Appointment
  searchSlotsOfProviderForEdit(model: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-slots/provider/${model.providerId}/date?q=${model.date}&isWeekView=false&timezone=${this.timeZone}&appointmentId=${model.appointmentId}`);
  }

  getOnlyProvidersNameAndId(id: number, isVerified: boolean, specialityId: any = '') {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/veil/providerGroup/${id}?isVerified=${isVerified}&specialityId=${specialityId}`);
  }

  /**
  * GET provider list by date and time
  * @param model
  */
  getProviderListByDateTime(model) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/providers/${model.groupId}/providerSlotsTime?startTime=${model.startTime}&endTime=${model.endTime}&appointmentType=${model.type}&specialityId=${model.specialityId}&isVerified=true&calendarId=${model.calendarId}`);
  }
  /**
   * Gets slots by date
   * @param date
   * @returns
   */
  getSlotsByDate(date) {
    return this._http.get<any[]>(`${this.baseUrl}provider-service/api/v1/provider-slots/date?q=${date}&timezone=${this.timeZone}`);
  }

  /**
   * On Demand search
   */
  onDemandSearch(providerGroupId, providerId, searchQuery) {
    return this._http.get<any[]>(`${this.baseUrl}provider-service/api/v1/on-demand-search?providerGroupId=${providerGroupId}&providerId=${providerId}&query=${searchQuery}`);
  }
  /**
   * Gets provider group details
   * @param id
   * @returns provider group details
   */
  getProviderGroupDetails(id: number): any {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${id}`);
  }


  getAvailableDateOfProvider(data, updatedTimezone: any, calendarId) {

    if (updatedTimezone != null)
      return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider/${data.id}/calendarId/${calendarId}/availability/month?startDateString=${data.sDate}&endDateString=${data.eDate}&timezone=${updatedTimezone}`)
    else
      return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider/${data.id}/calendarId/${calendarId}/availability/month?startDateString=${data.sDate}&endDateString=${data.eDate}&timezone=${this.timeZone}`)
  }


  getSpecialitiesOfaProvider(providerId) {
    return this._http.get<any[]>(`${this.baseUrl}provider-service/api/v1/provider/${providerId}/specialities`)
  }


  /**
   * Enables or disbale provider
   * @param data
   * @returns
   */
  enableOrDisbaleProvider(data) {
    return this._http.patch(`${this.baseUrl}provider-service/api/v1/provider/active/${data.id}?active=${data.isActive}`, null)
  }
  /**
   * Deletes provider
   * @param id
   * @returns
   */
  deleteProvider(id) {
    return this._http.delete(`${this.baseUrl}provider-service/api/v1/provider/${id}`)
  }

  fetchAllProvidersFromAthena(providerGroupId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${providerGroupId}/unsynced-providers`);
  }

  getProviderGroupList(): any {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-groups`);
  }

  mapExistingFacilityToGroup(id, data) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-group/${id}/specialities/facilities`, data);
  }
  fetchProviderGroupSpecialties(providerGroupId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/specialities/provider-group/${providerGroupId}`);
  }

  getCovidSettingForGroup(providerGroupId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${providerGroupId}/covid-settings`);
  }

  updateCovidSettingForGroup(providerGroupId, covidSettingRequest) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/provider-group/${providerGroupId}/covid-settings`, covidSettingRequest);
  }


  mapExistingGroupToFacility(id, data) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/facility/${id}/provider-group-specialities`, data);
  }

  searchProviderGroup(query: string) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-groups?name=${query}`);
  }


  disconnectProviderFromFacility(groupId: number, facilityId: number) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/facility/${facilityId}/disconnect/provider-group/${groupId}`).toPromise();

  }
  disconnectFacilityFromGroup(id: any, facilityId: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${id}/disconnect/facility/${facilityId}`).toPromise();
  }

  getConflictedAppointments(data) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/appointments/start-end-time?providerId=${data.providerId}&startTime=${data.startTime}&endTime=${data.endTime}&status=SCHEDULED`)
  }

  getAppointmentsByDateAndTimezone(data, updatedTimeZone: any) {
    if (updatedTimeZone != null)
      return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/appointments/date-timezone?providerId=${data.providerId}&startDate=${data.startDate}&endDate=${data.endDate}&status=${data.status}&timezone=${updatedTimeZone}`)
    else
      return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/appointments/date-timezone?providerId=${data.providerId}&startDate=${data.startDate}&endDate=${data.endDate}&status=${data.status}&timezone=${this.timeZone}`)
  }

  getProviderListByGroupId(data) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/providers?providerGroupId=${data.groupId}&&isVerified=true&clientId=${environment.clientId}`)
  }

  getProviderGroupData(providerId, timezone) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/download/${providerId}?timezone=${timezone}`)
  }

  getTimezonesDetails(): Observable<Timezones[]> {
    if (!this.timezonesProgress$ && !this.timezones) {
      this.timezonesProgress$ = this._http.get<Timezones[]>(`${this.baseUrl}common-service/api/v2/time-zones`).pipe(
        tap(data => this.timezones = data),
        share()
      );
    }
    if (this.timezones) {
      return of(this.timezones);
    }
    return this.timezonesProgress$;
  }

  searchInsurance(query: string) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/insurances-accepted?name=${query}`);
  }

  searchSpecialityByName(value: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/specialities?name=${value}`).toPromise();
  }

  togglerSuperUser(groupId, userId) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/auth/toggle-super-user/group/${groupId}/user/${userId}`, {});
  }

  saveReferralConfirmationEmail(data) {
    return this._http.post(`${this.baseUrl}provider-service/api/v1/auth/referral-confirmation-email`, data);
  }

  updateIsNotified(data, value) {
    return this._http.put(`${this.baseUrl}provider-service/api/v1/auth/provider/update-isNotified/${value}`, data);
  }

  getProviderById(id) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider/${id}`);
  }

  getProviderAdminCountAndPlan(groupId) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider-admin/count/groupId/${groupId}`);
  }

  saveCancelSubscriptionInfo(data) {
    return this._http.post(`${this.baseUrl}common-service/api/v1/cancel-subscription`, data);
  }

  getAllProvidersOfGroup(groupId): Observable<ProviderListItem[]> {
    return this._http.get<ProviderListItem[]>(`${this.baseUrl}provider-service/api/v1/providers/${groupId}`).pipe(tap((users => {
      users.forEach(user => {
        if (user.profilePicUrl) {
          user.profilePicUrl = encodeURI(user.profilePicUrl);
        }
      });
    })));
  }
  getProviderGroupByNPI(npi) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/auth/provider-group/npi/${npi}`);
  }

  public getSpecialistsByProviderGroupAndSpeciality(providerGroupId: number, specialityId): Observable<ProviderOption[]> {
    return this._http.get<ProviderOption[]>(
      `${this.baseUrl}/provider-service/api/v1/provider-group/${providerGroupId}/providers-by-specialty?specialtyId=${specialityId}`
    ).pipe(map((providers) => providers.filter(({ displayName }) => displayName !== 'Best Available Time')));
  }

  getHigestBillingPlan(groupId: any) {
    return this._http.get(`${this.baseUrl}payment-service/api/v1/auth/highest-billing-plan/${groupId}`);
  }

  public isProviderPcp(providerId): Observable<boolean> {
    return this._http.get<boolean>(`${this.baseUrl}provider-service/api/v1/auth/provider/is-pcp/${providerId}`);
  }

  getProvidersNameBySearch(providerGroupId: any, serachName: any, specialistId: any) {
    return this._http.get(`${this.baseUrl}provider-service/api/v1/provider-group/${providerGroupId}/search-providers-by-specialty?specialtyId=${specialistId}&providerName=${serachName}`)
  }

  getAddressIdByCalendarId(addressId){
    return this._http.get(`${this.baseUrl}provider-service/api/v1/calendar/addressId-by-calendarId/${addressId}`);
  }
  getAthenaAppointmentSyncStatus(id:number) {
    return this._http.get(`${this.baseUrl}appointment-service/api/v1//ehr/sync-status/appointment/${id}`)
  }
}
